import React, {useCallback, useEffect, useMemo, useState} from 'react';

import type {AppContextState, SetAppContext} from '../types';
import {useIsAriaMenuOpen} from '../hooks/useIsAriaMenuOpen';

import {AppContext} from './app.context';

const initialAppContext = {
    isOnStepByStepJoinScreen: false,
    isAriaMenuOpen: false,
};

export const AppContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const [appContext, setAppContextInternal] =
        useState<AppContextState>(initialAppContext);

    const setAppContext = useCallback<SetAppContext>((key, updater) => {
        setAppContextInternal(prevState => {
            const newValue = updater(prevState[key]);
            return {...prevState, [key]: newValue};
        });
    }, []);

    const contextValue = useMemo(
        () => ({appContext, setAppContext}),
        [appContext, setAppContext],
    );

    const isAriaMenuOpen = useIsAriaMenuOpen();

    useEffect(() => {
        if (isAriaMenuOpen !== contextValue.appContext.isAriaMenuOpen) {
            contextValue.setAppContext('isAriaMenuOpen', () => isAriaMenuOpen);
        }
    }, [isAriaMenuOpen, contextValue]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};
