import React from 'react';
import cx from 'classnames';

import {useEnter} from '@pexip/hooks';

import {FontVariant} from '../../../../design-tokens/constants';
import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import {Text} from '../Text/Text';

import styles from './ToggleSwitch.module.scss';

export interface ToggleSwitchProps
    extends Omit<React.ComponentProps<'input'>, 'onChange'> {
    checked: boolean;
    className?: string;
    isSmall?: boolean;
    name: string;
    label: string;
    labelClassName?: string;
    labelPosition?: 'start' | 'end';
    labelModifier?: 'hidden' | 'spaced';
    labelFontWeight?: 'normal' | 'bold';
    onChange: (isChecked: boolean) => void;
    colorScheme?: ColorScheme;
}

export const ToggleSwitch: React.FC<
    Omit<ToggleSwitchProps, 'type' | 'style'>
> = ({
    checked,
    className,
    label,
    labelClassName,
    labelPosition = 'end',
    labelModifier,
    labelFontWeight,
    onChange,
    isSmall,
    colorScheme,
    ...props
}) => {
    const {onKeyDown} = useEnter<HTMLInputElement>(e => {
        onChange(!e.currentTarget.checked);
    });
    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <label
                    className={cx(
                        colorScheme ?? defaultColorScheme,
                        styles.container,
                        styles[colorScheme ?? defaultColorScheme],
                        labelPosition && styles[labelPosition],
                        {
                            [styles.small]: isSmall,
                            [styles.spaced]: labelModifier === 'spaced',
                        },
                        className,
                    )}
                >
                    <input
                        type="checkbox"
                        checked={checked}
                        className={styles.input}
                        onChange={e => onChange(e.currentTarget.checked)}
                        onKeyDown={onKeyDown}
                        {...props}
                    />
                    <div
                        className={cx(styles.toggle, {
                            [styles.checked]: checked,
                        })}
                    >
                        <div className={cx(styles.indicator)} />
                    </div>
                    <Text
                        fontVariant={
                            labelFontWeight === 'bold'
                                ? FontVariant.BodyBold
                                : FontVariant.Body
                        }
                        className={cx(styles.label, labelClassName, {
                            [styles.hidden]: labelModifier === 'hidden',
                        })}
                    >
                        {label}
                    </Text>
                </label>
            )}
        </ThemeConsumer>
    );
};
