// MenuButton.tsx

import React from 'react';
import type {CollectionChildren, Key} from '@react-types/shared';
import {useMenuTriggerState} from '@react-stately/menu';
import type {Item} from '@react-stately/collections';
import {useMenuTrigger} from '@react-aria/menu';
import {useButton} from '@react-aria/button';
import {mergeProps} from '@react-aria/utils';
import cx from 'classnames';

import {RefButton} from '../../elements/Button/Button';
import {Tooltip} from '../../..';

import type {AriaMenuItem, AriaMenuProps} from './AriaMenu.types';
import {InnerAriaMenu} from './InnerAriaMenu';
import {InnerAriaMenuPopover} from './InnerAriaMenuPopover';

import styles from './AriaMenu.module.scss';

export const InnerAriaMenuButton: React.FC<
    Omit<AriaMenuProps, 'items'> & {
        children: CollectionChildren<typeof Item>;
        itemMap: Map<Key, AriaMenuItem>;
    }
> = ({
    headerContent,
    button,
    buttonTooltip,
    buttonTooltipPosition,
    onOpenChange,
    children,
    itemMap,
    testId,
    colorScheme,
    popoverPlacement,
    popoverOffset,
    menuClassName,
}) => {
    const state = useMenuTriggerState({onOpenChange});

    const DEFAULT_OFFSET = 8;

    const ref = React.useRef(null);

    const processOnAction = (key: Key) => {
        const item = itemMap.get(key);
        if (item) {
            item.onItemClick?.();
            if (item.closeMenuOnItemClick !== false) {
                state.close();
            }
        }
    };

    const {menuTriggerProps, menuProps} = useMenuTrigger<typeof Item>(
        {type: 'menu'},
        state,
        ref,
    );

    const {buttonProps} = useButton(menuTriggerProps, ref);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- filter out invalid properties
    const {onPress, onPressStart, ...ariaButtonProps} = menuTriggerProps;

    const enrichButton = () => {
        if (React.isValidElement(button)) {
            const refButton = buttonTooltip ? (
                <Tooltip
                    text={buttonTooltip}
                    position={buttonTooltipPosition}
                    role="tooltip"
                >
                    <RefButton {...button.props} ref={ref}></RefButton>
                </Tooltip>
            ) : (
                <RefButton {...button.props} ref={ref}></RefButton>
            );
            return React.cloneElement(refButton, {
                ...mergeProps(buttonProps, ariaButtonProps),
                ref: ref,
            } as React.Attributes);
        }
        return button;
    };

    const processedButton = enrichButton();

    return (
        <div className={cx(styles.innerMenuButton)}>
            {processedButton}
            {state.isOpen && (
                <InnerAriaMenuPopover
                    state={state}
                    triggerRef={ref}
                    colorScheme={colorScheme}
                    placement={popoverPlacement ?? 'bottom start'}
                    offset={popoverOffset ?? DEFAULT_OFFSET}
                >
                    {headerContent}
                    <InnerAriaMenu
                        itemMap={itemMap}
                        {...menuProps}
                        // Set onClose to undefine. This will prevent the menu from closing when the user clicks an item.
                        // The decision can be made in the onAction callback for each item individually.
                        onClose={undefined}
                        onAction={processOnAction}
                        testId={testId}
                        menuClassName={menuClassName}
                    >
                        {children}
                    </InnerAriaMenu>
                </InnerAriaMenuPopover>
            )}
        </div>
    );
};
