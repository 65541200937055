import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {Form, TextArea} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {
    CHARACTER_LIMIT,
    DEFAULT_CHAT_INPUT_HEIGHT,
    KEYBOARD_EVENT_KEY,
} from '../../constants';

import {ChatPanelInputButtons} from './ChatPanelInputButtons.view';

import styles from './ChatPanelInput.module.scss';

const formId = 'chatInputForm';
const inputId = 'chatTextInput';

export const ChatPanelInput: React.FC<{
    isDisabled?: boolean;
    onSubmit: (message: string) => void;
    placeholder: string;
    className?: string;
    maxHeight?: number;
}> = ({
    onSubmit,
    isDisabled = false,
    placeholder,
    className,
    maxHeight = DEFAULT_CHAT_INPUT_HEIGHT,
    ...props
}) => {
    const {t} = useTranslation();
    const [value, setValue] = useState('');

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
                onSubmit(value);
                setValue('');
            }}
            id={formId}
            className={cx(styles.form, className)}
            data-testid={TestId.ChatForm}
        >
            <TextArea
                isAutoResize
                isLabelHidden
                autoResizeMaxHeight={maxHeight}
                rows={1}
                id={inputId}
                label={t('meeting.write-message', 'Write a message')}
                className={styles.wrapper}
                textareaClassname={styles.input}
                onValueChange={newValue => setValue(newValue)}
                value={value}
                placeholder={placeholder}
                isDisabled={isDisabled}
                autoComplete="off"
                data-testid={TestId.ChatInput}
                maxLength={CHARACTER_LIMIT}
                onKeyDown={e => {
                    if (e.key === KEYBOARD_EVENT_KEY.enter) {
                        /*
                         * If shift key is pressed we want to create a new line in textarea.
                         * If only enter is pressed we submit.
                         */
                        if (!e.shiftKey) {
                            e.preventDefault();
                            e.currentTarget.form?.dispatchEvent(
                                new Event('submit', {
                                    cancelable: true,
                                    bubbles: true,
                                }),
                            );
                        }
                    }
                }}
                {...props}
            />
            <ChatPanelInputButtons isDisabled={!value || isDisabled} />
        </Form>
    );
};
