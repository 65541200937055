import type {BlockedBrowserPermissionsInfoType} from '@pexip/media-components';

export enum PreflightJoinDetailsToRender {
    BlockedDeviceSelection = 'blocked-device-selection',
    DeviceSelection = 'device-selection',
    Spinner = 'spinner',
}

export interface Idp {
    name: string;
    uuid: string;
    redirectUrl?: string;
    img?: string;
    disablePopupFlow?: boolean;
}

export enum FlowType {
    Express = 'express',
    StepByStep = 'step-by-step',
}

export interface BlockedBrowserPermissionsInfo {
    // vp9 is a webm file format
    vp9: string | undefined;
    mp4: string | undefined;
    browserName: string;
    link?: string;
    helpSteps?: Array<{step: number; text: string}>;
    videoTextOverlaySteps?: Array<{
        step: number;
        text: string;
        duration: number;
    }>;
    permissionInfoType: BlockedBrowserPermissionsInfoType;
}

export interface BrowserDetection<T> {
    onChromeOnMobile: () => T;
    onChromeOnDesktop: () => T;
    onFirefoxOnDesktop: () => T;
    onEdgeOnMobile: () => T;
    onEdgeOnDesktop: () => T;
    onSafariOnIPhone: () => T;
    onSafariOnIpad: () => T;
    onSafariOnMacOs: () => T;
    onOtherBrowser: () => T;
}

export type BrowserType =
    | 'chrome-mobile'
    | 'chrome'
    | 'edge'
    | 'edge-macosx'
    | 'firefox'
    | 'safari-ios'
    | 'safari-ipados'
    | 'safari-macosx'
    | 'other';

export interface PreflightTipsInfo {
    step: number;
    text: React.ReactNode;
}

export enum MeetingFlow {
    Loading,
    AreYouHost,
    EnterPin,
    EnterHostPin,
    EnterExtension,
    Idp,
    InMeeting,
    Error,
}

export type TestIdValue = string;
export type GetState<T, K extends keyof T> = () => Pick<T, K>;

export type Unsubscribe = () => void;
export type OnChange = () => void;
export type Subscribe = (onChange: OnChange) => Unsubscribe;

export enum StepByStepCompletion {
    Uncompleted = 'uncompleted',
    Completed = 'completed',
    Skipped = 'skipped',
    Reviewing = 'reviewing',
}

export type UserMenuStep =
    | 'menu'
    | 'video-and-sound'
    | 'quality'
    | 'meeting-settings'
    | 'layouts'
    | 'accessibility'
    | 'language'
    | 'about'
    | 'stats'
    | 'additional-settings';

export enum ConfirmationModalStates {
    Layout = 'LAYOUT',
    None = 'NONE',
}

export enum BrowserCloseConfirmation {
    Unset = 'unset',
    Enabled = 'enabled',
    Disabled = 'disabled',
}

export type AppContextState = {
    isOnStepByStepJoinScreen: boolean;
    isAriaMenuOpen: boolean;
};

export type SetAppContext = <K extends keyof AppContextState>(
    key: K,
    updater: (prevValue: AppContextState[K]) => AppContextState[K],
) => void;

export type AppContextType = {
    appContext: AppContextState;
    setAppContext: SetAppContext;
};

export interface ImageRecord {
    key: string;
    name: string;
    data: Blob;
}

export interface FeedbackConfig {
    enable?: boolean;
    url?: string;
}
