import React from 'react';

export const convertNewLinesToBr = (text: string): React.ReactNode => {
    if (!text.includes('\n')) {
        return text;
    }
    return text.split('\n').map((textLine, i) => (
        <React.Fragment key={i}>
            {textLine}
            <br />
        </React.Fragment>
    ));
};
