import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {PanelHeader, SettingsPanel} from '@pexip/media-components';
import {ActionsRow, ThemeProvider, ToggleSwitch} from '@pexip/components';

import {BrowserCloseConfirmation} from '../types';
import {TestId} from '../../test/testIds';
import {useBrowserCloseConfirmationConfig} from '../hooks/useBrowserCloseConfirmationConfig';
import {useConfig} from '../config';

export const AdditionalSettings: React.FC<{
    onBackClick: () => void;
}> = ({onBackClick}) => {
    const {
        browserCloseConfirmation,
        setBrowserCloseConfirmation,
        shouldShowBrowserCloseConfirmation,
        isEnableBrowserCloseConfirmationByDefault,
    } = useBrowserCloseConfirmationConfig();
    const [
        showParticipantAuthenicatedState,
        setShowParticipantAuthenicatedState,
    ] = useConfig('showParticipantAuthenicatedState');

    const toggleBrowserClosePrevention = useCallback(() => {
        switch (browserCloseConfirmation) {
            case BrowserCloseConfirmation.Enabled:
                setBrowserCloseConfirmation(
                    BrowserCloseConfirmation.Disabled,
                    true,
                );
                break;
            case BrowserCloseConfirmation.Disabled:
                setBrowserCloseConfirmation(
                    BrowserCloseConfirmation.Enabled,
                    true,
                );
                break;
            case BrowserCloseConfirmation.Unset:
                if (isEnableBrowserCloseConfirmationByDefault) {
                    setBrowserCloseConfirmation(
                        BrowserCloseConfirmation.Disabled,
                        true,
                    );
                } else {
                    setBrowserCloseConfirmation(
                        BrowserCloseConfirmation.Enabled,
                        true,
                    );
                }
        }
    }, [
        browserCloseConfirmation,
        isEnableBrowserCloseConfirmationByDefault,
        setBrowserCloseConfirmation,
    ]);

    const {t} = useTranslation();

    return (
        <>
            <SettingsPanel
                headerContent={
                    <ThemeProvider colorScheme="light">
                        <PanelHeader
                            title={`${t(
                                'settings.additional',
                                'Additional settings',
                            )}`}
                            onBackClick={onBackClick}
                        />
                    </ThemeProvider>
                }
            >
                <ThemeProvider colorScheme="light">
                    <ActionsRow
                        title={t(
                            'settings.browser-close-prevention',
                            'Browser close confirmation',
                        )}
                        subtitle={t(
                            'settings.browser-close-prevention-desc',
                            'You must confirm before closing the browser or tab in which the meeting is currently running',
                        )}
                    >
                        {/* TODO: The inline style for padding-left should be removed when the settings panel is redesigned */}
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={toggleBrowserClosePrevention}
                                labelModifier="hidden"
                                label={''}
                                name="name-labels-overlay"
                                data-testid={
                                    TestId.ToggleBrowserCloseConformation
                                }
                                checked={shouldShowBrowserCloseConfirmation}
                            />
                        </div>
                    </ActionsRow>
                    <ActionsRow
                        title={t(
                            'settings.show-participant-authenticated-state-title',
                            'Show authenticated participants',
                        )}
                        subtitle={t(
                            'settings.show-participant-authenticated-state-desc',
                            'When turned on you will see which participants are authenticated and which ones are not in the participants panel',
                        )}
                    >
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={isChecked =>
                                    setShowParticipantAuthenicatedState(
                                        isChecked,
                                        true,
                                    )
                                }
                                labelModifier="hidden"
                                label={''}
                                name="name-labels-overlay"
                                data-testid={
                                    TestId.ToggleBrowserCloseConformation
                                }
                                checked={showParticipantAuthenicatedState}
                            />
                        </div>
                    </ActionsRow>
                </ThemeProvider>
            </SettingsPanel>
        </>
    );
};
