import {useState} from 'react';

import {config} from '../config';

export const useSelfviewMirrorMode = () => {
    const [isSelfviewMirrored] = useState(
        config.get('isUserFacing') ? config.get('mirrorSelfview') : false,
    );

    return [isSelfviewMirrored] as const;
};
