import React from 'react';
import cx from 'classnames';

import {ChatPanelInputSendButton} from './ChatPanelInputSendButton.view';

import styles from './ChatPanelInputButtons.module.scss';

export const ChatPanelInputButtons: React.FC<{
    isDisabled: boolean;
}> = ({isDisabled}) => (
    <div className={cx(styles.wrapper)}>
        <ChatPanelInputSendButton disabled={isDisabled} />
    </div>
);
