import cx from 'classnames';
import React, {
    forwardRef,
    useRef,
    useCallback,
    useContext,
    useImperativeHandle,
    useState,
    useId,
} from 'react';

import type {BaseSizeModifier} from '../../../types/sizes';
import type {
    ButtonVariant,
    ColorScheme,
    InputVariant,
    LabelModifier,
} from '../../../types/variants';
import {Text} from '../Text/Text';
import {TestId} from '../../../utils/testIds';
import {InputLabel} from '../InputLabel/InputLabel';
import {ThemeContext} from '../../../themes/ThemeContext';
import {Button} from '../Button/Button';
import {Icon, IconTypes} from '../Icon/Icon';

import styles from './Input.module.scss';

export type TextLikeInputType =
    | 'text'
    | 'password'
    | 'number'
    | 'email'
    | 'search'
    | 'tel'
    | 'url'
    | 'date';

export const Input = forwardRef<
    Pick<HTMLInputElement, 'focus'>,
    Omit<React.ComponentPropsWithRef<'input'>, 'disabled'> & {
        clearButtonText?: string;
        clearButtonVariant?: ButtonVariant;
        colorScheme?: ColorScheme;
        enhancerEnd?: React.ReactNode;
        enhancerStart?: React.ReactNode;
        errorText?: string;
        hasError?: boolean;
        id?: string;
        isDisabled?: boolean;
        label: string;
        labelModifier?: LabelModifier;
        name: string;
        onClear?: (value: string) => void;
        onValueChange?: (value: string) => void;
        showClearButton?: boolean;
        sizeModifier?: BaseSizeModifier;
        testId?: string;
        type?: TextLikeInputType;
        value?: string;
        variant?: InputVariant;
    }
>(
    (
        {
            className,
            clearButtonText = 'Clear',
            clearButtonVariant,
            colorScheme,
            enhancerEnd,
            enhancerStart,
            errorText,
            hasError,
            id,
            isDisabled,
            label,
            labelModifier,
            name,
            onChange: inheritedOnChange,
            onClear,
            onValueChange,
            showClearButton = false,
            sizeModifier = 'medium',
            testId,
            type = 'text',
            value,
            variant = 'standard',
            ...props
        },
        ref,
    ) => {
        const defaultId = useId();
        const effectiveId = id ?? defaultId;

        const isLabelHidden = labelModifier === 'hidden';
        const isLabelInline = labelModifier === 'inline';

        const shouldLabelBeInline = isLabelInline || isLabelHidden;

        const input = useRef<HTMLInputElement | null>(null);

        const onClearInputClick = () => {
            const currentInput = input.current;

            if (onClear) {
                onClear(currentInput?.value ?? '');
            } else {
                onValueChange?.('');
            }

            if (currentInput) {
                currentInput.focus();
            }
        };

        const onChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                if (onValueChange) {
                    onValueChange(e.currentTarget.value);
                }
                if (inheritedOnChange) {
                    inheritedOnChange(e);
                }
            },
            [inheritedOnChange, onValueChange],
        );

        const labelProps = {text: label, id: effectiveId};

        useImperativeHandle(ref, () => ({
            focus: options => {
                if (input.current) {
                    input.current.focus(options);
                }
            },
            value: input.current?.value ?? '',
        }));

        const {colorScheme: defaultColorScheme, theme} =
            useContext(ThemeContext);

        return (
            <div
                className={cx(
                    colorScheme ?? defaultColorScheme,
                    styles[variant],
                    styles[colorScheme ?? defaultColorScheme],
                    {
                        [styles.disabled]: isDisabled,
                        [styles.error]: hasError && !isDisabled,
                    },
                    className,
                )}
            >
                {!shouldLabelBeInline && (
                    <InputLabel
                        className={styles.label}
                        data-testid={testId ? `${testId}-label` : undefined}
                        htmlFor={effectiveId}
                        {...labelProps}
                    />
                )}
                <div
                    className={cx(styles.container, {
                        [styles.small]: sizeModifier === 'small',
                        [styles.medium]: sizeModifier === 'medium',
                    })}
                >
                    {shouldLabelBeInline && (
                        <InputLabel
                            isLabelHidden={isLabelHidden}
                            isLabelInline={isLabelInline}
                            data-testid={testId ? `${testId}-label` : undefined}
                            htmlFor={effectiveId}
                            {...labelProps}
                        />
                    )}
                    {enhancerStart && (
                        <div className={cx(styles.enhancerStart, 'mr-2')}>
                            {enhancerStart}
                        </div>
                    )}
                    <input
                        className={cx(styles.inputElement, {
                            [styles.small]: sizeModifier === 'small',
                            [styles.medium]: sizeModifier === 'medium',
                        })}
                        data-testid={testId}
                        disabled={isDisabled}
                        id={effectiveId}
                        name={name}
                        onChange={onChange}
                        ref={input}
                        type={type}
                        value={value}
                        {...props}
                    />
                    {type === 'date' && (
                        <Icon
                            source={IconTypes.IconCalendar}
                            className={styles.dateSelectButton}
                        />
                    )}
                    {enhancerEnd && (
                        <div className={styles.enhancerEnd}>{enhancerEnd}</div>
                    )}
                    {showClearButton && !isDisabled && (
                        <Button
                            type="button"
                            size="compact"
                            className={styles.clearButton}
                            onClick={onClearInputClick}
                            aria-label="Clear input"
                            data-testid={TestId.InputClearButton}
                            variant={
                                clearButtonVariant ??
                                (theme === 'theme-legacy'
                                    ? 'transparent'
                                    : 'translucent')
                            }
                            colorScheme={colorScheme ?? defaultColorScheme}
                        >
                            {clearButtonText}
                        </Button>
                    )}
                </div>
                {hasError && errorText && (
                    <Text
                        className={cx('mt-2', styles.errorText)}
                        variant="danger"
                        role="alert"
                    >
                        {errorText}
                    </Text>
                )}
            </div>
        );
    },
);

Input.displayName = 'Input';

export type InputProps = React.ComponentProps<typeof Input>;

export const useInput = (props: InputProps): [string, InputProps] => {
    const [value, setValue] = useState(props.value ?? '');

    return [
        value,
        {
            ...props,
            onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                setValue(e.currentTarget.value),
            value,
        },
    ];
};
