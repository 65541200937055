import type React from 'react';
import {useEffect} from 'react';

/*
 * Expands the height of a textarea to show all of the content inside so that scrollbars are not rendered
 */
export const useAutosizeTextarea = ({
    textareaElement,
    textareaValue,
    enabled,
    maxHeight,
}: {
    textareaElement: HTMLTextAreaElement | null;
    textareaValue: React.TextareaHTMLAttributes<HTMLTextAreaElement>['value'];
    enabled?: boolean;
    maxHeight?: number;
}) => {
    useEffect(() => {
        if (textareaElement && enabled) {
            // temporarily set height to get the correct scrollHeight for the textarea
            textareaElement.style.height = '0px';
            const scrollHeight = textareaElement.scrollHeight;
            const height =
                typeof maxHeight === 'number' && scrollHeight > maxHeight
                    ? maxHeight
                    : scrollHeight + 1;
            textareaElement.style.height = height + 'px';
        }
    }, [textareaElement, textareaValue, maxHeight, enabled]);
};
