import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {notificationToastSignal} from '@pexip/components';

import type {BreakoutParticipants, BreakoutRoomName} from '../types';
import {isDuplicateRoomName} from '../utils/isDuplicateRoomName';
import {getBreakoutRooms} from '../utils/getBreakoutRooms';

export const useBreakoutAddRoom = ({
    setParticipants,
    existingBreakoutNames = [],
}: {
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
    existingBreakoutNames?: BreakoutRoomName[];
}) => {
    const {t} = useTranslation();

    return useCallback(() => {
        setParticipants(prevParticipants => {
            const currentParticipants = new Map(prevParticipants.entries());
            const ROOM = t('breakouts.room', 'Room');
            const DUPLICATE = t('breakouts.duplicate', 'duplicate');
            let newRoom = `${ROOM} ${[...prevParticipants.keys()].length}`;
            if (
                isDuplicateRoomName(newRoom, [
                    ...getBreakoutRooms(prevParticipants),
                    ...existingBreakoutNames,
                ])
            ) {
                newRoom = `${newRoom} (${DUPLICATE})`;
            }
            return currentParticipants.set(newRoom, []);
        });
        notificationToastSignal.emit([
            {
                message: t(
                    'breakouts.add-room-done-notification',
                    'A new room has been added',
                ),
            },
        ]);
    }, [existingBreakoutNames, setParticipants, t]);
};
