import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {useCountDown} from '@pexip/hooks';
import {
    Modal,
    ThemeProvider,
    FontVariant,
    Text,
    Button,
    ProgressBar,
    ProgressBarColor,
} from '@pexip/components';
import type {ModalProps} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './MoveToRoomModal.module.scss';

export const MoveToRoomModalView: React.FC<
    Omit<ModalProps, 'isOpen'> & {
        roomName: string;
        secondsBeforeMove: number;
        onJoin: () => void;
    }
> = ({className, roomName, secondsBeforeMove, onJoin, ...props}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(secondsBeforeMove > 0);

    const onJoinNow = () => {
        onJoin();
        setIsOpen(false);
        cleanup();
    };

    const {startCountDown, currentCount, cleanup} = useCountDown(
        {
            startCountFrom: secondsBeforeMove,
            enabled: true,
        },
        onJoinNow,
    );

    useEffect(() => {
        startCountDown();
        return cleanup;
    }, [cleanup, startCountDown]);

    // Highlight room name
    const highlightedRoomName = `<strong>${roomName}</strong>`;

    return (
        <ThemeProvider colorScheme="light">
            <Modal
                isOpen={isOpen}
                closeOnOutsideClick
                className={cx(styles.modalWrapper, className)}
                data-testid={TestId.ModalMoveToRoom}
                {...props}
            >
                <ProgressBar
                    backgroundClassName={styles.progressBarWrapper}
                    barClassName={styles.progressBar}
                    progress={secondsBeforeMove === currentCount ? 0 : 100}
                    barColor={ProgressBarColor.Primary}
                    style={{
                        transitionDuration: `${secondsBeforeMove - 1}s`,
                    }}
                />
                <Text
                    fontVariant={FontVariant.Body}
                    htmlTag="p"
                    className="mt-1 mb-2"
                >
                    <Trans
                        t={t}
                        i18nKey="breakouts.move-to-room-modal.title"
                        defaults="You will be moved to {{roomName}} in {{currentCount}} {{secondString}}."
                        values={{
                            roomName: highlightedRoomName,
                            currentCount,
                            secondString:
                                currentCount >= 2
                                    ? t('common.seconds', 'seconds')
                                    : t('common.second', 'second'),
                        }}
                    />
                </Text>
                <Text
                    fontVariant={FontVariant.Body}
                    htmlTag="p"
                    className="mb-4"
                >
                    <Trans t={t} i18nKey="breakouts.move-to-room-modal.text">
                        You can join right now if you are ready.
                    </Trans>
                </Text>
                <Button
                    data-testid={TestId.ButtonMoveToBreakoutNow}
                    modifier="fullWidth"
                    size="large"
                    onClick={onJoinNow}
                >
                    <Trans
                        t={t}
                        i18nKey="breakouts.move-to-room-modal.join-now-btn"
                        aria-label={t(
                            'breakouts.move-to-room-modal.aria-label-btn',
                            {
                                defaultValue: 'Join {{roomName}} now',
                                roomName,
                            },
                        )}
                    >
                        Join now
                    </Trans>
                </Button>
            </Modal>
        </ThemeProvider>
    );
};

export type MoveToRoomModalViewProps = React.ComponentProps<
    typeof MoveToRoomModalView
>;
