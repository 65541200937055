import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {BadgeCounter, Icon, IconTypes} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {InMeetingInputControl} from '../InputControl/InMeetingInputControl.view';

export const ParticipantsPanelToggleView: React.FC<
    ButtonProps & {
        isPanelOpen: boolean;
        inMeetingParticipantsCount?: number;
        participantsActionCount?: number;
        shouldAnimate?: boolean;
        isTouchDevice: boolean;
    }
> = ({
    isPanelOpen,
    inMeetingParticipantsCount = 0,
    participantsActionCount = 0,
    shouldAnimate,
    isTouchDevice,
    ...props
}) => {
    const {t} = useTranslation();
    const showNotifications = !isPanelOpen && participantsActionCount > 0;
    return (
        <BadgeCounter
            data-testid={TestId.BadgeCounterParticipantPanelButton}
            variant="info"
            isTruncated={showNotifications}
            isVisible={inMeetingParticipantsCount > 0}
            number={inMeetingParticipantsCount}
            shouldAnimate={shouldAnimate}
        >
            <InMeetingInputControl
                aria-label={t(
                    'meeting.toggle-participants-panel',
                    'Participants panel toggle',
                )}
                data-testid={TestId.ButtonParticipants}
                tooltipText={t(
                    'meeting.toggle-participants-panel-tooltip',
                    'Participants',
                )}
                tooltipPosition="right"
                shouldShowTooltip={!isTouchDevice}
                icon={<Icon source={IconTypes.IconGroup} size="small" />}
                isActive={isPanelOpen}
                {...props}
            />
        </BadgeCounter>
    );
};
export type ParticipantsToggleViewProps = React.ComponentProps<
    typeof ParticipantsPanelToggleView
>;

export type ParticipantsToggleButtonProps = React.ComponentProps<
    typeof ParticipantsPanelToggleView
>;
