import {useContext} from 'react';

import type {MenuItem} from '@pexip/components';
import type {PopupRequest} from '@pexip/plugin-api';
import type {Participant} from '@pexip/infinity';

import {Channels} from '../index';
import type {SettingsMenuBtn} from '../types';

import {usePopupRequest} from './usePopupRequest';
import {useOrderedPlugins} from './useOrderedPlugins';

export const useSettingsMenuActions = (
    me: Participant | undefined,
    isInMeeting: boolean,
) => {
    const channelsRef = useContext(Channels);
    const openPopup = usePopupRequest();

    const orderedPlugins = useOrderedPlugins();
    const settingsMenuActions = orderedPlugins
        .flatMap(plugin => plugin.buttons)
        .filter(btn => btn.position === 'settingsMenu') as SettingsMenuBtn[];

    const handleClick = (
        channelId: string,
        buttonId: string,
        opensPopup?: PopupRequest,
    ) => {
        if (opensPopup) {
            openPopup(channelId, opensPopup);
        }
        channelsRef?.current.get(channelId)?.sendEvent({
            event: 'ui:button:click',
            payload: {
                buttonId,
                input: undefined,
            },
        });
    };

    return settingsMenuActions
        .filter(({roles, inMeetingOnly}) => {
            if (isInMeeting && me) {
                return !roles || roles?.includes(me?.role);
            } else {
                return !inMeetingOnly;
            }
        })
        .map(action => ({
            label: action.label,
            clickAction: () =>
                handleClick(action.chanId, action.id, action.opensPopup),
        })) as MenuItem[];
};
