import React, {createContext, useContext} from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../types/variants';

import styles from './ThemeContext.module.scss';

export const themeValues = [
    'theme-legacy',
    // Pexip Design System
    'theme-pds',
] as const;

export type Theme = (typeof themeValues)[number];

export interface ThemeContextProps {
    colorScheme: ColorScheme;
    theme?: Theme;
}

export const ThemeContext = createContext<ThemeContextProps>({
    colorScheme: 'dark',
    // As of writing the "theme-pds" is empty and thus the same as undefined,
    // but in the future it can be used alongside theme-legacy.
    theme: 'theme-pds',
});

export const {Consumer: ThemeConsumer} = ThemeContext;

export const ThemeProvider: React.FC<
    React.PropsWithChildren<ThemeContextProps>
> = ({children, colorScheme, theme: themeInput}) => {
    const {theme: currentTheme} = useContext(ThemeContext);
    const theme = themeInput ?? currentTheme;

    return (
        <ThemeContext.Provider value={{colorScheme, theme}}>
            <div className={cx(styles.themeProvider, theme)}>{children}</div>
        </ThemeContext.Provider>
    );
};

export function withColorScheme<P extends object>(
    Component: React.ComponentType<P>,
    colorScheme: ColorScheme,
) {
    return function WrappedWithColorScheme(props: P) {
        return (
            <ThemeProvider colorScheme={colorScheme}>
                <Component {...props} />
            </ThemeProvider>
        );
    };
}
