import {useCallback, useContext} from 'react';

import {Plugins, Channels} from '..';
import {useBranding} from '../../branding/Context';
import type {PluginElements} from '../types';

export const useOrderedPlugins = () => {
    const pluginManifest = useBranding('plugins');
    const plugins = useContext(Plugins);
    const channelsRef = useContext(Channels);

    const findChannelId = useCallback(
        (pluginId?: string) =>
            Array.from(
                (channelsRef?.current ?? new Map<string, unknown>()).keys(),
            ).find(channelId => pluginId && channelId.includes(pluginId)),
        [channelsRef],
    );

    return pluginManifest && pluginManifest.every(plugin => plugin.id)
        ? (pluginManifest ?? []).map(plugin => {
              const channelId = findChannelId(plugin.id);
              return (channelId ? plugins[channelId] : {}) as PluginElements;
          })
        : Object.values(plugins);
};
