import React from 'react';
import {useTranslation} from 'react-i18next';

import {PanelHeader, SettingsPanel} from '@pexip/media-components';
import {ActionsRow, ThemeProvider, ToggleSwitch} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const AccessibilitySettingsView: React.FC<{
    alwaysDisplayUIInterfaces: boolean;
    toggleAlwaysDisplayUIInterfaces: () => void;
    disableKeyboardShortcuts: boolean;
    toggleDisableKeyboardShortcuts: () => void;
    onBackClick: () => void;
}> = ({
    alwaysDisplayUIInterfaces,
    toggleAlwaysDisplayUIInterfaces,
    disableKeyboardShortcuts,
    toggleDisableKeyboardShortcuts,
    onBackClick,
}) => {
    const {t} = useTranslation();

    return (
        <>
            <SettingsPanel
                headerContent={
                    <ThemeProvider colorScheme="light">
                        <PanelHeader
                            title={`${t(
                                'settings.accessibility',
                                'Accessibility',
                            )}`}
                            onBackClick={onBackClick}
                        />
                    </ThemeProvider>
                }
            >
                <ThemeProvider colorScheme="light">
                    <ActionsRow
                        title={t(
                            'settings.always-display-ui-controls',
                            'Always display call controls',
                        )}
                        subtitle={t(
                            'settings.always-display-ui-controls-desc',
                            'Call controls will remain in view at all times and will not fade away even after a few seconds of inactivity.',
                        )}
                    >
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={toggleAlwaysDisplayUIInterfaces}
                                labelModifier="hidden"
                                label={''}
                                name="always-display-ui-controls-toggle"
                                data-testid={
                                    TestId.ToggleAlwaysDisplayUiControls
                                }
                                checked={alwaysDisplayUIInterfaces}
                            ></ToggleSwitch>
                        </div>
                    </ActionsRow>
                    <ActionsRow
                        title={t(
                            'settings.disable-app-shotcuts',
                            'Disable app shortcuts',
                        )}
                        subtitle={t(
                            'settings.disable-app-shotcuts-desc',
                            'Recommended when using screen readers.',
                        )}
                    >
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={toggleDisableKeyboardShortcuts}
                                labelModifier="hidden"
                                label={''}
                                name="enable-keyboard-shortcuts-toggle"
                                data-testid={
                                    TestId.ToggleDisableKeyboardShortcuts
                                }
                                checked={disableKeyboardShortcuts}
                            ></ToggleSwitch>
                        </div>
                    </ActionsRow>
                </ThemeProvider>
            </SettingsPanel>
        </>
    );
};

export type AccessibilitySettingsViewProps = React.ComponentProps<
    typeof AccessibilitySettingsView
>;
