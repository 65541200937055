import type {SyntheticEvent} from 'react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import type {MenuItem} from '@pexip/components';
import {
    Icon,
    IconTypes,
    Button,
    KebabMenuButton,
    Menu,
} from '@pexip/components';

import type {BreakoutRoomId, BreakoutRoomName} from '../../types';
import {IDs} from '../../ids';
import {MainBreakoutRoomId} from '../../constants';

import {withClose} from './utils';

import styles from './ParticipantRowActions.module.scss';

export const AssignRoomMenu: React.FC<{
    close: (e: SyntheticEvent<HTMLElement>) => void;
    rooms: Record<BreakoutRoomId, BreakoutRoomName>;
    onRoomChange: (roomId: BreakoutRoomId) => void;
    onPointerDownCapture?:
        | React.PointerEventHandler<HTMLDivElement>
        | undefined;
}> = ({rooms, onRoomChange, close, onPointerDownCapture}) => {
    const {t} = useTranslation();
    const handleAction = withClose(close);

    const content = Object.entries(rooms).map(
        ([id, roomName]) =>
            ({
                label:
                    id === MainBreakoutRoomId
                        ? t('breakouts.breakout-room.main-name', 'Main Room')
                        : roomName,
                clickAction: handleAction(() => onRoomChange(id)),
            }) as MenuItem,
    );
    return (
        <Menu
            onPointerDownCapture={onPointerDownCapture}
            menuContent={{
                [t(
                    'breakouts.breakout-room.send-participant-to',
                    'Send participant to:',
                )]: content,
            }}
        />
    );
};

export const BreakoutParticipantRowActions: React.FC<
    Omit<AssignRoomMenuProps, 'close'> & {isHover: boolean; isDragging: boolean}
> = ({rooms, onRoomChange, isHover, isDragging}) => {
    const [isOpen, setOpen] = useState(false);
    const isInteracted = isHover || isOpen;

    return (
        <>
            {!isDragging && isInteracted && (
                <KebabMenuButton
                    position="bottomMiddle"
                    buttonSize="compact"
                    buttonModifier="square"
                    direction="horizontal"
                    buttonVariant="primary"
                    reactiveButtonStyles={false}
                    menuComponent={close => (
                        <AssignRoomMenu
                            onPointerDownCapture={e => e.stopPropagation()}
                            close={(e: SyntheticEvent<HTMLElement>) => {
                                close(e);
                            }}
                            rooms={rooms}
                            onRoomChange={onRoomChange}
                        />
                    )}
                    onContentVisibilityChange={setOpen}
                    onPointerDownCapture={e => e.stopPropagation()}
                    onPointerUpCapture={e => e.stopPropagation()}
                />
            )}
            <Button
                aria-label="Drag participant"
                className={cx(styles.indicator, styles.dragButton)}
                size="compact"
                modifier="square"
                variant="translucent"
                noHover={true}
            >
                <Icon
                    id={IDs.BreakoutParticipantDragSvg}
                    size="small"
                    source={IconTypes.IconDraggable}
                />
            </Button>
        </>
    );
};

type AssignRoomMenuProps = React.ComponentProps<typeof AssignRoomMenu>;
export type BreakoutParticipantRowActionsProps = React.ComponentProps<
    typeof BreakoutParticipantRowActions
>;
