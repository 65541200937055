import React from 'react';
import cx from 'classnames';
import {t} from 'i18next';
import {Trans} from 'react-i18next';

import {List, Row, FontVariant, Text} from '@pexip/components';

import {
    InputControlTooltip,
    InputControlTooltipClose,
} from '../InputControlTooltip/InputControlTooltip.view';
import {TestId} from '../../../test/testIds';

export const MuteDisabledTooltip: React.FC<
    Omit<React.ComponentProps<typeof InputControlTooltip>, 'content'>
> = props => {
    return (
        <InputControlTooltip
            content={
                <List className={cx([props.isTouch ? 'pl-0 pr-0' : ''])}>
                    <Row className="pt-3 pb-1">
                        <Text fontVariant={FontVariant.BodyBold}>
                            {t(
                                'media.host-muted-you',
                                'The host has muted you.',
                            )}
                        </Text>
                        <InputControlTooltipClose onClose={props.onClose} />
                    </Row>
                    <Row className="pt-0">
                        <Text>
                            <Trans
                                t={t}
                                i18nKey="media.host-muted-you-descriptions"
                            >
                                You will be able to unmute yourself once the
                                host has given you permission.
                            </Trans>{' '}
                        </Text>
                    </Row>
                </List>
            }
            position="topLeft"
            data-testid={TestId.TooltipMuteDisabled}
            {...props}
        />
    );
};
