import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Avatar,
    AvatarType,
    FontVariant,
    TextHeading,
    Text,
} from '@pexip/components';

import styles from './ParticipantRowMoreActionsHeader.module.scss';

export const ParticipantRowMoreActionsHeader: React.FC<
    React.ComponentProps<'div'> & {
        isTouchDevice: boolean;
        displayName: string;
        isAuthenticated: boolean;
        showParticipantAuthenticatedState: boolean;
    }
> = ({
    isTouchDevice,
    className,
    displayName,
    isAuthenticated,
    showParticipantAuthenticatedState,
}) => {
    const {t} = useTranslation();
    return (
        <div className={cx(styles.container, className)}>
            <Avatar
                colorScheme="dark"
                displayName={displayName}
                sizeModifier={isTouchDevice ? 'medium' : 'small'}
                type={AvatarType.Initials}
            />
            <TextHeading
                htmlTag={isTouchDevice ? FontVariant.H2 : FontVariant.H5}
                fontVariant={isTouchDevice ? FontVariant.H2 : FontVariant.H5}
                className={`${isTouchDevice ? 'mt-3' : 'mt-1'} text-center`}
            >
                {displayName}
            </TextHeading>
            {showParticipantAuthenticatedState && (
                <Text
                    fontVariant={
                        isTouchDevice ? FontVariant.BodyBold : FontVariant.Small
                    }
                    variant={isAuthenticated ? 'success' : 'danger'}
                    className={`${isTouchDevice ? 'mt-2' : ''} text-center`}
                >
                    {isAuthenticated
                        ? t('meeting.authenticated', 'Authenticated')
                        : t('meeting.unauthenticated', 'Unauthenticated')}
                </Text>
            )}
        </div>
    );
};
