import React, {useEffect, useId} from 'react';
import cx from 'classnames';

import {ThemeConsumer} from '../../../themes/ThemeContext';
import {Text} from '../../elements/Text/Text';
import type {ColorScheme, LabelModifier} from '../../../types/variants';
import {TestId} from '../../../utils/testIds';
import {IconTypes, Icon} from '../../elements/Icon/Icon';
import type {SizeModifier} from '../../../types/sizes';
import type {IconSource} from '../../../../design-tokens/generated/Icons';
import {InputLabel} from '../../elements/InputLabel/InputLabel';
import type {Option} from '../../../types/elements';
import {announce} from '../../elements/Announcer/Announcer';

import styles from './Select.module.scss';

export const Select: React.FC<
    Omit<React.ComponentPropsWithRef<'select'>, 'disabled'> & {
        colorScheme?: ColorScheme;
        errorText?: string;
        errorTextTestId?: string;
        hasError?: boolean;
        iconType?: IconSource;
        id?: string;
        isDisabled?: boolean;
        isFullWidth?: boolean;
        label: string;
        labelModifier?: LabelModifier;
        onValueChange: (id: string) => void;
        options: Option[];
        sizeModifier?: SizeModifier;
        value: string;
        wrapContent?: boolean;
    }
> = ({
    className,
    colorScheme,
    errorText,
    errorTextTestId,
    hasError,
    iconType,
    id,
    isDisabled,
    isFullWidth,
    label: selectLabel,
    labelModifier,
    onValueChange,
    options,
    sizeModifier = 'medium',
    value,
    wrapContent = false,
    ...props
}) => {
    const defaultId = useId();
    const effectiveId = id ?? defaultId;

    const selectedOptionValue = value ? value : options[0]?.id;

    const isLabelHidden = labelModifier === 'hidden';
    const isLabelInline = labelModifier === 'inline';

    const shouldLabelBeInline = isLabelInline || isLabelHidden;

    const labelProps = {text: selectLabel, id: effectiveId};

    const handleChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
        if (value !== e.currentTarget.value) {
            onValueChange(e.currentTarget.value);
        }
    };

    useEffect(() => {
        if (hasError && errorText) {
            announce(errorText, 'assertive');
        }
    }, [hasError, errorText]);

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <div
                    className={cx(
                        colorScheme ?? defaultColorScheme,
                        styles.container,
                        styles[colorScheme ?? defaultColorScheme],
                        {
                            [styles.disabled]: isDisabled,
                            [styles.error]: hasError && !isDisabled,
                            [styles.containerWrapContent]: wrapContent,
                        },
                        className,
                    )}
                >
                    {!shouldLabelBeInline && (
                        <InputLabel
                            className={cx(styles.label)}
                            {...labelProps}
                        />
                    )}
                    <div
                        className={cx(styles.selectWrapper, {
                            [styles.fullWidth]: isFullWidth,
                        })}
                    >
                        <select
                            className={styles.select}
                            id={effectiveId}
                            onBlur={handleChange}
                            onChange={handleChange}
                            value={selectedOptionValue}
                            disabled={isDisabled}
                            {...props}
                        >
                            {options.map(({id, label, isDisabled}) => (
                                <option
                                    disabled={isDisabled}
                                    key={id}
                                    value={id}
                                >
                                    {label}
                                </option>
                            ))}
                        </select>

                        <div
                            className={cx(styles.view, styles[sizeModifier], {
                                [styles.hasIcon]: !!iconType,
                            })}
                            data-testid={TestId.SelectView}
                        >
                            {shouldLabelBeInline && (
                                <InputLabel
                                    className={cx(styles.inlineLabel)}
                                    isLabelHidden={isLabelHidden}
                                    isLabelInline={isLabelInline}
                                    {...labelProps}
                                />
                            )}

                            {!!iconType && (
                                <Icon
                                    size="compact"
                                    source={iconType}
                                    className={styles.icon}
                                    data-testid={TestId.SelectIcon}
                                />
                            )}

                            <div className={styles.textWrapper}>
                                <span className={styles.text}>
                                    {options.find(
                                        option =>
                                            option.id === selectedOptionValue,
                                    )?.label ?? selectLabel}
                                </span>
                            </div>

                            <Icon
                                size={
                                    sizeModifier === 'medium'
                                        ? 'compact'
                                        : 'mini'
                                }
                                source={IconTypes.IconChevronDown}
                                className={styles.chevron}
                                data-testid={TestId.SelectChevron}
                            />
                        </div>
                    </div>

                    {hasError && errorText && (
                        <Text
                            className={cx('mt-2', styles.errorText)}
                            variant="danger"
                            data-testid={errorTextTestId}
                        >
                            {errorText}
                        </Text>
                    )}
                </div>
            )}
        </ThemeConsumer>
    );
};

export type SelectProps = React.ComponentProps<typeof Select>;
