// Menu.tsx
import React, {useRef} from 'react';
import type {Item} from '@react-stately/collections';
import type {Key} from '@react-types/shared';
import {useTreeState} from '@react-stately/tree';
import {useMenu} from '@react-aria/menu';
import type {AriaMenuProps} from '@react-aria/menu';
import cx from 'classnames';

import {InnerAriaMenuItem} from './InnerAriaMenuItem';
import type {AriaMenuItem} from './AriaMenu.types';

import styles from './AriaMenu.module.scss';

export const InnerAriaMenu: React.FC<
    AriaMenuProps<typeof Item> & {
        testId?: string;
        itemMap: Map<Key, AriaMenuItem>;
        menuClassName?: string;
    }
> = props => {
    // Create state based on the incoming props
    const state = useTreeState<typeof Item>(props);

    // Get props for the menu element
    const ref = useRef(null);
    const {menuProps} = useMenu(props, state, ref);

    return (
        <div
            {...menuProps}
            ref={ref}
            className={cx(
                styles.innerMenu,
                'px-2',
                'py-2',
                props.menuClassName,
            )}
            data-testid={props.testId}
        >
            {[...state.collection].map(item => (
                <InnerAriaMenuItem
                    itemMap={props.itemMap}
                    key={item.key}
                    item={item}
                    onAction={props.onAction}
                    state={state}
                />
            ))}
        </div>
    );
};
