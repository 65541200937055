import React, {useState} from 'react';
import cx from 'classnames';

import {useFocusFromOpened, useModal} from '@pexip/components';

import {TestId} from '../..';
import {useShouldPanelBeModal} from '../../hooks/useShouldPanelBeModal';

import styles from './ChatPanelContentWrapper.module.scss';

export const ChatPanelContentWrapper: React.FC<
    React.ComponentProps<'div'> & {
        children: React.ReactNode;
        className?: string;
        isShown: boolean;
        animateIn?: boolean;
        animateOut?: boolean;
    }
> = ({children, className, isShown, animateIn, animateOut, ...props}) => {
    const [containerElement, setContainerElement] =
        useState<HTMLDivElement | null>(null);
    useFocusFromOpened(containerElement, isShown);

    const shouldEnableModal = useShouldPanelBeModal(isShown);
    const {ref} = useModal(shouldEnableModal);

    return (
        <div ref={ref}>
            <div
                data-testid={TestId.ChatPanel}
                ref={setContainerElement}
                className={cx(
                    styles.panel,
                    styles.wrapper,
                    {
                        [styles.show]: isShown,
                        [styles.animateIn]: !!animateIn,
                        [styles.animateOut]: !!animateOut,
                    },
                    className,
                )}
                {...props}
            >
                {children}
            </div>
        </div>
    );
};
