import React, {useMemo} from 'react';
import cx from 'classnames';

import {Text} from '../Text/Text';
import type {ColorScheme} from '../../../types/variants';
import type {TooltipPosition} from '../../../types/propTypes';
import {Box} from '../Box/Box';
import {ThemeProvider} from '../../../themes/ThemeContext';
import {useAriaTooltip} from '../../../hooks/useAriaTooltip';

import styles from './Tooltip.module.scss';

/**
 * Tooltips need to be accessible to keyboard and screen reader users, so we
 * ensure that they are only placed on focusable and hoverable elements. For
 * example, plain text and disabled buttons aren't focusable, meaning keyboard
 * and screen reader users would be unable to access the information in that
 * tooltip.
 *
 * @see https://react-spectrum.adobe.com/react-spectrum/Tooltip.html#usage-on-disabled-or-non-interactive-elements
 */
export const Tooltip: React.FC<
    React.ComponentProps<'div'> & {
        closeDelay?: number;
        colorScheme?: ColorScheme;
        crossOffset?: number;
        defaultOpen?: boolean;
        delay?: number;
        isTooltipEnabled?: boolean;
        offset?: number;
        position?: TooltipPosition;
        text: React.ReactNode;
        tooltipContainerClassName?: string;
    }
> = ({
    children,
    className,
    closeDelay = 0,
    colorScheme = 'light',
    crossOffset,
    defaultOpen,
    delay = 500,
    isTooltipEnabled = true,
    offset,
    position = 'top',
    text,
    tooltipContainerClassName,
    ...props
}) => {
    const tooltipContent = useMemo(
        () => (
            <ThemeProvider colorScheme={colorScheme}>
                <Box
                    className={cx(
                        styles.tooltip,
                        styles.tooltipControl,
                        styles[position],
                        className,
                    )}
                    data-testid="tooltip-inner"
                >
                    {typeof text === 'string' ? <Text>{text}</Text> : text}
                </Box>
            </ThemeProvider>
        ),
        [className, colorScheme, position, text],
    );

    const {targetRef, triggerProps, tooltip} = useAriaTooltip({
        closeDelay,
        crossOffset,
        defaultOpen,
        delay,
        offset,
        position,
        tooltipContent,
    });

    if (!isTooltipEnabled) {
        return <>{children}</>;
    }

    return (
        <div
            className={cx(styles.tooltipContainer, tooltipContainerClassName)}
            ref={targetRef}
            {...props}
        >
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        ...triggerProps,
                    } as React.Attributes);
                }
                return child;
            })}
            {tooltip}
        </div>
    );
};

export type TooltipProps = React.ComponentProps<typeof Tooltip>;
