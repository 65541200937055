import type {MediaDescription} from 'sdp-transform';

import type {Signal} from '@pexip/signal';

import type {TransceiverMediaType} from './constants';

export interface PexipMediaLine extends MediaDescription {
    type: string;
    port: number;
    protocol: string;
    payloads?: string | undefined;
    content?: string;
}
export type DetachFn = ReturnType<Signal<unknown>['add']>;

export type ReferenceValue = string;
export type References = Record<string, ReferenceValue>;
export type LogFn = <T extends Record<string, unknown>>(
    msg: string,
    context?: T,
) => void;

// Event handlers
export type EventHandler = (event: Event) => void;
export type OnNegotiationNeededHandler = () => void;
export type OnTransceiverChangeHandler = () => void;
export type OnSecureCheckCodeHandler = (secureCheckCode: string) => void;
export type OnTrackEventHandler = (event: RTCTrackEvent) => void;
export type OnRemoteStreamsEventHandler = (config: TransceiverConfig) => void;
export type OnIceCandidateHandler = (event: RTCPeerConnectionIceEvent) => void;
export type OnDataChannelEventHandler = (event: RTCDataChannelEvent) => void;

export interface ExtendedRTCPeerConnection extends RTCPeerConnection {
    /**
     * Typescript DOM type definition has not included this method for
     * `RTCPeerConnection`, thus patch it manually.
     *
     * Allow a web application to easily request that ICE candidate gathering be
     * redone on both ends of the connection. This simplifies the process by
     * allowing the same method to be used by either the caller or the receiver
     * to trigger an ICE restart.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/restartIce
     */
    restartIce(): void;
    peerIdentity: Promise<unknown>;
}

export type MediaDirection = Exclude<RTCRtpTransceiverDirection, 'stopped'>;
export type TransceiverConfigDirectionTuple = [
    TransceiverConfig,
    (MediaDirection | 'send' | 'recv')?,
];

export interface Bandwidth {
    in: number;
    out: number;
}

export interface BasePeerConnection {
    // Native peer
    peer: ExtendedRTCPeerConnection;

    // States
    connectionState: RTCPeerConnection['connectionState'];
    iceGatheringState: RTCPeerConnection['iceGatheringState'];
    iceConnectionState: RTCPeerConnection['iceConnectionState'];
    signalingState: RTCPeerConnection['signalingState'];

    // Properties
    senders: RTCRtpSender[];
    receivers: RTCRtpReceiver[];

    bandwidth: Bandwidth;

    hasICECandidates: boolean;
    /**
     * Reference of any logical associations to the peer connection for logging
     */
    references: References;

    offerOptions: RTCOfferOptions | undefined;
    answerOptions: RTCAnswerOptions | undefined;
    polite: boolean;

    configs: MediaConfig[];

    getTransceiverConfigs: () => TransceiverConfig[];
    getDataChannelConfigs: () => DataChannelConfig[];

    addConfig(
        peer: RTCPeerConnection,
        initOrConfig: DataChannelInit | DataChannelConfig,
    ): DataChannelConfig;
    addConfig(
        peer: RTCPeerConnection,
        initOrConfig: TransceiverInit | TransceiverConfig,
    ): TransceiverConfig;
    addConfig(
        peer: RTCPeerConnection,
        initOrConfig:
            | TransceiverInit
            | TransceiverConfig
            | DataChannelInit
            | DataChannelConfig,
    ): TransceiverConfig | DataChannelConfig;

    // Methods
    setLocalStream(
        stream: MediaStream | undefined,
        target: TransceiverConfigDirectionTuple[],
        shouldSyncMedia?: boolean,
    ): Promise<void>;
    getStats(selector?: MediaStreamTrack | null): Promise<RTCStatsReport>;

    createDataChannel: RTCPeerConnection['createDataChannel'];
    /**
     * Key/Value pair for referencing logical associations for logging
     *
     * @param key - The key for the reference
     * @param value - The value for the reference
     */
    setReference(key: string, value: ReferenceValue): void;
    /**
     * Only recently supported on some browser: https://caniuse.com/?search=setconfiguration
     */
    setConfiguration: RTCPeerConnection['setConfiguration'] | undefined;
    getConfiguration: RTCPeerConnection['getConfiguration'];

    close: RTCPeerConnection['close'];
    restartIce: () => void;
}

export interface PeerConnection extends BasePeerConnection {
    // Properties
    currentLocalDescription: RTCPeerConnection['currentLocalDescription'];
    /**
     * It represents a local description that is in the process of being
     * negotiated plus any local candidates that have been generated by the ICE
     * Agent since the offer or answer was created. If the `RTCPeerConnection`
     * is in the stable state, the value is `null`.
     *
     * https://w3c.github.io/webrtc-pc/#dom-peerconnection-pendinglocaldesc
     */
    pendingLocalDescription?: RTCSessionDescriptionInit | RTCSessionDescription;
    currentRemoteDescription: RTCPeerConnection['currentRemoteDescription'];
    /**
     * It represents a remote description that is in the process of being
     * negotiated, complete with any remote candidates that have been supplied
     * via `addIceCandidate()` since the offer or answer was created. If the
     * `RTCPeerConnection` is in the stable state, the value is `null`.
     *
     * https://w3c.github.io/webrtc-pc/#dom-peerconnection-pendingremotedesc
     */
    pendingRemoteDescription: RTCPeerConnection['pendingRemoteDescription'];

    negotiationNeeded: boolean;

    // Methods
    createOffer(options?: RTCOfferOptions): Promise<RTCSessionDescriptionInit>;
    createAnswer(
        options?: RTCAnswerOptions,
    ): Promise<RTCSessionDescriptionInit>;

    receiveIceCandidate(
        candidate: RTCIceCandidate | RTCIceCandidateInit,
    ): Promise<void>;
    releaseLocalICECandidatesBuffer(ignore: boolean): void;

    receiveAnswer(answer: RTCSessionDescriptionInit): Promise<void>;
    receiveOffer(offer: RTCSessionDescriptionInit): Promise<void>;

    negotiate(): Promise<void>;

    // Event handlers
    onConnectionStateChange?: EventHandler;
    onDataChannel?: RTCPeerConnection['ondatachannel'];
    onIceCandidate?: OnIceCandidateHandler;
    onIceCandidateError?: RTCPeerConnection['onicecandidateerror'];
    onIceConnectionStateChange?: EventHandler;
    onIceGatheringStateChange?: EventHandler;
    onNegotiationNeeded?: OnNegotiationNeededHandler;
    onRemoteStreams?: OnRemoteStreamsEventHandler;
    onSignalingStateChange?: EventHandler;
    onTrack?: OnTrackEventHandler;
    onTransceiverChange?: OnTransceiverChangeHandler;
    onSecureCheckCode?: OnSecureCheckCodeHandler;
}

export type MainPeerConnection = BasePeerConnection;

export type GetSignalTypeFromInterface<T, K extends keyof T> =
    T[K] extends Signal<infer S> ? S : never;

export interface PeerConnectionSignals {
    onConnectionStateChange: Signal<RTCPeerConnectionState>;
    onDataChannel: Signal<RTCDataChannel>;
    onIceCandidate: Signal<RTCIceCandidate | null>;
    // Firefox does not support RTCPeerConnectionIceErrorEvent
    // @see {@link https://bugzil.la/1561441}
    onIceCandidateError: Signal<RTCPeerConnectionIceErrorEvent | Event>;
    onIceConnectionStateChange: Signal<RTCIceConnectionState>;
    onIceGatheringStateChange: Signal<RTCIceGatheringState>;
    onNegotiationNeeded: Signal<undefined>;
    onRemoteStreams: Signal<TransceiverConfig>;
    onSignalingStateChange: Signal<RTCSignalingState>;
    onTrack: Signal<RTCTrackEvent>;
    onTransceiverChange: Signal<undefined>;
    onSecureCheckCode: Signal<string>;
}
interface OfferRequirement {
    stream?: MediaStream;
    target?: TransceiverConfigDirectionTuple[];
    syncMedia?: boolean;
}
export interface PeerConnectionCommandSignals {
    onOfferRequired: Signal<OfferRequirement | undefined>;
    onReceiveAnswer: Signal<RTCSessionDescriptionInit>;
    onReceiveIceCandidate?: Signal<RTCIceCandidate | RTCIceCandidateInit>;
    onReceiveOffer: Signal<RTCSessionDescriptionInit>;
}

export interface CorePeerConnectionSignals {
    onAnswer: Signal<RTCSessionDescriptionInit>;
    onOffer: Signal<RTCSessionDescriptionInit>;
    onOfferIgnored: Signal<undefined>;
    onError: Signal<Error>;
}

export type PCRequiredSignals = CorePeerConnectionSignals &
    Omit<PeerConnectionCommandSignals, 'onReceiveIceCandidate'>;

export type PCOptionalsSignals = Partial<PeerConnectionSignals> &
    Pick<PeerConnectionCommandSignals, 'onReceiveIceCandidate'>;

export type PCSignals = PCRequiredSignals & PCOptionalsSignals;

export interface MediaEncodingParameters extends RTCRtpEncodingParameters {
    maxWidth?: number;
    maxHeight?: number;
}

/**
 * Media Types ("media")
 *
 * They are "audio", "video", "text", "application", and "message"
 * @see {@link https://www.rfc-editor.org/rfc/rfc4566#section-8.2.1}
 */
export type MediaType =
    | 'application'
    | 'text'
    | 'message'
    | TransceiverMediaType;

export interface TransceiverInit {
    /**
     * Content Attributes
     * @see {@link https://www.rfc-editor.org/rfc/rfc4796#section-5}
     * @defaultValue `'main'`
     */
    content?: string;
    /**
     * Either `audio` or `video`
     */
    kindOrTrack: TransceiverMediaType | MediaStreamTrack;
    /**
     * Can be any of these `sendrecv`, `sendonly`, `recvonly` and `inactive`
     * @defaultValue `'sendrecv'`
     */
    direction?: MediaDirection;
    /**
     * A list of `MediaStream` objects to add to the transceiver's
     * `RTCRtpReceiver`
     */
    streams?: MediaStream[];
    /**
     * A list of `RTCRtpEncodingParameters` objects, each specifying the
     * parameters for a single codec that could be used to encode the track's
     * media
     */
    sendEncodings?: MediaEncodingParameters[];
    transceiver?: RTCRtpTransceiver;
    allowAutoChangeOfDirection?: boolean;
    relativeDirection?: boolean;
}

interface SyncTransceiverOption {
    direction?: MediaDirection;
    track?: MediaStreamTrack | null;
    streams?: MediaStream[];
    sendEncodings?: RTCRtpEncodingParameters[];
}

interface EventListener<T, K extends string, E> {
    event: K;
    listener: (this: T, evt: E) => void;
    options?: boolean | AddEventListenerOptions;
}

// Copied from https://github.com/microsoft/TypeScript/blob/e374eba37c002aa8238f00f77a34673a7536f5b1/src/lib/dom.generated.d.ts#L17817C1-L17825C1
interface RTCDataChannelEventMap {
    bufferedamountlow: Event;
    close: Event;
    closing: Event;
    error: Event;
    message: MessageEvent;
    open: Event;
}

// Copied from https://github.com/microsoft/TypeScript/blob/8a85b2aafb349c8f952b46513dfbb47d62fc84cb/src/lib/dom.generated.d.ts
export interface RTCPeerConnectionEventMap {
    connectionstatechange: Event;
    datachannel: RTCDataChannelEvent;
    icecandidate: RTCPeerConnectionIceEvent;
    icecandidateerror: Event;
    iceconnectionstatechange: Event;
    icegatheringstatechange: Event;
    negotiationneeded: Event;
    signalingstatechange: Event;
    track: RTCTrackEvent;
}

type EventListeners<T, M extends object, K extends keyof M> = Array<
    K extends string ? EventListener<T, K, M[K]> : never
>;

export interface DataChannelInit extends RTCDataChannelInit {
    label: string;
    dataChannel?: RTCDataChannel;
    eventListeners?: EventListeners<
        RTCDataChannel,
        RTCDataChannelEventMap,
        keyof RTCDataChannelEventMap
    >;
}

export type RTCPeerConnectionEventListeners = EventListeners<
    RTCPeerConnection,
    RTCPeerConnectionEventMap,
    keyof RTCPeerConnectionEventMap
>;

interface MediaBaseConfig {
    kind: string;
    dirty: boolean;
    toString(): string;
}

/**
 * Data Channel Config for managed media
 */
export interface DataChannelConfig extends MediaBaseConfig {
    options: DataChannelInit;
    kind: 'application';
    dataChannel: RTCDataChannel | undefined;
    syncDataChannel(peer: RTCPeerConnection): void;
}

/**
 * Transceiver Config for managed media
 */
export interface TransceiverConfig extends MediaBaseConfig {
    /**
     * Content Attributes
     * @see {@link https://www.rfc-editor.org/rfc/rfc4796#section-5}
     * @defaultValue `'main'`
     */
    content: string;
    /**
     * Either `audio` or `video`
     */
    kind: TransceiverMediaType;
    /**
     * Can be any of these `sendrecv`, `sendonly`, `recvonly` and `inactive`
     */
    direction: RTCRtpTransceiverDirection;
    transceiver: RTCRtpTransceiver | undefined;
    /**
     * A `MediaStreamTrack` to associate with the transceiver
     */
    track: MediaStreamTrack | undefined | null;
    /**
     * A list of local `MediaStream` objects to add to the transceiver's
     * `RTCRtpSender`
     */
    streams: MediaStream[];
    remoteStreams?: readonly MediaStream[];
    /**
     * A list of `RTCRtpEncodingParameters` objects, each specifying the
     * parameters for a single codec that could be used to encode the track's
     * media
     */
    sendEncodings?: RTCRtpEncodingParameters[];

    mediaDescription?: PexipMediaLine;

    allowAutoChangeOfDirection: boolean;
    relativeDirection: boolean;

    syncDirection(): void;
    syncSenderTrack(): Promise<void>;
    syncSenderParameters(): Promise<void>;
    syncStreams(): void;
    syncTransceiver(
        peer: RTCPeerConnection,
        config?: SyncTransceiverOption,
    ): Promise<void>;
}

export type MediaInit = TransceiverInit | DataChannelInit;
export type MediaConfig = TransceiverConfig | DataChannelConfig;

export interface PeerConnectionOptions {
    allow1080p?: boolean;
    allow4kPreso?: boolean;
    allowCodecSdpMunging?: boolean;
    allowVP9?: boolean;
    answerOptions?: RTCAnswerOptions;
    bandwidth?: Bandwidth;
    offerOptions?: RTCOfferOptions;
    rtcConfig?: RTCConfiguration;
    mediaInits?: MediaInit[];
    polite?: boolean;
}

export type MainPeerConnectionOptions = PeerConnectionOptions;

export enum RecoveryTimeout {
    ConnectionState = 5000,
    IceConnectionState = 2000,
    Negotiation = 200,
}
