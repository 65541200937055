import {useEffect} from 'react';

import {createSignal} from '@pexip/signal';

export const keydownSignal = createSignal<KeyboardEvent>({
    allowEmittingWithoutObserver: true,
    name: 'useHotKey:keydownEvent',
});

export const excludedElements = ['INPUT', 'TEXTAREA'];

const emitKeydownSignal = (event: KeyboardEvent) => keydownSignal.emit(event);

export interface DisallowedKeys {
    metaKey?: boolean;
    ctrlKey?: boolean;
}

const defaultDisallowedKeys: DisallowedKeys = {metaKey: true, ctrlKey: true};

export const useHotKey = ({
    key,
    handler,
    disallowedKeys = defaultDisallowedKeys,
    isDisabled = false,
}: {
    key: string;
    handler: () => void;
    isDisabled?: boolean;
    disallowedKeys?: DisallowedKeys;
}) => {
    if (key.length !== 1) {
        throw new Error('Hotkey length is limited to 1 currently');
    }

    useEffect(() => {
        !isDisabled && document.addEventListener('keydown', emitKeydownSignal);

        return () => document.removeEventListener('keydown', emitKeydownSignal);
    }, [isDisabled]);

    useEffect(
        () =>
            keydownSignal.add(event => {
                if (event.repeat || isDisabled) {
                    return;
                }

                if (
                    excludedElements.includes(
                        document.activeElement?.tagName || '',
                    )
                ) {
                    return;
                }

                let k: keyof DisallowedKeys;
                for (k in disallowedKeys) {
                    if (disallowedKeys[k] && event[k]) {
                        return;
                    }
                }

                const capsLockIsOn = event.getModifierState
                    ? event.getModifierState('CapsLock')
                    : false;

                if (capsLockIsOn) {
                    const pressedKey = event.shiftKey
                        ? event.key.toUpperCase()
                        : event.key.toLowerCase();
                    if (key === pressedKey) {
                        return handler();
                    }
                } else if (key === event.key) {
                    return handler();
                }
            }),
        [key, handler, disallowedKeys, isDisabled],
    );
};
