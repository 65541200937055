import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    ModalCloseButton as CloseButton,
    FontVariant,
    List,
    Row,
    Text,
    TextLink,
} from '@pexip/components';
import {DeniedDevices} from '@pexip/media';

import {TestId} from '../../../test/testIds';
import {BlockedBrowserPermissionsInfoType} from '../../types';
import {getUserAgentDetails} from '../../utils';
import {InputControlTooltip} from '../InputControlTooltip/InputControlTooltip.view';

import styles from './DeviceDeniedTooltip.module.scss';

/*
t('media.permission-info.[microphone].title', 'microphone')
t('media.permission-info.[camera].title', 'camera')
t('media.permission-info.[microphone-and-camera].title', 'camera and microphone')
*/

export const DeviceDeniedTooltip: React.FC<{
    children: React.ReactNode;
    contentWrapperClassName?: string;
    deniedDevice: DeniedDevices;
    setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    learnHowToGrantAccessURL?: string;
    permissionInfoType?: BlockedBrowserPermissionsInfoType;
    mainWrapperClassName?: string;
    onClose?: () => void;
    visible: boolean;
    isTouch?: boolean;
}> = ({
    children,
    contentWrapperClassName,
    deniedDevice,
    setShowHelpVideo,
    learnHowToGrantAccessURL,
    permissionInfoType,
    mainWrapperClassName,
    onClose,
    visible,
    isTouch = getUserAgentDetails(navigator.userAgent).isTouch,
    ...props
}) => {
    const {t} = useTranslation();

    const content = (
        <List className={cx([isTouch ? 'pl-0 pr-0' : ''])}>
            <Row className="pt-3 pb-1">
                {deniedDevice === DeniedDevices.Camera && (
                    <Text fontVariant={FontVariant.BodyBold}>
                        {t(
                            'media.you-blocked-access-tooltip.camera',
                            'You blocked access to your camera',
                        )}
                    </Text>
                )}

                {deniedDevice === DeniedDevices.Microphone && (
                    <Text fontVariant={FontVariant.BodyBold}>
                        {t(
                            'media.you-blocked-access-tooltip.mic',
                            'You blocked access to your microphone',
                        )}
                    </Text>
                )}

                {deniedDevice === DeniedDevices.Both && (
                    <Text fontVariant={FontVariant.BodyBold}>
                        {t(
                            'media.you-blocked-access-tooltip.camera-and-mic',
                            'You blocked access to your camera & microphone',
                        )}
                    </Text>
                )}

                <CloseButton
                    className={styles.close}
                    onClose={onClose}
                    aria-label={t('common.close-tooltip', 'Close tooltip')}
                />
            </Row>
            <Row className="pt-0">
                {(permissionInfoType ===
                    BlockedBrowserPermissionsInfoType.Complete ||
                    permissionInfoType ===
                        BlockedBrowserPermissionsInfoType.CompleteReload) && (
                    <Text>
                        <Trans
                            t={t}
                            i18nKey="media.permissions-help-video-link"
                        >
                            To grant access please follow the steps in{' '}
                            <TextLink
                                onClick={() => setShowHelpVideo(true)}
                                target="_blank"
                                data-testid={
                                    TestId.TextLinkUnblockPermissionsVideo
                                }
                            >
                                this short video
                            </TextLink>
                        </Trans>{' '}
                        <Trans t={t} i18nKey="media.learn-how-to-grant-access">
                            {' '}
                            or go to the{' '}
                            <TextLink
                                href={learnHowToGrantAccessURL}
                                target="_blank"
                                data-testid={
                                    TestId.TextLinkLearnRequestPermissions
                                }
                            >
                                privacy settings
                            </TextLink>{' '}
                            of your browser
                        </Trans>
                    </Text>
                )}
                {permissionInfoType ===
                    BlockedBrowserPermissionsInfoType.Void && (
                    <Text>
                        {t(
                            'media.other-browsers-privacy-settings',
                            'To grant access please go to the privacy settings of your browser or try to refresh the page.',
                        )}
                    </Text>
                )}
            </Row>
        </List>
    );

    return (
        <InputControlTooltip
            isTouch={isTouch}
            mainWrapperClassName={mainWrapperClassName}
            onClose={onClose}
            contentWrapperClassName={contentWrapperClassName}
            visible={visible}
            {...props}
            content={content}
        >
            {children}
        </InputControlTooltip>
    );
};

export type DeviceDeniedToolTipProps = React.ComponentProps<
    typeof DeviceDeniedTooltip
>;
