import React, {useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import type {PreviewStreamController} from '@pexip/media';
import {
    usePreviewControllerHandler,
    usePreviewErrorHandling,
    useAnalyzer,
    DevicesList,
    AudioMeter,
    SettingsPanel,
    PanelHeader,
    SelfViewSettings,
} from '@pexip/media-components';
import {
    Bar,
    Button,
    List,
    ThemeProvider,
    ToggleSwitch,
    notificationToastSignal,
    withColorScheme,
} from '@pexip/components';
import {noop} from '@pexip/utils';

import {useConfig} from '../config';
import {
    browserSupportsPtzConstraints,
    useDevices,
    usePreviewAudioInput,
    usePreviewAudioOutput,
    usePreviewDenoise,
    usePreviewFecc,
    usePreviewStreamQuality,
    usePreviewPresoContentHint,
    usePreviewVideoInput,
    useStreamStatus,
    usePreviewPreferPrexInMix,
} from '../services/Media.service';
import {TestId} from '../../test/testIds';
import {applicationConfig} from '../applicationConfig';
import {useSelfviewMirrorMode} from '../hooks/useSelfviewMirrorMode';

import {OutputAudioTester} from './OutputAudioTester.viewModel';

const SecondaryAudioMeter = withColorScheme(AudioMeter, 'light');

export const MediaControl: React.FC<{
    onBackClick: () => void;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
    controller: PreviewStreamController;
}> = ({close, onBackClick, controller}) => {
    const {t} = useTranslation();
    const devices = useDevices();
    const streamStatus = useStreamStatus();
    const [settingsChanged, setSettingsChanged] = useState(false);
    const [isMirrored] = useSelfviewMirrorMode();

    const {
        videoInputError,
        setVideoInputError,
        audioInputError,
        setAudioInputError,
    } = usePreviewErrorHandling(controller, streamStatus, {
        audio: Boolean(controller.media.constraints?.audio),
        video: Boolean(controller.media.constraints?.video),
    });

    const audioInput = usePreviewAudioInput({
        error: audioInputError,
        controller,
        setError: setAudioInputError,
    });

    const videoInput = usePreviewVideoInput({
        error: videoInputError,
        controller,
        setError: setVideoInputError,
    });

    const audioOutput = usePreviewAudioOutput(
        devices,
        useConfig('audioOutput')[0],
    );

    const streamQuality = usePreviewStreamQuality();
    const denoise = usePreviewDenoise();
    const fecc = usePreviewFecc();
    const presoContentHint = usePreviewPresoContentHint();
    const preferPresInMix = usePreviewPreferPrexInMix();

    useEffect(
        () =>
            controller.onApplyChangesError(() =>
                notificationToastSignal.emit([
                    {
                        message: t(
                            'settings.video-and-sound-section.apply-changes-error',
                            'There was a problem applying the Video and Sound changes',
                        ),
                        timeout: 0,
                        isClickable: true,
                        isDanger: true,
                        isInterrupt: true,
                        onDismiss: noop,
                    },
                ]),
            ),
        [controller, t],
    );

    useEffect(() => {
        setSettingsChanged(
            controller.audioInputChanged ||
                controller.videoInputChanged ||
                audioOutput.hasChanges() ||
                streamQuality.hasChanges() ||
                denoise.hasChanges() ||
                fecc.hasChanges() ||
                presoContentHint.hasChanges() ||
                preferPresInMix.hasChanges(),
        );
    }, [
        audioOutput,
        controller.audioInputChanged,
        controller.videoInputChanged,
        denoise,
        fecc,
        preferPresInMix,
        presoContentHint,
        streamQuality,
    ]);

    const previewController = usePreviewControllerHandler({
        controller,
        applyChanges: [
            streamQuality.applyChanges,
            denoise.applyChanges,
            videoInput.applyChanges,
            audioInput.applyChanges,
            audioOutput.applyChanges,
            fecc.applyChanges,
            presoContentHint.applyChanges,
            preferPresInMix.applyChanges,
        ],
    });

    const analyzer = useAnalyzer(controller.media);

    const handleDenoise = useCallback(() => {
        denoise.setPreview(previewDenoise => !previewDenoise);
    }, [denoise]);

    const handleChangeOfSharingPriority = useCallback(() => {
        presoContentHint.setPreview(previewDenoise => !previewDenoise);
    }, [presoContentHint]);

    const handleFecc = useCallback(() => {
        fecc.setPreview(prevFecc => !prevFecc);
    }, [fecc]);

    const handlePreferPresInMix = useCallback(() => {
        preferPresInMix.setPreview(prevPreferPresInMix => !prevPreferPresInMix);
    }, [preferPresInMix]);

    const requestedAudio = Boolean(
        previewController.previewMedia.constraints?.audio,
    );

    const requestedVideo = Boolean(
        previewController.previewMedia.constraints?.video,
    );

    const deviceList = (
        <DevicesList
            devices={devices}
            requestedAudio={requestedAudio}
            requestedVideo={requestedVideo}
            videoInputError={videoInput.error}
            audioInputError={audioInput.error}
            videoInput={videoInput.preview}
            audioInput={audioInput.preview}
            audioOutput={audioOutput.preview}
            onAudioInputChange={audioInput.setPreview}
            onAudioOutputChange={audioOutput.setPreview}
            onVideoInputChange={videoInput.setPreview}
            isLoading={previewController.isSaving}
        />
    );

    const audioMeter = !!previewController.previewMedia?.audioInput &&
        !!analyzer && (
            <SecondaryAudioMeter
                analyzer={analyzer}
                data-testid={TestId.AudioMeterSettings}
            />
        );

    const isSaving =
        previewController.isSaving || previewController.updatingPreview;

    const allowToSave =
        settingsChanged &&
        !isSaving &&
        /*
         * Respective device selection is disabled when requestedVideo or requestedAudio
         * is false, this should not hinder the user from saving other changes.
         */
        (!videoInput.error.title || !requestedVideo) &&
        (!audioInput.error.title || !requestedAudio);

    const onSave = useCallback(
        (e: React.SyntheticEvent<HTMLElement, Event>) => {
            void previewController.handleSave(e);
            setSettingsChanged(false);
        },
        [previewController],
    );

    const previewStream = previewController.previewMedia?.stream;

    return (
        <SettingsPanel
            scrollable
            data-testid={TestId.VideoAndSoundPanel}
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t(
                            'settings.video-and-sound',
                            'Video and Sound',
                        )}`}
                        onBackClick={onBackClick}
                    />
                </ThemeProvider>
            }
            footerContent={
                <ThemeProvider colorScheme="light">
                    <Bar>
                        <Button
                            onClick={close}
                            variant="secondary"
                            size="medium"
                            modifier="fullWidth"
                            isDisabled={isSaving}
                            data-testid={TestId.ButtonSettingsCancel}
                        >
                            <Trans t={t} i18nKey="settings.cancel">
                                Cancel
                            </Trans>
                        </Button>
                        <Button
                            onClick={onSave}
                            type="submit"
                            modifier="fullWidth"
                            className="ml-2"
                            isLoading={isSaving}
                            isDisabled={!allowToSave}
                            data-testid={TestId.ButtonSettingsSave}
                        >
                            <Trans t={t} i18nKey="settings.save">
                                Save
                            </Trans>
                        </Button>
                    </Bar>
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <SelfViewSettings
                    srcObject={previewStream}
                    isMirrored={isMirrored}
                >
                    {audioMeter}
                </SelfViewSettings>
                {deviceList}
                {applicationConfig.audioProcessing && (
                    <ToggleSwitch
                        checked={denoise.preview}
                        className="mt-4"
                        data-testid={TestId.ToggleNoiseSuppression}
                        isSmall
                        label={t(
                            'settings.noise-suppression',
                            'Noise suppression',
                        )}
                        labelModifier="spaced"
                        labelPosition="start"
                        name="noise-supression"
                        onChange={handleDenoise}
                    />
                )}
                <ToggleSwitch
                    checked={presoContentHint.preview}
                    className="mt-4"
                    data-testid={TestId.TogglePrioritizeSharingMotion}
                    isSmall
                    label={t(
                        'settings.prioritize-sharing-motion',
                        'Prioritize sharing motion',
                    )}
                    labelModifier="spaced"
                    labelPosition="start"
                    name="prioritize-sharing-motion"
                    onChange={handleChangeOfSharingPriority}
                />
                {browserSupportsPtzConstraints() && (
                    <ToggleSwitch
                        checked={fecc.preview}
                        className="mt-4"
                        data-testid={TestId.ToggleFecc}
                        isSmall
                        label={t('settings.fecc', 'Far-end camera control')}
                        labelModifier="spaced"
                        labelPosition="start"
                        name="fecc"
                        onChange={handleFecc}
                    />
                )}
                <ToggleSwitch
                    checked={preferPresInMix.preview}
                    className="mt-4"
                    data-testid={TestId.TogglePreferPresInMix}
                    isSmall
                    label={t(
                        'settings.prefer-pres-in-mix',
                        'Prefer presentation in mix',
                    )}
                    labelModifier="spaced"
                    labelPosition="start"
                    name="prefer-pres-in-mix"
                    onChange={handlePreferPresInMix}
                />
                <List spacing="none" className="mt-1">
                    <OutputAudioTester
                        sinkId={audioOutput.preview?.deviceId ?? ''}
                    />
                </List>
            </ThemeProvider>
        </SettingsPanel>
    );
};
