import React, {useState} from 'react';
import cx from 'classnames';

import {ModalCloseButton} from '../CloseButton/ModalCloseButton.view';
import type {ClickEventHandler} from '../../../types/events';
import {useFocusFromOpened} from '../../../hooks/useFocusFromOpened';
import {useModal} from '../../..';

import styles from './Sheet.module.scss';

const handleClick: ClickEventHandler = e => e.stopPropagation();

export const Sheet: React.FC<
    React.ComponentProps<'div'> & {
        closeButtonAriaLabel?: string;
        isOpen: boolean;
        onClose: () => void;
        withCloseButton?: boolean;
    }
> = ({
    isOpen = false,
    closeButtonAriaLabel = 'Close sheet',
    onClose,
    children,
    withCloseButton = false,
    className,
}) => {
    const {ref} = useModal(isOpen);

    const [containerElement, setContainerElement] =
        useState<HTMLDivElement | null>(null);
    useFocusFromOpened(containerElement, isOpen);

    return (
        <div
            ref={ref}
            className={cx(styles.wrapper, {[styles.open]: isOpen})}
            role="dialog"
        >
            <div
                className={cx(styles.sheet, className)}
                onClick={handleClick}
                onKeyDown={handleClick}
                role="none"
            >
                <div
                    className={styles.sheetInner}
                    ref={setContainerElement}
                    tabIndex={-1}
                >
                    {children}
                    {withCloseButton && (
                        <ModalCloseButton
                            aria-label={closeButtonAriaLabel}
                            onClose={onClose}
                            className={styles.closeButton}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
