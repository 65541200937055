import React from 'react';
import cx from 'classnames';

import {Text} from '../Text/Text';
import {FontVariant} from '../../../../design-tokens/generated/constants';
import {TestId} from '../../../utils/testIds';
import type {BadgeCounterVariant} from '../../../types/variants';

import {useBadgeCounterAnimation} from './useBadgeCounterAnimation';
import {useDisplayedNumber} from './useDisplayedNumber';

import styles from './BadgeCounter.module.scss';

export const BadgeCounter: React.FC<
    React.ComponentProps<'div'> & {
        number?: number;
        isVisible: boolean;
        variant?: BadgeCounterVariant;
        shouldAnimate?: boolean;
        isTruncated?: boolean;
        badgeClassName?: string;
    }
> = ({
    number = 0,
    isVisible,
    variant = 'notification',
    shouldAnimate = true,
    isTruncated = false,
    badgeClassName,
    children,
    ...props
}) => {
    const isAnimating = useBadgeCounterAnimation(number, shouldAnimate);
    const displayedNumber = useDisplayedNumber(number);

    return (
        <div
            className={cx(styles.wrapper, {
                [styles.truncated]: isTruncated,
            })}
            {...props}
        >
            {children && <div>{children}</div>}
            <div
                data-testid={TestId.BadgeCounterNumber}
                className={cx(
                    styles.counter,
                    styles[variant],
                    {
                        [styles.animated]: isAnimating,
                        [styles.visible]: isVisible,
                        [styles.truncated]: isTruncated,
                    },
                    badgeClassName,
                )}
            >
                <Text
                    colorScheme={variant === 'info' ? 'light' : 'dark'}
                    fontVariant={FontVariant.SmallBold}
                    className={styles.number}
                >
                    {displayedNumber}
                </Text>
            </div>
        </div>
    );
};

export type BadgeCounterProps = React.ComponentProps<typeof BadgeCounter>;
