import {useCallback} from 'react';

/**
 * This hook gives the ability to set the focus on an element
 * which is then blurred right away, thereby effectively resetting
 * the focus order. The next focusable element that comes after this element
 * will be focused on the next tab.
 * @param element - element which focus point should start from
 * @returns focusFrom function, when called sets the focus point to the given element
 */
export const useFocusFrom = (element: HTMLElement | null) => {
    const focusFrom = useCallback(() => {
        if (element) {
            const prevTabIndex = element.tabIndex;
            element.tabIndex = -1;
            element.focus();
            element.tabIndex = prevTabIndex;
        }
    }, [element]);

    return {focusFrom};
};
