import {ValidationError, NonJSONResponseError} from '../errors';

import type {Endpoints} from './types';
import {
    validateDisconnectParticipant200,
    validateDisconnectParticipant403,
    validateMuteParticipant200,
    validateMuteParticipant403,
    validateUnmuteParticipant200,
    validateUnmuteParticipant403,
    validateClientMuteParticipant200,
    validateClientMuteParticipant403,
    validateClientUnmuteParticipant200,
    validateClientUnmuteParticipant403,
    validateVideoMuteParticipant200,
    validateVideoMuteParticipant403,
    validateVideoUnmuteParticipant200,
    validateVideoUnmuteParticipant403,
    validateAllowrxpresentationParticipant200,
    validateAllowrxpresentationParticipant403,
    validateDenyrxpresentationParticipant200,
    validateDenyrxpresentationParticipant403,
    validateSpotlightonParticipant200,
    validateSpotlightonParticipant403,
    validateSpotlightoffParticipant200,
    validateSpotlightoffParticipant403,
    validateUnlockParticipant200,
    validateUnlockParticipant403,
    validateDtmfParticipantBody,
    validateDtmfParticipant200,
    validateDtmfParticipant403,
    validateCallsWebrtcParticipantBody,
    validateCallsWebrtcParticipant200,
    validateCallsWebrtcParticipant400,
    validateCallsWebrtcParticipant403,
    validateCallsWebrtcParticipant500,
    validateCallsWebrtcParticipant504,
    validateCallsRtmpParticipantBody,
    validateCallsRtmpParticipant200,
    validateCallsRtmpParticipant400,
    validateCallsRtmpParticipant403,
    validateCallsRtmpParticipant500,
    validateCallsRtmpParticipant504,
    validateOverlaytextParticipantBody,
    validateOverlaytextParticipant200,
    validateOverlaytextParticipant403,
    validatePresInMixParticipantBody,
    validatePresInMixParticipant200,
    validatePresInMixParticipant403,
    validateRoleParticipantBody,
    validateRoleParticipant200,
    validateRoleParticipant403,
    validateFeccParticipantBody,
    validateFeccParticipant200,
    validateFeccParticipant403,
    validateBuzzParticipant200,
    validateBuzzParticipant403,
    validateClearbuzzParticipant200,
    validateClearbuzzParticipant403,
    validateTransferParticipantBody,
    validateTransferParticipant200,
    validateTransferParticipant403,
    validateMessageParticipantBody,
    validateMessageParticipant200,
    validateMessageParticipant403,
    validateAvatarJpgParticipant403,
    validateAvatarJpgParticipant404,
    validateTakeFloor200,
    validateTakeFloor403,
    validateReleaseFloor200,
    validateReleaseFloor403,
    validateShowLiveCaptions200,
    validateShowLiveCaptions403,
    validateHideLiveCaptions200,
    validateHideLiveCaptions403,
    validatePreferredAspectRatioBody,
    validatePreferredAspectRatio200,
    validatePreferredAspectRatio403,
    validateNotifyNotAFK200,
    validateNotifyNotAFK403,
} from './validation';

type DisconnectParticipantResponse =
    | {status: 200; data: Endpoints['disconnect_participant']['200']}
    | {status: 403; data: Endpoints['disconnect_participant']['403']};
export async function disconnectParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['disconnect_participant']['Params'];
    host: string;
}): Promise<DisconnectParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/disconnect`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDisconnectParticipant200(data)) {
                throw new ValidationError(
                    validateDisconnectParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['disconnect_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDisconnectParticipant403(data)) {
                throw new ValidationError(
                    validateDisconnectParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['disconnect_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type DisconnectParticipantMap = Endpoints['disconnect_participant'];

type MuteParticipantResponse =
    | {status: 200; data: Endpoints['mute_participant']['200']}
    | {status: 403; data: Endpoints['mute_participant']['403']};
export async function muteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['mute_participant']['Params'];
    host: string;
}): Promise<MuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/mute`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateMuteParticipant200(data)) {
                throw new ValidationError(validateMuteParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['mute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateMuteParticipant403(data)) {
                throw new ValidationError(validateMuteParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['mute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type MuteParticipantMap = Endpoints['mute_participant'];

type UnmuteParticipantResponse =
    | {status: 200; data: Endpoints['unmute_participant']['200']}
    | {status: 403; data: Endpoints['unmute_participant']['403']};
export async function unmuteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['unmute_participant']['Params'];
    host: string;
}): Promise<UnmuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/unmute`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUnmuteParticipant200(data)) {
                throw new ValidationError(validateUnmuteParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['unmute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUnmuteParticipant403(data)) {
                throw new ValidationError(validateUnmuteParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['unmute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type UnmuteParticipantMap = Endpoints['unmute_participant'];

type ClientMuteParticipantResponse =
    | {status: 200; data: Endpoints['client_mute_participant']['200']}
    | {status: 403; data: Endpoints['client_mute_participant']['403']};
export async function clientMuteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['client_mute_participant']['Params'];
    host: string;
}): Promise<ClientMuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/client_mute`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateClientMuteParticipant200(data)) {
                throw new ValidationError(
                    validateClientMuteParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['client_mute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateClientMuteParticipant403(data)) {
                throw new ValidationError(
                    validateClientMuteParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['client_mute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ClientMuteParticipantMap = Endpoints['client_mute_participant'];

type ClientUnmuteParticipantResponse =
    | {status: 200; data: Endpoints['client_unmute_participant']['200']}
    | {status: 403; data: Endpoints['client_unmute_participant']['403']};
export async function clientUnmuteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['client_unmute_participant']['Params'];
    host: string;
}): Promise<ClientUnmuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/client_unmute`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateClientUnmuteParticipant200(data)) {
                throw new ValidationError(
                    validateClientUnmuteParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['client_unmute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateClientUnmuteParticipant403(data)) {
                throw new ValidationError(
                    validateClientUnmuteParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['client_unmute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ClientUnmuteParticipantMap = Endpoints['client_unmute_participant'];

type VideoMuteParticipantResponse =
    | {status: 200; data: Endpoints['video_mute_participant']['200']}
    | {status: 403; data: Endpoints['video_mute_participant']['403']};
export async function videoMuteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['video_mute_participant']['Params'];
    host: string;
}): Promise<VideoMuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/video_muted`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateVideoMuteParticipant200(data)) {
                throw new ValidationError(
                    validateVideoMuteParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['video_mute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateVideoMuteParticipant403(data)) {
                throw new ValidationError(
                    validateVideoMuteParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['video_mute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type VideoMuteParticipantMap = Endpoints['video_mute_participant'];

type VideoUnmuteParticipantResponse =
    | {status: 200; data: Endpoints['video_unmute_participant']['200']}
    | {status: 403; data: Endpoints['video_unmute_participant']['403']};
export async function videoUnmuteParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['video_unmute_participant']['Params'];
    host: string;
}): Promise<VideoUnmuteParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/video_unmuted`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateVideoUnmuteParticipant200(data)) {
                throw new ValidationError(
                    validateVideoUnmuteParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['video_unmute_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateVideoUnmuteParticipant403(data)) {
                throw new ValidationError(
                    validateVideoUnmuteParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['video_unmute_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type VideoUnmuteParticipantMap = Endpoints['video_unmute_participant'];

type AllowrxpresentationParticipantResponse =
    | {status: 200; data: Endpoints['allowrxpresentation_participant']['200']}
    | {status: 403; data: Endpoints['allowrxpresentation_participant']['403']};
export async function allowrxpresentationParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['allowrxpresentation_participant']['Params'];
    host: string;
}): Promise<AllowrxpresentationParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/allowrxpresentation`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateAllowrxpresentationParticipant200(data)) {
                throw new ValidationError(
                    validateAllowrxpresentationParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['allowrxpresentation_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateAllowrxpresentationParticipant403(data)) {
                throw new ValidationError(
                    validateAllowrxpresentationParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['allowrxpresentation_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type AllowrxpresentationParticipantMap =
    Endpoints['allowrxpresentation_participant'];

type DenyrxpresentationParticipantResponse =
    | {status: 200; data: Endpoints['denyrxpresentation_participant']['200']}
    | {status: 403; data: Endpoints['denyrxpresentation_participant']['403']};
export async function denyrxpresentationParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['denyrxpresentation_participant']['Params'];
    host: string;
}): Promise<DenyrxpresentationParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/denyrxpresentation`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDenyrxpresentationParticipant200(data)) {
                throw new ValidationError(
                    validateDenyrxpresentationParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['denyrxpresentation_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDenyrxpresentationParticipant403(data)) {
                throw new ValidationError(
                    validateDenyrxpresentationParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['denyrxpresentation_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type DenyrxpresentationParticipantMap =
    Endpoints['denyrxpresentation_participant'];

type SpotlightonParticipantResponse =
    | {status: 200; data: Endpoints['spotlighton_participant']['200']}
    | {status: 403; data: Endpoints['spotlighton_participant']['403']};
export async function spotlightonParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['spotlighton_participant']['Params'];
    host: string;
}): Promise<SpotlightonParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/spotlighton`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateSpotlightonParticipant200(data)) {
                throw new ValidationError(
                    validateSpotlightonParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['spotlighton_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateSpotlightonParticipant403(data)) {
                throw new ValidationError(
                    validateSpotlightonParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['spotlighton_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type SpotlightonParticipantMap = Endpoints['spotlighton_participant'];

type SpotlightoffParticipantResponse =
    | {status: 200; data: Endpoints['spotlightoff_participant']['200']}
    | {status: 403; data: Endpoints['spotlightoff_participant']['403']};
export async function spotlightoffParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['spotlightoff_participant']['Params'];
    host: string;
}): Promise<SpotlightoffParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/spotlightoff`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateSpotlightoffParticipant200(data)) {
                throw new ValidationError(
                    validateSpotlightoffParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['spotlightoff_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateSpotlightoffParticipant403(data)) {
                throw new ValidationError(
                    validateSpotlightoffParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['spotlightoff_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type SpotlightoffParticipantMap = Endpoints['spotlightoff_participant'];

type UnlockParticipantResponse =
    | {status: 200; data: Endpoints['unlock_participant']['200']}
    | {status: 403; data: Endpoints['unlock_participant']['403']};
export async function unlockParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['unlock_participant']['Params'];
    host: string;
}): Promise<UnlockParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/unlock`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUnlockParticipant200(data)) {
                throw new ValidationError(validateUnlockParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['unlock_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUnlockParticipant403(data)) {
                throw new ValidationError(validateUnlockParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['unlock_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type UnlockParticipantMap = Endpoints['unlock_participant'];

type DtmfParticipantResponse =
    | {status: 200; data: Endpoints['dtmf_participant']['200']}
    | {status: 403; data: Endpoints['dtmf_participant']['403']};
export async function dtmfParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['dtmf_participant']['Params'];
    body: Endpoints['dtmf_participant']['Body'];
    host: string;
}): Promise<DtmfParticipantResponse> {
    if (!validateDtmfParticipantBody(body)) {
        throw new ValidationError(validateDtmfParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/dtmf`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDtmfParticipant200(data)) {
                throw new ValidationError(validateDtmfParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['dtmf_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDtmfParticipant403(data)) {
                throw new ValidationError(validateDtmfParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['dtmf_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type DtmfParticipantMap = Endpoints['dtmf_participant'];

type CallsWebrtcParticipantResponse =
    | {status: 200; data: Endpoints['calls_webrtc_participant']['200']}
    | {status: 400; data: Endpoints['calls_webrtc_participant']['400']}
    | {status: 403; data: Endpoints['calls_webrtc_participant']['403']}
    | {status: 500; data: Endpoints['calls_webrtc_participant']['500']}
    | {status: 504; data: Endpoints['calls_webrtc_participant']['504']};
export async function callsWebrtcParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['calls_webrtc_participant']['Params'];
    body: Endpoints['calls_webrtc_participant']['Body'];
    host: string;
}): Promise<CallsWebrtcParticipantResponse> {
    if (!validateCallsWebrtcParticipantBody(body)) {
        throw new ValidationError(validateCallsWebrtcParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/calls`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateCallsWebrtcParticipant200(data)) {
                throw new ValidationError(
                    validateCallsWebrtcParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['calls_webrtc_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 400) {
        const data = await res.text();
        if (!validateCallsWebrtcParticipant400(data)) {
            throw new ValidationError(validateCallsWebrtcParticipant400.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateCallsWebrtcParticipant403(data)) {
                throw new ValidationError(
                    validateCallsWebrtcParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['calls_webrtc_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 500) {
        const data = await res.text();
        if (!validateCallsWebrtcParticipant500(data)) {
            throw new ValidationError(validateCallsWebrtcParticipant500.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 504) {
        const data = await res.text();
        if (!validateCallsWebrtcParticipant504(data)) {
            throw new ValidationError(validateCallsWebrtcParticipant504.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type CallsWebrtcParticipantMap = Endpoints['calls_webrtc_participant'];

type CallsRtmpParticipantResponse =
    | {status: 200; data: Endpoints['calls_rtmp_participant']['200']}
    | {status: 400; data: Endpoints['calls_rtmp_participant']['400']}
    | {status: 403; data: Endpoints['calls_rtmp_participant']['403']}
    | {status: 500; data: Endpoints['calls_rtmp_participant']['500']}
    | {status: 504; data: Endpoints['calls_rtmp_participant']['504']};
export async function callsRtmpParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['calls_rtmp_participant']['Params'];
    body: Endpoints['calls_rtmp_participant']['Body'];
    host: string;
}): Promise<CallsRtmpParticipantResponse> {
    if (!validateCallsRtmpParticipantBody(body)) {
        throw new ValidationError(validateCallsRtmpParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/calls`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateCallsRtmpParticipant200(data)) {
                throw new ValidationError(
                    validateCallsRtmpParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['calls_rtmp_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 400) {
        const data = await res.text();
        if (!validateCallsRtmpParticipant400(data)) {
            throw new ValidationError(validateCallsRtmpParticipant400.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateCallsRtmpParticipant403(data)) {
                throw new ValidationError(
                    validateCallsRtmpParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['calls_rtmp_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 500) {
        const data = await res.text();
        if (!validateCallsRtmpParticipant500(data)) {
            throw new ValidationError(validateCallsRtmpParticipant500.errors);
        }
        return {status: res.status, data};
    }

    if (res.status === 504) {
        const data = await res.text();
        if (!validateCallsRtmpParticipant504(data)) {
            throw new ValidationError(validateCallsRtmpParticipant504.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type CallsRtmpParticipantMap = Endpoints['calls_rtmp_participant'];

type OverlaytextParticipantResponse =
    | {status: 200; data: Endpoints['overlaytext_participant']['200']}
    | {status: 403; data: Endpoints['overlaytext_participant']['403']};
export async function overlaytextParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['overlaytext_participant']['Params'];
    body: Endpoints['overlaytext_participant']['Body'];
    host: string;
}): Promise<OverlaytextParticipantResponse> {
    if (!validateOverlaytextParticipantBody(body)) {
        throw new ValidationError(validateOverlaytextParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/overlaytext`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateOverlaytextParticipant200(data)) {
                throw new ValidationError(
                    validateOverlaytextParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['overlaytext_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateOverlaytextParticipant403(data)) {
                throw new ValidationError(
                    validateOverlaytextParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['overlaytext_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type OverlaytextParticipantMap = Endpoints['overlaytext_participant'];

type PresInMixParticipantResponse =
    | {status: 200; data: Endpoints['pres_in_mix_participant']['200']}
    | {status: 403; data: Endpoints['pres_in_mix_participant']['403']};
export async function presInMixParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['pres_in_mix_participant']['Params'];
    body: Endpoints['pres_in_mix_participant']['Body'];
    host: string;
}): Promise<PresInMixParticipantResponse> {
    if (!validatePresInMixParticipantBody(body)) {
        throw new ValidationError(validatePresInMixParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/pres_in_mix`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validatePresInMixParticipant200(data)) {
                throw new ValidationError(
                    validatePresInMixParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['pres_in_mix_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validatePresInMixParticipant403(data)) {
                throw new ValidationError(
                    validatePresInMixParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['pres_in_mix_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type PresInMixParticipantMap = Endpoints['pres_in_mix_participant'];

type RoleParticipantResponse =
    | {status: 200; data: Endpoints['role_participant']['200']}
    | {status: 403; data: Endpoints['role_participant']['403']};
export async function roleParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['role_participant']['Params'];
    body: Endpoints['role_participant']['Body'];
    host: string;
}): Promise<RoleParticipantResponse> {
    if (!validateRoleParticipantBody(body)) {
        throw new ValidationError(validateRoleParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/role`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRoleParticipant200(data)) {
                throw new ValidationError(validateRoleParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['role_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateRoleParticipant403(data)) {
                throw new ValidationError(validateRoleParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['role_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type RoleParticipantMap = Endpoints['role_participant'];

type FeccParticipantResponse =
    | {status: 200; data: Endpoints['fecc_participant']['200']}
    | {status: 403; data: Endpoints['fecc_participant']['403']};
export async function feccParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['fecc_participant']['Params'];
    body: Endpoints['fecc_participant']['Body'];
    host: string;
}): Promise<FeccParticipantResponse> {
    if (!validateFeccParticipantBody(body)) {
        throw new ValidationError(validateFeccParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/fecc`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateFeccParticipant200(data)) {
                throw new ValidationError(validateFeccParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['fecc_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateFeccParticipant403(data)) {
                throw new ValidationError(validateFeccParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['fecc_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type FeccParticipantMap = Endpoints['fecc_participant'];

type BuzzParticipantResponse =
    | {status: 200; data: Endpoints['buzz_participant']['200']}
    | {status: 403; data: Endpoints['buzz_participant']['403']};
export async function buzzParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['buzz_participant']['Params'];
    host: string;
}): Promise<BuzzParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/buzz`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBuzzParticipant200(data)) {
                throw new ValidationError(validateBuzzParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['buzz_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateBuzzParticipant403(data)) {
                throw new ValidationError(validateBuzzParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['buzz_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type BuzzParticipantMap = Endpoints['buzz_participant'];

type ClearbuzzParticipantResponse =
    | {status: 200; data: Endpoints['clearbuzz_participant']['200']}
    | {status: 403; data: Endpoints['clearbuzz_participant']['403']};
export async function clearbuzzParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['clearbuzz_participant']['Params'];
    host: string;
}): Promise<ClearbuzzParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/clearbuzz`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateClearbuzzParticipant200(data)) {
                throw new ValidationError(
                    validateClearbuzzParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['clearbuzz_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateClearbuzzParticipant403(data)) {
                throw new ValidationError(
                    validateClearbuzzParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['clearbuzz_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ClearbuzzParticipantMap = Endpoints['clearbuzz_participant'];

type TransferParticipantResponse =
    | {status: 200; data: Endpoints['transfer_participant']['200']}
    | {status: 403; data: Endpoints['transfer_participant']['403']};
export async function transferParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['transfer_participant']['Params'];
    body: Endpoints['transfer_participant']['Body'];
    host: string;
}): Promise<TransferParticipantResponse> {
    if (!validateTransferParticipantBody(body)) {
        throw new ValidationError(validateTransferParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/transfer`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateTransferParticipant200(data)) {
                throw new ValidationError(
                    validateTransferParticipant200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['transfer_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateTransferParticipant403(data)) {
                throw new ValidationError(
                    validateTransferParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['transfer_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type TransferParticipantMap = Endpoints['transfer_participant'];

type MessageParticipantResponse =
    | {status: 200; data: Endpoints['message_participant']['200']}
    | {status: 403; data: Endpoints['message_participant']['403']};
export async function messageParticipant({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['message_participant']['Params'];
    body: Endpoints['message_participant']['Body'];
    host: string;
}): Promise<MessageParticipantResponse> {
    if (!validateMessageParticipantBody(body)) {
        throw new ValidationError(validateMessageParticipantBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/message`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateMessageParticipant200(data)) {
                throw new ValidationError(validateMessageParticipant200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['message_participant']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateMessageParticipant403(data)) {
                throw new ValidationError(validateMessageParticipant403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['message_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type MessageParticipantMap = Endpoints['message_participant'];

type AvatarJpgParticipantResponse =
    | {status: 403; data: Endpoints['avatar.jpg_participant']['403']}
    | {status: 404; data: Endpoints['avatar.jpg_participant']['404']};
export async function avatarJpgParticipant({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['avatar.jpg_participant']['Params'];
    host: string;
}): Promise<AvatarJpgParticipantResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/avatar.jpg`,
        {
            ...init,
            method: 'get',
        },
    );

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateAvatarJpgParticipant403(data)) {
                throw new ValidationError(
                    validateAvatarJpgParticipant403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['avatar.jpg_participant']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 404) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateAvatarJpgParticipant404(data)) {
                throw new ValidationError(
                    validateAvatarJpgParticipant404.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['avatar.jpg_participant']['404'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type AvatarJpgParticipantMap = Endpoints['avatar.jpg_participant'];

type TakeFloorResponse =
    | {status: 200; data: Endpoints['take_floor']['200']}
    | {status: 403; data: Endpoints['take_floor']['403']};
export async function takeFloor({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['take_floor']['Params'];
    host: string;
}): Promise<TakeFloorResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/take_floor`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateTakeFloor200(data)) {
                throw new ValidationError(validateTakeFloor200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['take_floor']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateTakeFloor403(data)) {
                throw new ValidationError(validateTakeFloor403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['take_floor']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type TakeFloorMap = Endpoints['take_floor'];

type ReleaseFloorResponse =
    | {status: 200; data: Endpoints['release_floor']['200']}
    | {status: 403; data: Endpoints['release_floor']['403']};
export async function releaseFloor({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['release_floor']['Params'];
    host: string;
}): Promise<ReleaseFloorResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/release_floor`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateReleaseFloor200(data)) {
                throw new ValidationError(validateReleaseFloor200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['release_floor']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateReleaseFloor403(data)) {
                throw new ValidationError(validateReleaseFloor403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['release_floor']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ReleaseFloorMap = Endpoints['release_floor'];

type ShowLiveCaptionsResponse =
    | {status: 200; data: Endpoints['show_live_captions']['200']}
    | {status: 403; data: Endpoints['show_live_captions']['403']};
export async function showLiveCaptions({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['show_live_captions']['Params'];
    host: string;
}): Promise<ShowLiveCaptionsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/show_live_captions`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateShowLiveCaptions200(data)) {
                throw new ValidationError(validateShowLiveCaptions200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['show_live_captions']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateShowLiveCaptions403(data)) {
                throw new ValidationError(validateShowLiveCaptions403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['show_live_captions']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ShowLiveCaptionsMap = Endpoints['show_live_captions'];

type HideLiveCaptionsResponse =
    | {status: 200; data: Endpoints['hide_live_captions']['200']}
    | {status: 403; data: Endpoints['hide_live_captions']['403']};
export async function hideLiveCaptions({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['hide_live_captions']['Params'];
    host: string;
}): Promise<HideLiveCaptionsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/hide_live_captions`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateHideLiveCaptions200(data)) {
                throw new ValidationError(validateHideLiveCaptions200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['hide_live_captions']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateHideLiveCaptions403(data)) {
                throw new ValidationError(validateHideLiveCaptions403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['hide_live_captions']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type HideLiveCaptionsMap = Endpoints['hide_live_captions'];

type PreferredAspectRatioResponse =
    | {status: 200; data: Endpoints['preferred_aspect_ratio']['200']}
    | {status: 403; data: Endpoints['preferred_aspect_ratio']['403']};
export async function preferredAspectRatio({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['preferred_aspect_ratio']['Params'];
    body: Endpoints['preferred_aspect_ratio']['Body'];
    host: string;
}): Promise<PreferredAspectRatioResponse> {
    if (!validatePreferredAspectRatioBody(body)) {
        throw new ValidationError(validatePreferredAspectRatioBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/preferred_aspect_ratio`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validatePreferredAspectRatio200(data)) {
                throw new ValidationError(
                    validatePreferredAspectRatio200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['preferred_aspect_ratio']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validatePreferredAspectRatio403(data)) {
                throw new ValidationError(
                    validatePreferredAspectRatio403.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['preferred_aspect_ratio']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type PreferredAspectRatioMap = Endpoints['preferred_aspect_ratio'];

type NotifyNotAFKResponse =
    | {status: 200; data: Endpoints['notify_not_a_f_k']['200']}
    | {status: 403; data: Endpoints['notify_not_a_f_k']['403']};
export async function notifyNotAFK({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['notify_not_a_f_k']['Params'];
    host: string;
}): Promise<NotifyNotAFKResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants/${encodeURIComponent(params.participantUuid)}/notify_not_afk`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateNotifyNotAFK200(data)) {
                throw new ValidationError(validateNotifyNotAFK200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['notify_not_a_f_k']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 403) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateNotifyNotAFK403(data)) {
                throw new ValidationError(validateNotifyNotAFK403.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['notify_not_a_f_k']['403'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type NotifyNotAFKMap = Endpoints['notify_not_a_f_k'];
