import {useCallback, useEffect, useState} from 'react';

import type {
    useAutoHideUIInterfaces as useAutoHideUIInterfacesType,
    useFullscreenApi as useFullscreenApiType,
} from '@pexip/hooks';
import {isFullscreen} from '@pexip/hooks';
import type {Signal} from '@pexip/signal';

import type {
    EnableInMeetingUIAutoHideState,
    InMeetingUI,
    MeetingPanelsState,
    MeetingPanelType,
} from '../types';
import {PanelAnimationTypes} from '../utils/meetingPanels';

import {useInMeetingUIState} from './useInMeetingUIState';

export type InMeetingUIHookArgs = {
    enableInMeetingUIAutoHide: Signal<EnableInMeetingUIAutoHideState>;
    useAutoHideUIInterfaces: typeof useAutoHideUIInterfacesType;
    useFullscreenApi: typeof useFullscreenApiType;
    togglePanel: (
        panel: MeetingPanelType,
    ) => (panelsState: MeetingPanelsState) => [boolean, PanelAnimationTypes];
};

export const createInMeetingUIHook =
    (inMeetingUIArgs: InMeetingUIHookArgs) =>
    ({
        isStreamReady,
        isAutoHideUiControlsDisabledByConfig,
        toggleConfirm,
    }: {
        isStreamReady: boolean;
        isAutoHideUiControlsDisabledByConfig: boolean;
        toggleConfirm?: Record<
            MeetingPanelType,
            (togglePanel: () => void) => void
        >;
    }): InMeetingUI => {
        const {
            enableInMeetingUIAutoHide,
            useAutoHideUIInterfaces,
            useFullscreenApi,
            togglePanel,
        } = inMeetingUIArgs;

        const [panelsState, setPanelsState] = useState<MeetingPanelsState>({
            openBreakoutRoomsPanel: false,
            openChatPanel: false,
            openParticipantPanel: false,
            openAddParticipant: false,
            openPluginsPanel: false,
            animationType: PanelAnimationTypes.NONE,
        });

        const togglePanelState = useCallback(
            (panel: MeetingPanelType) => {
                if (panel === 'add') {
                    setPanelsState(prevPanelsState => ({
                        ...prevPanelsState,
                        openAddParticipant: !prevPanelsState.openAddParticipant,
                    }));
                    return;
                }

                setPanelsState(prevPanelsState => {
                    const [isOpen, animationType] =
                        togglePanel(panel)(prevPanelsState);

                    const visibility = {
                        openAddParticipant: prevPanelsState.openAddParticipant,
                        openBreakoutRoomsPanel:
                            panel === 'breakoutRooms' && isOpen,
                        openChatPanel: panel === 'chat' && isOpen,
                        openParticipantPanel:
                            panel === 'participants' && isOpen,
                        openPluginsPanel: panel === 'plugins' && isOpen,
                    };

                    return {
                        ...visibility,
                        animationType,
                    };
                });
            },
            [togglePanel, setPanelsState],
        );

        const togglePanels = useCallback(
            (panel: MeetingPanelType) => {
                // Special handling for panel toggle confirmation
                let requiredConfirm: MeetingPanelType | undefined;
                if (panelsState.openBreakoutRoomsPanel) {
                    requiredConfirm = 'breakoutRooms';
                } else if (panelsState.openChatPanel) {
                    requiredConfirm = 'chat';
                } else if (panelsState.openAddParticipant) {
                    requiredConfirm = 'participants';
                } else if (panelsState.openPluginsPanel) {
                    requiredConfirm = 'plugins';
                }

                if (requiredConfirm && toggleConfirm?.[requiredConfirm]) {
                    toggleConfirm[requiredConfirm](() =>
                        togglePanelState(panel),
                    );
                    return;
                }

                // Default handling for all panels
                togglePanelState(panel);
            },
            [
                panelsState.openAddParticipant,
                panelsState.openBreakoutRoomsPanel,
                panelsState.openChatPanel,
                panelsState.openPluginsPanel,
                toggleConfirm,
                togglePanelState,
            ],
        );

        const {enableAutoHide} = useAutoHideUIInterfaces(
            isStreamReady,
            isAutoHideUiControlsDisabledByConfig,
        );

        const showSidePanel =
            panelsState.openParticipantPanel ||
            panelsState.openChatPanel ||
            panelsState.openBreakoutRoomsPanel ||
            panelsState.openPluginsPanel;

        const {autoHideProps, isAutoHideUIVisible} = useInMeetingUIState(
            showSidePanel,
            enableAutoHide,
            enableInMeetingUIAutoHide,
        );
        const fullscreenApi = useFullscreenApi();

        useEffect(
            () => () => {
                if (isFullscreen(fullscreenApi.fullscreenElement)) {
                    void fullscreenApi.exitFullscreen?.();
                }
            },
            [fullscreenApi],
        );

        return {
            autoHideProps,
            panelsState,
            showSidePanel,
            togglePanels,
            isAutoHideUIVisible,
        };
    };
