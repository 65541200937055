import type {HTMLProps} from 'react';
import React from 'react';
import cx from 'classnames';

import {Text} from '../../elements/Text/Text';
import {Icon} from '../../elements/Icon/Icon';
import type {IconSource} from '../../../../design-tokens/generated/Icons';
import type {
    ExtendedFixedSizeModifier,
    LogoSizeModifier,
} from '../../../types/sizes';
import type {ColorScheme, TextVariant} from '../../../types/variants';
import {FontVariant} from '../../../../design-tokens/constants';
import {ThemeConsumer, ThemeProvider} from '../../../themes/ThemeContext';

import styles from './RoundIndicator.module.scss';

export type RoundIndicatorModifier = 'border' | 'noBackground';

export type RoundIndicatorBaseProps = HTMLProps<HTMLDivElement> & {
    alt?: string;
    className?: string;
    iconType?: IconSource;
    iconSize?: LogoSizeModifier;
    imageUrl?: string;
    isActive?: boolean;
    text?: string;
    textVariant?: TextVariant;
    fontVariant?: FontVariant;
    title?: string;
};

export type RoundIndicatorBaseTypeProps =
    | {
          type: 'icon';
          iconType: IconSource;
          title?: string;
      }
    | {
          type: 'text';
          text: string;
      }
    | {
          type: 'image';
          imageUrl: string;
          alt: string;
      };

export const RoundIndicatorBase: React.FC<
    RoundIndicatorBaseProps & RoundIndicatorBaseTypeProps
> = ({
    alt,
    className,
    text,
    iconType,
    iconSize = 'compact',
    imageUrl,
    type,
    textVariant = 'inherit',
    fontVariant = FontVariant.BodyBold,
    title,
    ...props
}) => {
    const isIcon = type === 'icon';
    const isText = type === 'text';
    const isImage = type === 'image';

    const hasTextWrapper = isText || isIcon;

    return (
        <div className={cx(styles.wrap, className)} title={title} {...props}>
            {hasTextWrapper && (
                <Text
                    fontVariant={fontVariant}
                    variant={textVariant}
                    className={cx({
                        [styles.text]: isText,
                        'd-flex': isIcon,
                    })}
                    isTruncated
                >
                    {isText && text}

                    {isIcon && iconType && (
                        <Icon size={iconSize} source={iconType} />
                    )}
                </Text>
            )}

            {isImage && imageUrl && (
                <img src={imageUrl} alt={alt} className={styles.image} />
            )}
        </div>
    );
};

export const RoundIndicator: React.FC<
    React.ComponentProps<typeof RoundIndicatorBase> & {
        colorScheme?: ColorScheme;
        modifier?: RoundIndicatorModifier;
        sizeModifier?: ExtendedFixedSizeModifier;
    }
> = ({
    className,
    colorScheme,
    modifier,
    textVariant,
    sizeModifier = 'small',
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => {
            const currentColorScheme = colorScheme ?? defaultColorScheme;
            const defaultTextVariant =
                colorScheme === 'dark' ? 'subtle' : 'tinted';

            return (
                <ThemeProvider colorScheme={currentColorScheme}>
                    <RoundIndicatorBase
                        className={cx(
                            currentColorScheme,
                            styles[currentColorScheme],
                            styles[sizeModifier],
                            {
                                [styles.border]: modifier === 'border',
                                [styles.noBackground]:
                                    modifier === 'noBackground',
                            },
                            className,
                        )}
                        textVariant={textVariant ?? defaultTextVariant}
                        {...props}
                    />
                </ThemeProvider>
            );
        }}
    </ThemeConsumer>
);

export type RoundIndicatorProps = React.ComponentProps<typeof RoundIndicator>;
