import React from 'react';

import type {ChatMessage} from '../../types';
import {TestId} from '../../../test/testIds';

import {ChatMessageView} from './ChatMessage.view';
import {ChatActivityMessage} from './ChatActivityMessage.view';

export const ChatPanelMessages: React.FC<
    {messages: ChatMessage[]} & React.ComponentProps<'div'>
> = ({messages, className}) => (
    <div data-testid={TestId.ChatMessages} className={className}>
        {messages.map(message =>
            message.type === 'activity' ? (
                <ChatActivityMessage key={message.id} message={message} />
            ) : (
                <ChatMessageView key={message.id} message={message} />
            ),
        )}
    </div>
);
