import {useEffect, useState} from 'react';

import {useXs} from '@pexip/components';

export const useShouldPanelBeModal = (isOpen: boolean) => {
    const isXs = useXs();
    const [shouldBeModal, setShouldBeModal] = useState(false);

    useEffect(() => {
        setShouldBeModal(isXs && isOpen);
    }, [isOpen, isXs]);

    return shouldBeModal;
};
