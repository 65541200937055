import React from 'react';
import cx from 'classnames';

import {Button} from '../../elements/Button/Button';
import {Icon, IconTypes} from '../../elements/Icon/Icon';
import type {ClickEventHandler} from '../../../types/events';
import type {ColorScheme} from '../../../types/variants';

import styles from './ModalCloseButton.module.scss';

/**
 * A generic close button for modals, tooltips and pill toasts.
 */
export const ModalCloseButton: React.FC<{
    className?: string;
    colorScheme?: ColorScheme;
    onClose?: ClickEventHandler;
}> = ({className, onClose, ...props}) => (
    <Button
        className={cx(styles.closeButton, className)}
        onClick={onClose}
        size="none"
        variant="translucent"
        noHover
        {...props}
    >
        <Icon
            className={styles.icon}
            size="compact"
            source={IconTypes.IconClose}
        />
    </Button>
);

export type ModalCloseButtonProps = React.ComponentProps<
    typeof ModalCloseButton
>;
