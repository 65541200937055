import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {notificationToastSignal} from '@pexip/components';

import type {BreakoutParticipants} from '../types';
import type {ShuffleBreakoutParticipants} from '../utils';
import {shuffleBreakoutParticipants} from '../utils';

export const useBreakoutShuffleParticipants = ({
    setParticipants,
    myIdentity,
    shuffle = shuffleBreakoutParticipants,
}: {
    myIdentity?: string;
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
    shuffle?: ShuffleBreakoutParticipants;
}) => {
    const shuffleTimeout = useRef<number>();
    const [isShuffling, setIsShuffling] = useState(false);
    const {t} = useTranslation();

    const doShuffle = useCallback(() => {
        setIsShuffling(true);
        setParticipants(prevParticipants =>
            shuffle({allParticipants: prevParticipants, identity: myIdentity}),
        );
        shuffleTimeout.current = window.setTimeout(() => {
            // minimum time to show that shuffling is ongoing
            setIsShuffling(false);
            notificationToastSignal.emit([
                {
                    message: t(
                        'breakouts.shuffle-done-notification',
                        'Participants have been shuffled',
                    ),
                },
            ]);
        }, 500);
    }, [myIdentity, setParticipants, shuffle, t]);

    useEffect(() => {
        return () => {
            clearTimeout(shuffleTimeout.current);
        };
    }, []);

    return {isShuffling, doShuffle};
};
