import React from 'react';
import cx from 'classnames';

import {Text} from '../Text/Text';
import {Icon, IconTypes} from '../Icon/Icon';
import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import {removeKeyFromObject} from '../../../utils/helpers';
import {TestId} from '../../../utils/testIds';

import styles from './Checkbox.module.scss';

export type CheckboxProps = Omit<
    React.ComponentProps<'input'>,
    'type' | 'disabled'
> & {
    className?: string;
    isDisabled?: boolean;
    label: string;
    labelModifier?: 'default' | 'hidden' | 'spaced';
    labelPosition?: 'start' | 'end';
    name: string;
    colorScheme?: ColorScheme;
};

export const Checkbox = React.forwardRef(function forwardedCheckbox(
    {
        checked,
        className,
        isDisabled,
        label,
        labelModifier = 'default',
        labelPosition = 'end',
        name,
        colorScheme,
        ...props
    }: CheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>,
) {
    const isLabelHidden = labelModifier === 'hidden';
    // This removes 'indeterminate' prop which is passed down by the table component in Trieste
    const inputProps = removeKeyFromObject('indeterminate', {...props});
    const labelId = `${name}-label`;

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <label
                    id={labelId}
                    className={cx(
                        styles.checkbox,
                        styles[colorScheme ?? defaultColorScheme],
                        styles[labelPosition],
                        {
                            [styles.disabled]: isDisabled,
                            [styles.spaced]: labelModifier === 'spaced',
                        },
                        className,
                    )}
                    title={isLabelHidden ? label : undefined}
                >
                    <input
                        ref={ref}
                        className={styles.input}
                        type="checkbox"
                        name={name}
                        checked={checked}
                        disabled={isDisabled}
                        aria-labelledby={labelId}
                        {...inputProps}
                    />
                    <span
                        className={cx(styles.checkmark, {
                            [styles.checked]: checked,
                        })}
                    >
                        {checked && (
                            <Icon
                                data-testid={TestId.CheckboxCheck}
                                source={IconTypes.IconCheckmark}
                            />
                        )}
                    </span>
                    <Text
                        variant={isDisabled ? 'disabled' : 'standard'}
                        className={cx(styles.label, {
                            [styles.hidden]: isLabelHidden,
                        })}
                    >
                        {label}
                    </Text>
                </label>
            )}
        </ThemeConsumer>
    );
});
