import React, {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Panel, Tooltip, useSmUp} from '@pexip/components';

import type {
    BreakoutParticipants,
    BreakoutRoomAssignmentMode,
} from '../../types';
import {BreakoutRoom} from '../BreakoutRoom/BreakoutRoom.view';
import {BreakoutRoomsConfigurationMenu} from '../BreakoutRoomsConfiguration/BreakoutRoomsConfiguration.view';
import {PanelHeader} from '../PanelHeader/PanelHeader.view';
import {
    useBreakoutAddRoom,
    useBreakoutAssignParticipantsByMode,
    useBreakoutChangeParticipantRoom,
    useBreakoutParticipantDrag,
    useBreakoutRemoveRoom,
    useBreakoutRoomNameChange,
    useBreakoutShuffleParticipants,
    useBreakoutUpdateParticipants,
} from '../../hooks';
import {getBreakoutRooms} from '../../utils/getBreakoutRooms';
import {TestId} from '../../../test/testIds';
import type {ShuffleBreakoutParticipants} from '../../utils/shuffleBreakoutParticipants';
import {shuffleBreakoutParticipants} from '../../utils/shuffleBreakoutParticipants';

export const BreakoutSetupPanel: React.FC<
    React.PropsWithChildren<{
        currentParticipants: BreakoutParticipants;
        myIdentity?: string;
        openRoomsText: string;
        isOpeningRooms?: boolean;
        numberOfRooms: number;
        assignmentMode: BreakoutRoomAssignmentMode;
        headerText?: string;
        onOpenRooms: (participants: BreakoutParticipants) => void;
        onClosePanel: () => void;
        onBack: () => void;
        onSettingsButtonClick: () => void;
        shuffle?: ShuffleBreakoutParticipants;
    }>
> = ({
    currentParticipants,
    myIdentity,
    openRoomsText,
    isOpeningRooms = false,
    numberOfRooms = 0,
    assignmentMode,
    headerText,
    onBack,
    onClosePanel,
    onOpenRooms,
    onSettingsButtonClick,
    shuffle = shuffleBreakoutParticipants,
    children,
}) => {
    const {t} = useTranslation();
    const isSmUp = useSmUp();
    const [participants, setParticipants] = useState(currentParticipants);

    const [isDragging, setIsDragging] = useState(false);

    const scrollElementRef = useRef<HTMLElement>(null);

    const {handleBreakoutRoomNameChange, duplicateRoomNameModal} =
        useBreakoutRoomNameChange({
            participants,
            setParticipants,
        });

    const changeParticipantRoom = useBreakoutChangeParticipantRoom({
        participants,
        setParticipants,
    });

    useBreakoutUpdateParticipants({
        currentParticipants,
        participants,
        setParticipants,
        changeParticipantRoom,
    });

    useBreakoutAssignParticipantsByMode({
        assignmentMode,
        myIdentity,
        numberOfRooms,
        setParticipants,
        shuffle,
    });

    const {
        targetRoomId: currentDragTarget,
        onParticipantDragging,
        onParticipantDrag,
        getBreakoutRoomRefCallback,
    } = useBreakoutParticipantDrag({
        participants,
        changeParticipantRoom,
        setIsDragging,
    });

    const onRemoveRoom = useBreakoutRemoveRoom({setParticipants});

    const {doShuffle, isShuffling} = useBreakoutShuffleParticipants({
        myIdentity,
        setParticipants,
        shuffle,
    });

    const onAddRoom = useBreakoutAddRoom({setParticipants});

    const isNoBreakoutRooms = useMemo(
        () => getBreakoutRooms(participants).length < 1,
        [participants],
    );
    const openRoomsButton = (
        <Button
            onClick={() => onOpenRooms(participants)}
            isLoading={isOpeningRooms}
            modifier="fullWidth"
            isDisabled={isNoBreakoutRooms}
            data-testid={TestId.OpenBreakoutRooms}
        >
            {openRoomsText}
        </Button>
    );

    return (
        <>
            <Panel
                scrollElementRef={scrollElementRef}
                overflowHidden={isDragging}
                isRounded={isSmUp}
                padding="none"
                footerPadding="small"
                headerContent={
                    <PanelHeader
                        title={headerText}
                        onBackClick={onBack}
                        onCloseClick={onClosePanel}
                        closeButtonTestId={TestId.PanelHeaderCloseButton}
                        endContent={
                            <BreakoutRoomsConfigurationMenu
                                onAddRoomButtonClick={onAddRoom}
                                onSettingsButtonClick={onSettingsButtonClick}
                                onShuffledButtonClick={doShuffle}
                                isShuffling={isShuffling}
                            />
                        }
                    />
                }
                footerContent={
                    isNoBreakoutRooms ? (
                        <Tooltip
                            tooltipContainerClassName="d-flex"
                            text={t(
                                'breakouts.open-rooms-btn-disabled-tooltip',
                                'Minimum one breakout room is required',
                            )}
                            position="top"
                        >
                            {openRoomsButton}
                        </Tooltip>
                    ) : (
                        openRoomsButton
                    )
                }
                scrollElementClassname="pt-2 pr-3 pb-3 pl-3"
            >
                {Array.from(participants.entries()).map(
                    ([roomId, roomParticipants]) => {
                        return (
                            <BreakoutRoom
                                scrollElementRef={scrollElementRef}
                                id={roomId}
                                key={roomId}
                                name={roomId}
                                rooms={Array.from(participants.keys()).reduce(
                                    (acc, current) =>
                                        roomId === current
                                            ? acc
                                            : {
                                                  ...acc,
                                                  [current]: current,
                                              },
                                    {},
                                )}
                                myIdentity={myIdentity}
                                participants={roomParticipants}
                                isDragTarget={currentDragTarget === roomId}
                                onNameChanged={handleBreakoutRoomNameChange}
                                onParticipantDragging={onParticipantDragging}
                                onParticipantDrag={onParticipantDrag}
                                onParticipantRoomChange={changeParticipantRoom}
                                onRemoveRoom={onRemoveRoom}
                                ref={getBreakoutRoomRefCallback(roomId)}
                                canEditRoomName={true}
                            />
                        );
                    },
                )}
            </Panel>
            {duplicateRoomNameModal}
            {children}
        </>
    );
};
