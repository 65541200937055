import React from 'react';

import type {AriaMenuItem} from '@pexip/components';
import {Button, Modal} from '@pexip/components';

import {ParticipantRowMoreActionsHeader} from '../ParticipantRowMoreActionsHeader/ParticipantRowMoreActionsHeader.view';

import styles from './ParticipantRowMoreActionsTouchModal.module.scss';

export const ParticipantRowMoreActionsTouchModal: React.FC<{
    displayName: string;
    isAuthenticated: boolean;
    showParticipantAuthenticatedState: boolean;
    isOpen: boolean;
    button: React.ReactElement<typeof Button>;
    menuContent: AriaMenuItem[];
    onOpenChange: (state: boolean) => void;
}> = ({
    displayName,
    isAuthenticated,
    showParticipantAuthenticatedState,
    isOpen,
    button,
    menuContent,
    onOpenChange,
}) => (
    <>
        {button}
        <Modal
            padding="compact"
            withCloseButton
            closeOnOutsideClick
            isOpen={isOpen}
            onClose={() => onOpenChange(false)}
        >
            <div className={styles.modalContent}>
                <ParticipantRowMoreActionsHeader
                    isTouchDevice
                    displayName={displayName}
                    isAuthenticated={isAuthenticated}
                    showParticipantAuthenticatedState={
                        showParticipantAuthenticatedState
                    }
                />
                <div className={styles.buttonContainer}>
                    {menuContent.map(item => (
                        <Button
                            className="justify-content-start"
                            size="large"
                            modifier="fullWidth"
                            key={item.key}
                            variant="text"
                            enhancerStart={item.icon}
                            onClick={() => {
                                item.onItemClick?.();
                                onOpenChange(false);
                            }}
                        >
                            {item.label}
                        </Button>
                    ))}
                </div>
            </div>
        </Modal>
    </>
);
