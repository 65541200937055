import React, {useState, useEffect, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {Toast} from '@pexip/components';

export const ToastSpeakingWhileMuted: React.FC<{
    voiceDetected: boolean;
    audioMuted: boolean;
    setVoiceDetected: (isVoidDetected: boolean) => void;
}> = ({voiceDetected, audioMuted, setVoiceDetected}) => {
    const {t} = useTranslation();

    const [silentMode, setSilentMode] = useState(false);

    useEffect(() => {
        if (!audioMuted) {
            setSilentMode(false);
            setVoiceDetected(false);
        }
        return () => {
            setVoiceDetected(false);
        };
    }, [audioMuted, setVoiceDetected]);

    const shouldBeShown = voiceDetected && audioMuted && !silentMode;

    const handleDismiss = useCallback(
        (audiMuted: boolean) => {
            if (audiMuted) {
                setSilentMode(true);
            }
            setVoiceDetected(false);
        },
        [setVoiceDetected],
    );

    return (
        <Toast
            message={t(
                'media.trying-to-speak',
                'Trying to speak? Your microphone is muted',
            )}
            isDisplayed={shouldBeShown}
            isVisible={shouldBeShown}
            onDismiss={() => handleDismiss(audioMuted)}
            position="bottomCenter"
            colorScheme="light"
            timeout={0}
        />
    );
};
