import type {Signal} from '@pexip/signal';
import type {Backoff} from '@pexip/utils';
import type {
    ConferenceStatusMap,
    ParticipantsMap,
    TransformLayoutMap,
    FeccParticipantMap,
    DialMap,
    InfinityErrorMessage,
    InfinityErrorCode,
    TransferParticipantMap,
    unlockParticipant,
    disconnect,
    disconnectParticipant,
    dial,
    muteParticipant,
    unmuteParticipant,
    muteguests,
    unmuteguests,
    videoMuteParticipant,
    videoUnmuteParticipant,
    lock,
    unlock,
    transformLayout,
    buzzParticipant,
    clearbuzzParticipant,
    spotlightonParticipant,
    spotlightoffParticipant,
    ack,
    update,
    newCandidate,
    callsWebrtcParticipant,
    takeFloor,
    releaseFloor,
    transferParticipant,
    CallsWebrtcParticipantMap,
    StatisticsMap,
    ThemeMap,
    MessageConferenceMap,
    messageConference,
    messageParticipant,
    requestToken,
    RequestTokenMap,
    overlaytextParticipant,
    OverlaytextParticipantMap,
    dtmfParticipant,
    dtmf,
    preferredAspectRatio,
    BreakoutsMap,
    breakoutDisconnect,
    showLiveCaptions,
    breakoutShowLiveCaptions,
    hideLiveCaptions,
    breakoutHideLiveCaptions,
    participants,
    startConference,
    breakoutsDisconnect,
    breakoutsEmpty,
    breakoutMessageConference,
    feccParticipant,
    FeccMap,
    fecc,
    breakoutUnlockParticipant,
    breakoutDisconnectParticipant,
    presInMixParticipant,
    guestLeaveBreakout,
    guestBreakoutBuzz,
    guestClearBreakoutBuzz,
    hostClearBreakoutBuzz,
    hostBreakoutBuzz,
    notifyNotAFK,
    breakoutTransformLayout,
    breakoutLock,
    breakoutUnlock,
    clearAllBuzz,
    breakoutClearAllBuzz,
    clientMuteParticipant,
    clientUnmuteParticipant,
    setGuestsCanUnmute,
} from '@pexip/infinity-api';
import type {
    AudioQualityStats,
    CallQualityStats,
    InboundAudioMetrics,
    InboundVideoMetrics,
    NormalizedRTCStats,
    OutboundAudioMetrics,
    OutboundVideoMetrics,
    Quality,
    StatsCollector,
    StatsSignals,
} from '@pexip/peer-connection-stats';
import type {
    Bandwidth,
    CorePeerConnectionSignals,
    MainPeerConnectionOptions,
    PCOptionalsSignals,
    PeerConnectionCommandSignals,
} from '@pexip/peer-connection';

export type MessageBody = MessageConferenceMap['Body'];
export type ConferenceStateEvent = ConferenceStatusMap['200']['result'];
// `last_spoken_time` field will not be updated via participant events
export type RTCParticipantEvent = Omit<
    ParticipantsMap['200']['result'][0],
    'last_spoken_time'
>;
export type Transforms = TransformLayoutMap['Body']['transforms'];
export type Layout = Transforms['layout'];
export type FeccEvent = Omit<FeccParticipantMap['Body'], 'target'>;
export type Role = TransferParticipantMap['Body']['role'];
export type Stun = RequestTokenMap['200']['result']['stun'];
export type Turn = RequestTokenMap['200']['result']['turn'];
export type ThemeSchema = ThemeMap['200']['result'];
export type Screen = ThemeSchema['direct_media_escalate'];
export type MediaType = CallsWebrtcParticipantMap['Body']['media_type'];
export type CurrentServiceType =
    | RTCParticipantEvent['service_type']
    | RequestTokenMap['200']['result']['current_service_type'];
interface RedirectIdp {
    name: string;
    uuid: string;
}

interface Idp extends RedirectIdp {
    img?: string;
    disablePopUpFlow?: boolean;
}

export interface RequestClient {
    token: string;
    expires: number;
    statsUpdateInterval?: number;

    fetcher: typeof fetch;
    refreshToken: () => Promise<boolean>;
    cleanup: (reason?: DisconnectReason) => Promise<void>;
}

export interface RequestClientOptions {
    conferenceAlias: string;
    backoff?: Backoff;
    expires?: number;
    fetcher?: typeof fetch;
    token: string;
    host: string;
    clientStatsUpdateInterval?: number;
    tokenExpiredCb?: () => void;
}

export interface BreakoutRoom {
    breakout_uuid: string;
    participant_uuid: string;
}

export interface Client {
    roomId: string;
    breakoutRooms: Map<string, BreakoutRoom>;
    conferenceStatus: Map<string, ConferenceStatus>;
    conferenceFeatureFlags?: ConferenceFeatureFlags;
    secureCheckCode: string;
    latestStats: Stats | undefined;
    serviceType: CurrentServiceType;

    getMe(roomId?: string): Participant | undefined;
    getParticipants(roomId: string): Participant[];
    setFeccSupported(canFecc: boolean): void;

    admit: (opt: {
        participantUuid: string;
        conferenceAlias?: string;
        host?: string;
        breakoutUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<ReturnType<typeof unlockParticipant>>
        | Awaited<ReturnType<typeof breakoutUnlockParticipant>>
    >;

    call: (opt: {
        conferenceAlias: string;
        directMedia?: boolean;
        displayName: string;
        bandwidth: number;
        mediaStream?: MediaStream | (() => MediaStream | undefined);
        node?: string;
        host?: string;
        pin?: string;
        chosenIdp?: string;
        ssoToken?: string;
        token?: string;
        conferenceExtension?: string;
        callTag?: string;
        callTypeQueryParameter?: string;
        callType: ClientCallType;
        mediaPriorities?: MediaPriority;
        clientId?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof requestToken>>>;

    disconnect: (opt: {
        callUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
        participantUuid?: string;
        reason?: DisconnectReason;
        callback?: (() => void) | (() => Promise<void>);
        release?: () => Promise<void>;
    }) => Promise<void>;

    kick: (opt: {
        participantUuid: string;
        conferenceAlias?: string;
        host?: string;
        breakoutUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<ReturnType<typeof disconnectParticipant>>
        | Awaited<ReturnType<typeof breakoutDisconnectParticipant>>
    >;

    dial: (
        opt: Omit<DialMap['Body'], 'protocol'> & {
            destination: string;
            conferenceAlias?: string;
            host?: string;
            protocol?: DialMap['Body']['protocol'];
        },
    ) => Promise<undefined | Awaited<ReturnType<typeof dial>>>;

    transfer: (opt: {
        destination: string;
        pin: string;
        role: Role;
        participantUuid: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof transferParticipant>>>;

    mute: (opt: {
        mute: boolean;
        participantUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<ReturnType<typeof muteParticipant | typeof unmuteParticipant>>
    >;

    clientMute: (opt: {
        mute: boolean;
        participantUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  typeof clientMuteParticipant | typeof clientUnmuteParticipant
              >
          >
    >;

    guestsCanUnmute: (opt: {
        setting: boolean;
        participantUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof setGuestsCanUnmute>>>;

    muteAllGuests: (opt: {
        mute: boolean;
        conferenceAlias?: string;
        host?: string;
    }) => Promise<
        undefined | Awaited<ReturnType<typeof muteguests | typeof unmuteguests>>
    >;

    muteVideo: (opt: {
        muteVideo: boolean;
        participantUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  typeof videoMuteParticipant | typeof videoUnmuteParticipant
              >
          >
    >;

    lock: (opt: {
        lock: boolean;
        conferenceAlias?: string;
        host?: string;
        breakoutUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  | typeof lock
                  | typeof unlock
                  | typeof breakoutLock
                  | typeof breakoutUnlock
              >
          >
    >;

    disconnectAll: (opt: {
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof disconnect>>>;

    setLayout: (opt: {
        transforms: Transforms;
        conferenceAlias?: string;
        host?: string;
        breakoutUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  typeof transformLayout | typeof breakoutTransformLayout
              >
          >
    >;

    sendMessage: (
        opt: MessageBody & {
            conferenceAlias?: string;
            host?: string;
            participantUuid?: string;
            breakoutUuid?: string;
        },
    ) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  | typeof messageConference
                  | typeof messageParticipant
                  | typeof breakoutMessageConference
              >
          >
    >;

    raiseHand: (opt: {
        raise: boolean;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        breakoutUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<typeof buzzParticipant | typeof clearbuzzParticipant>
          >
    >;

    lowerAllRaisedHands: (opt: {
        conferenceAlias?: string;
        host?: string;
        breakoutUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<ReturnType<typeof clearAllBuzz | typeof breakoutClearAllBuzz>>
    >;

    spotlight: (opt: {
        enable: boolean;
        participantUuid: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  typeof spotlightonParticipant | typeof spotlightoffParticipant
              >
          >
    >;

    ack: (opt: {
        callUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
        participantUuid?: string;
        sdp?: string;
        offerIgnored?: boolean;
    }) => Promise<undefined | Awaited<ReturnType<typeof ack>>>;

    update: (opt: {
        sdp: string;
        callUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
        participantUuid?: string;
        abortSignal?: AbortSignal;
    }) => Promise<undefined | Awaited<ReturnType<typeof update>>>;

    newCandidate: (opt: {
        candidate: IceCandidate;
        callUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof newCandidate>>>;

    sendOffer: (opt: {
        sdp: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        breakoutUuid?: string;
    }) => Promise<
        undefined | Awaited<ReturnType<typeof callsWebrtcParticipant>>
    >;

    takeFloor: (opts: {
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        breakoutUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof takeFloor>>>;

    releaseFloor: (opts: {
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        breakoutUuid?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof releaseFloor>>>;

    present: (stream?: MediaStream) => void;
    restartCall: (opts: {
        bandwidth: number;
        mediaStream?: MediaStream | (() => MediaStream | undefined);
        callType?: ClientCallType;
        mediaPriorities?: MediaPriority;
    }) => Promise<void>;
    stopPresenting: () => void;
    setStream: (stream: MediaStream) => void;
    setBandwidth: (bandwidth: number) => void;

    liveCaptions: (opt: {
        enable: boolean;
        conferenceAlias?: string;
        participantUuid?: string;
        breakoutUuid?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<
                  | typeof showLiveCaptions
                  | typeof breakoutShowLiveCaptions
                  | typeof hideLiveCaptions
                  | typeof breakoutHideLiveCaptions
              >
          >
    >;

    setRole: (opt: {
        role: 'chair' | 'guest';
        participantUuid: string;
        conferenceAlias?: string;
    }) => Promise<void>;

    setConferenceExtension: (conferenceExtension?: string) => void;
    setPin: (pin?: string) => void;
    setSsoToken: (ssoToken?: string) => void;
    setCallTag: (callTag: string) => void;

    statistics: (opt: {
        audio?: StatisticsMap['Body']['audio'];
        video?: StatisticsMap['Body']['video'];
        presentation?: StatisticsMap['Body']['presentation'];
        callUuid?: string;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<void>;

    requestTheme: (opt: {
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ThemeSchema>>;

    setTextOverlay: (
        opt: OverlaytextParticipantMap['Body'] & {
            conferenceAlias?: string;
            host?: string;
            participantUuid: string;
        },
    ) => Promise<
        undefined | Awaited<ReturnType<typeof overlaytextParticipant>>
    >;

    sendDTMF: (opt: {
        digits: string;
        participantUuid?: string;
        callUuid?: string;
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<
        undefined | Awaited<ReturnType<typeof dtmfParticipant | typeof dtmf>>
    >;

    requestAspectRatio: (opt: {
        aspectRatio: number;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        abortSignal?: AbortSignal;
    }) => ReturnType<typeof preferredAspectRatio> | Promise<undefined>;

    sendConferenceRequest: (opt: {
        path: string;
        method: 'GET' | 'POST';
        payload?: Record<string, unknown>;
    }) => Promise<undefined | {status: number; data: unknown}>;

    /**
     * Requests the current participant list from backend via 'participants' API call
     * @returns Response of 'participants' API call
     */
    requestParticipants: (opt: {
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof participants>>>;

    /**
     * @param participants - which participants from which rooms to move to the new breakout upon its creation
     * @returns
     */
    breakout: (
        opt: BreakoutsMap['Body'],
    ) => Promise<{breakout_uuid: string} | undefined>;

    joinBreakoutRoom: (opt: {breakoutUuid?: string}) => Promise<void>;
    guestLeaveBreakoutRoom: () => Promise<
        undefined | Awaited<ReturnType<typeof guestLeaveBreakout>>
    >;
    closeBreakouts: () => Promise<
        undefined | Awaited<ReturnType<typeof breakoutsDisconnect>>
    >;
    closeBreakoutRoom: (opt: {
        breakoutUuid: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof breakoutDisconnect>>>;
    /**
     * @param fromBreakoutUuid - Optional breakout uuid to move the participants from. Defaults from main room.
     * @param toRoomUuid - Breakout room uuid to move the participants to, or `main` to move them to the main room
     * @param participants - List of participant uuids to move 'fromBreakoutUuid' to 'toRoomUuid'
     */
    emptyBreakouts: () => Promise<
        undefined | Awaited<ReturnType<typeof breakoutsEmpty>>
    >;
    breakoutMoveParticipants: (opt: {
        fromBreakoutUuid?: string;
        toRoomUuid: string;
        participants: string[];
    }) => Promise<void>;
    startConference: (opt: {
        conferenceAlias?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof startConference>>>;
    fecc: (
        opt: FeccMap['Body'] & {target?: string},
    ) => Promise<
        undefined | Awaited<ReturnType<typeof feccParticipant | typeof fecc>>
    >;
    availableLayouts: (opt: {
        conferenceAlias?: string;
        host?: string;
    }) => Promise<string[] | undefined>;

    layoutSvgs: (opt: {
        conferenceAlias?: string;
        host?: string;
    }) => Promise<Record<string, string> | undefined>;
    presInMix: (opt: {
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
        state: boolean;
    }) => Promise<undefined | Awaited<ReturnType<typeof presInMixParticipant>>>;
    breakoutAskForHelp: (opt: {
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<
              ReturnType<typeof guestBreakoutBuzz | typeof hostBreakoutBuzz>
          >
    >;
    breakoutRemoveAskForHelp: (opt: {
        conferenceAlias?: string;
        breakoutUuid?: string;
        host?: string;
    }) => Promise<
        | undefined
        | Awaited<
              | ReturnType<typeof guestClearBreakoutBuzz>
              | typeof hostClearBreakoutBuzz
          >
    >;
    notifyNotAFK: (opt: {
        conferenceAlias?: string;
        participantUuid?: string;
        host?: string;
    }) => Promise<undefined | Awaited<ReturnType<typeof notifyNotAFK>>>;
}

export type InfinityClient = Omit<
    Client,
    | 'ack'
    | 'update'
    | 'newCandidate'
    | 'sendOffer'
    | 'statistics'
    | 'takeFloor'
    | 'releaseFloor'
    | 'requestTheme'
    | 'sendMessage'
> & {
    sendMessage: (opt: {
        payload: MessageBody['payload'];
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<GetEndpointResponse<'sendMessage'>>;
    sendApplicationMessage: (opt: {
        payload: Record<string, unknown>;
        conferenceAlias?: string;
        host?: string;
        participantUuid?: string;
    }) => Promise<GetEndpointResponse<'sendMessage'>>;
};

export type GetEndpointParams<K extends keyof Client> = Client[K] extends (
    ...args: never
) => unknown
    ? Parameters<Client[K]>
    : never;

export type GetEndpointResponse<K extends keyof Client> = Client[K] extends (
    ...args: never
) => infer R
    ? Awaited<R>
    : unknown;

export type EndpointResponse<K = keyof Client> = K extends keyof Client
    ? GetEndpointResponse<K>
    : never;

export interface MediaPriority {
    video: RTCPriorityType;
    audio: RTCPriorityType;
    presentation: RTCPriorityType;
}

export interface CallOptions {
    sendOffer: (opt: {
        sdp: string;
        breakoutUuid?: string;
    }) => Promise<GetEndpointResponse<'sendOffer'>>;

    ack: Client['ack'] & {breakoutUuid?: string};

    newCandidate: (opt: {
        candidate: IceCandidate;
        breakoutUuid?: string;
    }) => Promise<GetEndpointResponse<'newCandidate'>>;

    update: (opt: {
        sdp: string;
        abortSignal?: AbortSignal;
        breakoutUuid?: string;
    }) => Promise<GetEndpointResponse<'update'>>;

    takeFloor: Client['takeFloor'];
    releaseFloor: Client['releaseFloor'];

    getCurrentCallUuid: () => CallUuid;

    signals: InfinitySignals;
    eventSignals: EventSignals;
    callSignals: CallSignals;

    pcMainSignals: Pick<
        CorePeerConnectionSignals &
            Omit<PeerConnectionCommandSignals, 'onReceiveIceCandidate'> &
            Required<PCOptionalsSignals>,
        | 'onError'
        | 'onOfferIgnored'
        | 'onOfferRequired'
        | 'onReceiveIceCandidate'
        | 'onReceiveAnswer'
        | 'onReceiveOffer'
        | 'onOffer'
        | 'onAnswer'
        | 'onRemoteStreams'
        | 'onIceCandidate'
        | 'onConnectionStateChange'
        | 'onIceConnectionStateChange'
        | 'onTransceiverChange'
        | 'onSecureCheckCode'
    >;
    mainStatsSignals: MainStatsSignals;

    peerOptions: MainPeerConnectionOptions;
    dataChannelId?: number;

    mediaStream?: MediaStream;
    isDirectMedia: boolean;
    callType?: ClientCallType;
    mediaPriorities?: MediaPriority;
}

export interface Call {
    presoState: PresoState;
    disconnect: () => void;
    present: (stream?: MediaStream) => Promise<void>;
    receivePresentation: (event: NormalizedPresentationEvent) => Promise<void>;
    stopPresenting: () => Promise<void>;
    stopReceivingPresentation: () => void;
    setStream: (stream: MediaStream) => void;
    setBandwidth: (bandwidth: Bandwidth) => void;
    sendDataChannelEvent: (msg: DataChannelEvent) => void;
    mediaStream?: MediaStream;
    bandwidth: Bandwidth;
}

export interface PresoConnectionChangeEvent {
    send: RTCPeerConnectionState;
    recv: RTCPeerConnectionState;
}

export type ExtendedInfinityErrorCode =
    | InfinityErrorCode
    | '#pex128'
    | '#pex117';

export type ErrorSignal = Signal<{
    error: string;
    errorCode: ExtendedInfinityErrorCode;
}>;

export interface ClientSignals {
    onConnected: Signal<undefined>;
    onDisconnected: ErrorSignal;
    onCallDisconnected: Signal<CallDisconnectedEvent>;
    onAnswer: Signal<CallsWebrtcParticipantMap['200']['result']>;
    onPresentationAnswer: Signal<{
        sdp: string;
        callUuid: string;
        present: string;
    }>;
    onIceCandidate: Signal<RTCIceCandidate>;
    onFailedRequest: Signal<keyof Client>;
    onRetryQueueFlushed: Signal<undefined>;
}

interface RoomEvent {
    id: string;
}

interface RoomParticipantsEvent extends RoomEvent {
    participants: Participant[];
}
interface RoomParticipantEvent extends RoomEvent {
    participant: Participant;
}
interface RoomConferenceEvent extends RoomEvent {
    status: ConferenceStatus;
}

export interface ParticipantSignals {
    onParticipants: Signal<RoomParticipantsEvent>;
    onParticipantJoined: Signal<RoomParticipantEvent>;
    onParticipantLeft: Signal<RoomParticipantEvent>;
    onMe: Signal<RoomParticipantEvent>;
    onRaiseHand: Signal<RoomParticipantEvent>;
}

export interface InfinitySignals extends ClientSignals, ParticipantSignals {
    onAuthenticatedWithConference: Signal<{
        conferenceAlias: string;
        conferenceName?: string;
    }>;
    onError: ErrorSignal;
    onPinRequired: Signal<{hasHostPin: boolean; hasGuestPin: boolean}>;
    onIdp: Signal<Idp[]>;
    onRedirect: Signal<{
        redirectUrl: string;
        redirectIdp: RedirectIdp;
        disablePopupFlow?: boolean;
    }>;
    onMessage: Signal<Message>;
    onApplicationMessage: Signal<ApplicationMessage>;
    onMyselfMuted: Signal<boolean>;
    onRequestedLayout: Signal<{
        primaryScreen: {hostLayout: Layout; guestLayout: Layout};
    }>;
    onLayoutOverlayTextEnabled: Signal<boolean>;
    onStage: Signal<Stage[]>;
    onConferenceStatus: Signal<RoomConferenceEvent>;
    onTransfer: Signal<TransferDetails>;
    onCancelTransfer: Signal<Record<string, string>>;
    onLiveCaptions: Signal<{data: string; isFinal: boolean}>;
    onSplashScreen: Signal<SplashScreen>;
    onNewOffer: Signal<string>;
    onUpdateSdp: Signal<string>;
    onPeerDisconnect: Signal<undefined>;
    onExtension: Signal<string>;
    onLayoutUpdate: EventSignals['onLayoutUpdate'];
    onServiceType: Signal<CurrentServiceType>;
    onBreakoutEnd: Signal<BreakoutRoom>;
    onBreakoutBegin: Signal<BreakoutRoom>;
    onBreakoutRefer: Signal<BreakoutReferDetails>;
    onFecc: Signal<FeccEvent>;
}

export type InfinitySignalsOptional = Pick<
    Partial<InfinitySignals>,
    'onPinRequired' | 'onParticipants' | 'onError'
>;
export type InfinitySignalsRequired = Pick<
    InfinitySignals,
    'onConnected' | 'onAnswer' | 'onPresentationAnswer' | 'onDisconnected'
>;

export interface MainStatsSignals {
    audioIn: StatsSignals;
    audioOut: StatsSignals;
    videoIn: StatsSignals;
    videoOut: StatsSignals;
    presoVideoIn: StatsSignals;
    presoVideoOut: StatsSignals;
    combinedRtcStatsSignal: Signal<
        [
            NormalizedRTCStats | undefined,
            NormalizedRTCStats | undefined,
            NormalizedRTCStats | undefined,
            NormalizedRTCStats | undefined,
            NormalizedRTCStats | undefined,
            NormalizedRTCStats | undefined,
        ]
    >;
    combinedCallQualitySignal: Signal<
        [Quality | undefined, Quality | undefined]
    >;
    combinedCallQualityStatsSignal: Signal<
        [CallQualityStats | undefined, CallQualityStats | undefined]
    >;
}

export interface Stats {
    inbound: {
        audio?: InboundAudioMetrics;
        video?: InboundVideoMetrics;
        preso?: InboundVideoMetrics;
    };
    outbound: {
        audio?: OutboundAudioMetrics;
        video?: OutboundVideoMetrics;
        preso?: OutboundVideoMetrics;
    };
}
export interface CallSignals {
    onRemoteStream: Signal<MediaStream>;
    onRemotePresentationStream: Signal<MediaStream>;
    onCallConnected: Signal<undefined>;
    onPresentationConnectionChange: Signal<PresoConnectionChangeEvent>;
    onRtcStats: Signal<Stats>;
    onCallQualityStats: Signal<{
        inbound: {audio: AudioQualityStats};
        outbound: {audio: AudioQualityStats};
    }>;
    onCallQuality: Signal<[Quality, Quality]>;
    onSecureCheckCode: Signal<string>;
    onReconnecting: Signal<undefined>;
    onReconnected: Signal<undefined>;
}

export type InfinityCallSignalsOptional = Pick<
    Partial<CallSignals>,
    'onCallConnected'
>;
export type InfinityCallRequired = Pick<
    CallSignals,
    | 'onRemoteStream'
    | 'onRemotePresentationStream'
    | 'onPresentationConnectionChange'
    | 'onRtcStats'
    | 'onCallQualityStats'
    | 'onCallQuality'
>;
export interface SplashScreen {
    text: string;
    background: string;
    screenKey: keyof ThemeSchema | 'custom';
    displayDuration: number;
}
export interface EventSignals {
    onConnected: Signal<undefined>;
    onError: Signal<undefined>;
    onPresentationStart: Signal<NormalizedPresentationEvent>;
    onPresentationStop: Signal<undefined>;
    onPresentationEnded: Signal<undefined>;
    onPresentationFrame: Signal<string>;
    onParticipantCreate: Signal<Participant>;
    onParticipantUpdate: Signal<Participant>;
    onParticipantDelete: Signal<string>;
    onMessage: Signal<MessageEvent>;
    onConferenceUpdate: Signal<ConferenceStatus>;
    onStageUpdate: Signal<StageEvent[]>;
    onLayoutUpdate: Signal<LayoutEvent>;
    onCallDisconnected: Signal<CallDisconnectedEvent>;
    onDisconnect: Signal<DisconnectEvent>;
    onParticipantSyncBegin: Signal<undefined>;
    onParticipantSyncEnd: Signal<undefined>;
    onRefer: Signal<{
        alias: string;
        token: string;
        breakout_name?: string;
        target?: 'main' | 'breakout' | 'conference';
    }>;
    onCancelRefer: Signal<Record<string, string>>;
    onHold: Signal<boolean>;
    onFecc: Signal<FeccEvent>;
    onRefreshToken: Signal<undefined>;
    onLiveCaptions: Signal<LiveCaptionsEvent>;
    onPeerDisconnect: Signal<undefined>;
    onNewOffer: Signal<{sdp: string}>;
    onUpdateSdp: Signal<{sdp: string}>;
    onNewCandidate: Signal<IceCandidate>;
    onSplashScreen: Signal<{
        screen_key: keyof ThemeSchema;
        display_duration: number;
    } | null>;
    onBreakoutBegin: Signal<BreakoutRoom>;
    // TODO: This basically syncs all the events from breakout rooms so essentially you can get any og the above in here
    // Find a better way to share the code
    onBreakoutEvent: Signal<
        {
            breakout_uuid: string;
        } & (
            | {event: 'participant_create'; data: RTCParticipantEvent}
            | {event: 'participant_update'; data: RTCParticipantEvent}
            | {event: 'participant_delete'; data: RTCParticipantEvent}
            | {event: 'participant_sync_begin'}
            | {event: 'participant_sync_end'}
            | {event: 'message_received'; data: MessageEvent}
            | {event: 'conference_update'; data: ConferenceStateEvent}
            | {event: 'presentation_stop'}
            | {event: 'presentation_start'; data: PresentationEvent}
            | {event: 'live_captions'; data: LiveCaptionsEvent}
        )
    >;
    onBreakoutEnd: Signal<BreakoutRoom>;
    onBreakoutRefer: Signal<{
        breakout_uuid: string;
        breakout_name: string;
        requester_uuid: string;
    }>;
}

export type InfinityEventSignalsOptional = Pick<
    Partial<EventSignals>,
    'onHold' | 'onRefer' | 'onFecc' | 'onRefreshToken'
>;

export type SignalName =
    | keyof CallSignals
    | keyof EventSignals
    | keyof InfinitySignals;

export interface IceCandidate {
    candidate: string;
    mid: string;
    ufrag?: string;
}

export type ClientSideErrorMessage =
    | 'Could not reconnect to the meeting'
    | 'Could not execute critical network action'
    | 'Could not find ICE candidates'
    | 'WebRTC connection closed'
    | 'WebRTC connection failed';

export type ExtendedInfinityErrorMessage =
    | InfinityErrorMessage
    | ClientSideErrorMessage;

export interface DisconnectEvent {
    reason: InfinityErrorMessage;
}

export interface MessageEvent extends MessageBody {
    origin: string;
    uuid: string;
    direct: boolean;
}

export interface StageEvent {
    participant_uuid: string;
    stage_index: number;
    vad: number;
}

export interface LayoutEvent {
    requested_layout: {
        primary_screen: {
            chair_layout: Layout;
            guest_layout: Layout;
        };
    };
    view: Layout;
    participants: string[];
    overlay_text_enabled?: boolean;
}

export interface PresentationEvent {
    status?: 'start' | 'stop' | 'newframe';
    presenter_name?: string;
    presenter_uri?: string;
    presenter_uuid?: string;
    lastEventId?: string;
}

export interface NormalizedPresentationEvent {
    presenterDisplayName: string;
    presenterName?: string;
    presenterUri?: string;
    presenterUuid?: string;
}

export interface LiveCaptionsEvent {
    data: string;
    is_final: boolean;
}

export interface CallDisconnectedEvent {
    call_uuid: string;
    reason?: DisconnectEvent;
}

export type EventsSourceType =
    | DisconnectEvent
    | MessageEvent
    | ConferenceStateEvent
    | PresentationEvent
    | RTCParticipantEvent
    | FeccEvent;

export enum CallType {
    audio = 'audio',
    video = 'video',
    api = 'api',
}
export interface Participant {
    callType: CallType;
    canControl: boolean;
    canChangeLayout: boolean;
    canDisconnect: boolean;
    canMute: boolean;
    canTransfer: boolean;
    canFecc: boolean;
    canRaiseHand: boolean;
    canSpotlight: boolean;
    displayName?: string;
    overlayText: string;
    handRaisedTime: number;
    identity: string;
    isCameraMuted: boolean;
    isConjoined: boolean;
    isConnecting: boolean;
    isEndpoint?: boolean;
    isExternal: boolean;
    isIdpAuthenticated?: boolean;
    isGateway: boolean;
    isHost: boolean;
    isMainVideoDroppedOut: boolean;
    isClientMuted: boolean;
    isMuted: boolean;
    isPresenting: boolean;
    isSpotlight: boolean;
    isStreaming: boolean;
    isTransferring: boolean;
    isVideoSilent: boolean;
    isWaiting: boolean;
    needsPresentationInMix: boolean;
    protocol: RTCParticipantEvent['protocol'];
    raisedHand: boolean;
    role: RTCParticipantEvent['role'];
    rxPresentation: boolean;
    serviceType: RTCParticipantEvent['service_type'];
    spotlightOrder: number;
    startAt: Date;
    startTime: number;
    uri: string;
    uuid: string;
    vendor: RTCParticipantEvent['vendor'];
    rawData: RTCParticipantEvent;
}

export interface Message {
    at: Date;
    id: string;
    message: string;
    displayName: string;
    userId: string;
    direct: boolean;
}

export interface ApplicationMessage extends Omit<Message, 'message'> {
    message: Record<string, unknown>;
}

export interface Stage {
    userId: string;
    stageIndex: number;
    vad: number;
}

export interface ConferenceStatus {
    locked: boolean;
    guestsMuted: boolean;
    allMuted: boolean;
    started: boolean;
    liveCaptionsAvailable: boolean;
    breakoutRooms: boolean;
    directMedia: boolean;
    presentationAllowed: boolean;
    classification?: {
        current: number;
        levels: Record<number, string>;
    };
    breakout: boolean;
    breakoutName?: string;
    breakoutDescription?: string;
    endTime?: number;
    endAction?: 'transfer' | 'disconnect';
    guestsAllowedToLeave?: boolean;
    breakoutbuzz?: {
        time: number;
        value: boolean;
    };
    guestsCanUnmute?: boolean;
    rawData: ConferenceStateEvent;
}

export interface TransferDetails {
    alias: string;
    token: string;
    callTag?: string;
    breakoutName?: string;
    target?: 'main' | 'breakout' | 'conference' | 'direct' | 'transcoded';
}

export interface BreakoutReferDetails {
    breakoutUuid: string;
    breakoutName: string;
    requesterUuid: string;
}

/* eslint-disable @typescript-eslint/prefer-literal-enum-member --- We want this */
/**
 * Client Call Type for client to make call w.r.t. audio, video and direction
 */
export enum ClientCallType {
    /**
     * Client to join as a presentation and control-only participant, i.e. the
     * user will not send or receive any audio or video. They can still access
     * the conference controls and send and receive presentations.
     */
    None,
    /**
     * Join as an sending-audio-only participant, not receiving
     * any audio, also will not send or receive video.
     */
    AudioSendOnly = 1 << 1,
    /**
     * Join as an receiving-audio-only participant, not sending
     * any audio, also will not send or receive video.
     */
    AudioRecvOnly = 1 << 2,
    /**
     * Join as an sending-video-only participant, not receiving
     * any video, also will not send or receive audio.
     */
    VideoSendOnly = 1 << 3,
    /**
     * Join as an receiving-video-only participant, not sending
     * any video, also will not send or receive audio.
     */
    VideoRecvOnly = 1 << 4,
    /**
     * Join as an audio-only participant, i.e. the user will send and receive
     * audio but will not send or receive video.
     */
    Audio = AudioSendOnly | AudioRecvOnly,
    /**
     * Join as an video-only participant, i.e. the user will send and receive
     * video but will not send or receive audio.
     */
    Video = VideoSendOnly | VideoRecvOnly,
    /**
     * Join as an sending-audio-video-only participant, i.e. the user will only
     * send audio and video but will not receive audio or video.
     */
    AudioSendVideoSendOnly = AudioSendOnly | VideoSendOnly,
    /**
     * Join as an receiving-audio-video-only participant, i.e. the user will only
     * receive audio and video but will not send audio or video.
     */
    AudioRecvVideoRecvOnly = AudioRecvOnly | VideoRecvOnly,
    /**
     * Join as a full (send and receive) audio and video participant
     */
    AudioVideo = Audio | Video,
}
/* eslint-enable @typescript-eslint/prefer-literal-enum-member --- We want this */

export interface ConferenceFeatureFlags {
    allow1080p: boolean;
    allowVP9: boolean;
    callType: 'video' | 'audio' | 'video-only';
    chatEnabled: boolean;
    guestsCanPresent: boolean;
    isDirectMedia: boolean;
    breakoutRoomsEnabled: boolean;
}

export type CallUuid = string | undefined;

export interface PresoState {
    send: RTCPeerConnectionState;
    recv: RTCPeerConnectionState;
}

export interface StatsCollectors {
    inbound: {
        audio?: StatsCollector;
        video?: StatsCollector;
        preso?: StatsCollector;
    };
    outbound: {
        audio?: StatsCollector;
        video?: StatsCollector;
        preso?: StatsCollector;
    };
}

export type DataChannelEvent =
    | {
          type: 'message';
          body: Omit<MessageEvent, 'direct'>;
      }
    | {
          type: 'fecc';
          body: FeccEvent;
      };

export type DisconnectReason =
    | 'Browser closed'
    | 'User initiated disconnect'
    | 'Transfer'
    | 'DirectMediaTransfer';

export type SSOdMessage =
    | {result: 'forbidden'; code: 403}
    | {result: 'success'; token: string}
    | {result: 'service unavailable'; code: 503};
