import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ExtendedTooltipPosition, ModalProps} from '@pexip/components';
import {
    Box,
    Button,
    CustomTooltip,
    Modal,
    ModalCloseButton as CloseButton,
} from '@pexip/components';

import {getUserAgentDetails} from '../../utils/userAgentDetails';

import styles from './InputControlTooltip.module.scss';

const ToolTipModal: React.FC<
    {
        content: React.ReactNode;
        children: React.ReactNode;
        visible: boolean;
        onClose?: () => void;
    } & Omit<ModalProps, 'isOpen' | 'content'>
> = ({content, children, onClose, visible, ...props}) => {
    const {t} = useTranslation();

    return (
        <>
            <Modal
                isOpen={visible}
                closeOnOutsideClick={true}
                withCloseButton={false}
                onClose={onClose}
                {...props}
            >
                {content}
                <Button
                    size="large"
                    className="mt-3"
                    modifier="fullWidth"
                    onClick={onClose}
                >
                    {t('common.ok', 'Ok')}
                </Button>
            </Modal>
            {children}
        </>
    );
};

const ToolTipBalloon: React.FC<{
    content: React.ReactNode;
    children: React.ReactNode;
    visible: boolean;
    mainWrapperClassName?: string;
    wrapperClassName?: string;
    position: ExtendedTooltipPosition;
}> = ({
    content,
    children,
    visible,
    mainWrapperClassName,
    wrapperClassName,
    position,
    ...props
}) => {
    return (
        <CustomTooltip
            controlledIsVisible={visible}
            isHoverEnabled={false}
            mainWrapperClassName={mainWrapperClassName}
            wrapperClassName={wrapperClassName}
            {...props}
            content={<Box className={styles.tooltipWrapper}>{content}</Box>}
            position={position}
        >
            {children}
        </CustomTooltip>
    );
};

export const InputControlTooltip: React.FC<{
    isTouch?: boolean;
    children: React.ReactNode;
    content: React.ReactNode;
    contentWrapperClassName?: string;
    mainWrapperClassName?: string;
    position?: ExtendedTooltipPosition;
    onClose?: () => void;
    visible: boolean;
}> = ({
    isTouch = getUserAgentDetails(navigator.userAgent).isTouch,
    children,
    content,
    contentWrapperClassName,
    mainWrapperClassName,
    position = 'topCenter',
    onClose,
    visible,
    ...props
}) => {
    return isTouch ? (
        <ToolTipModal
            content={content}
            visible={visible}
            onClose={onClose}
            {...props}
        >
            {children}
        </ToolTipModal>
    ) : (
        <ToolTipBalloon
            mainWrapperClassName={mainWrapperClassName}
            wrapperClassName={contentWrapperClassName}
            visible={visible}
            position={position}
            {...props}
            content={content}
        >
            {children}
        </ToolTipBalloon>
    );
};

export const InputControlTooltipClose: React.FC<
    React.ComponentProps<typeof CloseButton>
> = props => {
    const {t} = useTranslation();
    return (
        <CloseButton
            className={styles.close}
            aria-label={t('common.close-tooltip', 'Close tooltip')}
            {...props}
        />
    );
};
