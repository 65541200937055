import {
    mediaBreakpointDown,
    mediaBreakpointUp,
    mediaBreakpointBetween,
    mediaBreakpointOnly,
} from '../utils/breakpoints';

export const TOUCH_DEVICE = `(pointer: coarse)`;
export const PORTRAIT = '(orientation: portrait)';
export const LANDSCAPE = '(orientation: landscape)';

export const MOBILE_DEVICE = `${TOUCH_DEVICE} and ${mediaBreakpointDown('md')}`;
export const TABLET_DEVICE = `${TOUCH_DEVICE} and ${mediaBreakpointUp('md')}`;

export const XS = mediaBreakpointOnly('xs');
export const SM = mediaBreakpointOnly('sm');
export const MD = mediaBreakpointOnly('md');
export const LG = mediaBreakpointOnly('lg');
export const XL = mediaBreakpointOnly('xl');

export const SM_UP = mediaBreakpointUp('sm');
export const MD_UP = mediaBreakpointUp('md');
export const LG_UP = mediaBreakpointUp('lg');
export const XL_UP = mediaBreakpointUp('xl');

export const SM_DOWN = mediaBreakpointDown('sm');
export const MD_DOWN = mediaBreakpointDown('md');
export const LG_DOWN = mediaBreakpointDown('lg');
export const XL_DOWN = mediaBreakpointDown('xl');

export const SM_TO_LG = mediaBreakpointBetween('sm', 'lg');
export const SM_TO_XL = mediaBreakpointBetween('sm', 'xl');
export const MD_TO_XL = mediaBreakpointBetween('md', 'xl');

export const BELOW_360 = '(max-width: 359px)';
