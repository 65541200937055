import React from 'react';
import cx from 'classnames';

import type {
    ButtonModifiersValues,
    ExtendedSizeModifier,
} from '../../../types/sizes';
import type {DropContentPosition, DropContentType} from '../DropContent/types';
import type {DropContentProps} from '../DropContent/DropContent';
import {DropContent} from '../DropContent/DropContent';
import {Button} from '../../elements/Button/Button';
import {Icon, IconTypes} from '../../elements/Icon/Icon';
import type {ButtonVariant} from '../../../types/variants';

import styles from './KebabMenuButton.module.scss';

export const KebabMenuButton: React.FC<
    React.PropsWithChildren<
        Omit<DropContentProps, 'content'> & {
            className?: string;
            direction?: 'horizontal' | 'vertical';
            isDisabled?: boolean;
            label?: string;
            menuComponent: DropContentType;
            menuPosition?: DropContentPosition;
            buttonVariant?: ButtonVariant;
            buttonSize?: ExtendedSizeModifier;
            buttonModifier?: ButtonModifiersValues;
            reactiveButtonStyles?: boolean;
        }
    >
> = ({
    className,
    direction = 'vertical',
    isDisabled,
    label = 'Toggle the menu',
    menuComponent,
    menuPosition = 'bottomRight',
    buttonVariant = 'translucent',
    buttonSize,
    buttonModifier,
    reactiveButtonStyles = true,
    ...props
}) => {
    return (
        <DropContent
            buttonLabel={label}
            className={styles.wrapper}
            content={menuComponent}
            data-testid="kebab-menu"
            interactiveElement={(props, isContentVisible) => (
                <Button
                    {...props}
                    className={cx(
                        props.className,
                        styles.button,
                        {
                            [styles.reactiveStyles]: reactiveButtonStyles,
                            [styles.active]: isContentVisible,
                        },
                        className,
                    )}
                    isDisabled={isDisabled}
                    variant={buttonVariant}
                    size={buttonSize}
                    modifier={buttonModifier}
                >
                    <Icon
                        source={
                            direction === 'vertical'
                                ? IconTypes.IconMoreVertical
                                : IconTypes.IconMoreHorizontal
                        }
                        className={cx({[styles.icon]: reactiveButtonStyles})}
                        data-testid={`kebab-menu-icon-${direction}`}
                    />
                </Button>
            )}
            isDisabled={isDisabled}
            position={menuPosition}
            {...props}
        />
    );
};
