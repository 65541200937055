import React from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {
    CoreHeader,
    Group,
    Text,
    PexipLogo,
    FontVariant,
    Divider,
    TextHeading,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './Header.module.scss';

type TitlesProps = React.ComponentProps<'div'> & {
    text?: string;
};

const HeaderLogo: React.FC<React.ComponentProps<'span'>> = ({
    className,
    onClick,
    ...props
}) => {
    const {t} = useTranslation();
    return (
        <Text
            className={cx(className, {
                [styles.interactive]: Boolean(onClick),
            })}
            data-testid={TestId.HeaderLogo}
            fontVariant={FontVariant.Body}
            onClick={onClick}
            {...props}
        >
            <Trans t={t} i18nKey="common.a-pexip-meeting">
                a
                <PexipLogo
                    aria-label={t('common.label-pexip-logo', 'Pexip logo')}
                    size="medium"
                    className="ml-1 mr-1"
                />
                meeting
            </Trans>
        </Text>
    );
};

const Title: React.FC<TitlesProps> = ({className, text, ...props}) => (
    <TextHeading
        htmlTag="h1"
        fontVariant={FontVariant.H5}
        className={cx(styles.title, className)}
        {...props}
    >
        {text}
    </TextHeading>
);

const SubTitle: React.FC<TitlesProps> = ({text, className, ...props}) => (
    <Text
        fontVariant={FontVariant.Body}
        className={cx(styles.subTitle, className)}
        {...props}
    >
        / {text}
    </Text>
);

const HeaderEnhancerStart: React.FC<{
    enhancerStartActions?: React.ReactElement;
    onClick?: () => void;
    subTitle?: string;
    title: string;
}> = ({enhancerStartActions, onClick, subTitle, title}) => (
    <div className={styles.enhancerStart}>
        <div className={styles.logoWrapper}>
            <HeaderLogo
                className={cx(styles.logoText, 'ml-4')}
                onClick={onClick}
            />
            <Divider direction="vertical" className="ml-4" />
        </div>
        <Group spacing="compact" className={styles.titleSection}>
            {title && (
                <Title
                    text={title}
                    data-testid={TestId.HeaderTitle}
                    className="hideOnMobile"
                />
            )}
            {subTitle && (
                <SubTitle
                    text={subTitle}
                    data-testid={TestId.HeaderSubTitle}
                    className="hideOnMobile"
                />
            )}
            {enhancerStartActions}
        </Group>
    </div>
);

export const Header: React.FC<{
    enhancerEndActions?: React.ReactElement;
    enhancerStartActions?: React.ReactElement;
    onClick?: () => void;
    subTitle?: string;
    title: string;
    userAvatar?: React.ReactElement;
}> = ({
    enhancerEndActions,
    enhancerStartActions,
    onClick,
    subTitle,
    title,
    userAvatar,
    ...props
}) => (
    <CoreHeader
        enhancerStart={
            <HeaderEnhancerStart
                enhancerStartActions={enhancerStartActions}
                onClick={onClick}
                subTitle={subTitle}
                title={title}
            />
        }
        enhancerEnd={
            <Group spacing="small" className={styles.enhancerEnd}>
                {enhancerEndActions}
                {userAvatar}
            </Group>
        }
        {...props}
    />
);
