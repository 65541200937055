import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '../../../types/sizes';
import type {ColorScheme, ShadowModifier} from '../../../types/variants';
import {sizeToPadding} from '../../../utils/helpers';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import {BoxHeader} from '../BoxHeader/BoxHeader';
import type {BoxFooterProps} from '../BoxFooter/BoxFooter';
import {BoxFooter} from '../BoxFooter/BoxFooter';

import styles from './Box.module.scss';

export const Box = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        hasBackground?: boolean;
        headerContent?: React.ReactElement;
        footerContent?: React.ReactElement;
        bgColor?: string;
        isRounded?: boolean;
        isWithBorder?: boolean;
        isWithShadow?: boolean;
        shadowModifier?: ShadowModifier;
        padding?: ExtendedSizeModifier;
        headerPadding?: ExtendedSizeModifier;
        footerPadding?: ExtendedSizeModifier;
        footerTopPadding?: ExtendedSizeModifier;
        footerRightPadding?: ExtendedSizeModifier;
        footerBottomPadding?: ExtendedSizeModifier;
        footerLeftPadding?: ExtendedSizeModifier;
        colorScheme?: ColorScheme;
        scrollable?: boolean;
    } & Pick<BoxFooterProps, 'hasFooterShadow'>
>(
    (
        {
            hasBackground = true,
            headerContent,
            footerContent,
            bgColor,
            children,
            className,
            isRounded = true,
            isWithBorder = false,
            isWithShadow = true,
            shadowModifier = 'dark',
            padding = 'none',
            headerPadding = padding,
            footerPadding = padding,
            footerTopPadding = footerPadding,
            footerRightPadding = footerPadding,
            footerBottomPadding = footerPadding,
            footerLeftPadding = footerPadding,
            hasFooterShadow,
            scrollable,
            style,
            colorScheme,
            ...props
        },
        ref,
    ) => {
        const hasHeaderOrFooter = !!headerContent || !!footerContent;
        return (
            <ThemeConsumer>
                {({colorScheme: defaultColorScheme}) => (
                    <div
                        className={cx(
                            hasBackground &&
                                styles[colorScheme ?? defaultColorScheme],
                            {
                                [styles.shadowDark]:
                                    isWithShadow && shadowModifier === 'dark',
                                [styles.shadowLight]:
                                    isWithShadow && shadowModifier === 'light',
                                [styles.rounded]: isRounded,
                                [styles.border]: isWithBorder,
                                [styles[sizeToPadding(padding)]]:
                                    !hasHeaderOrFooter,
                                [styles.hasHeaderOrFooter]: hasHeaderOrFooter,
                            },
                            styles.box,
                            className,
                        )}
                        style={
                            hasBackground && bgColor
                                ? {
                                      ...style,
                                      backgroundColor: bgColor,
                                  }
                                : style
                        }
                        ref={ref}
                        {...props}
                    >
                        {headerContent && (
                            <BoxHeader padding={headerPadding}>
                                {headerContent}
                            </BoxHeader>
                        )}
                        {children && hasHeaderOrFooter ? (
                            <div
                                className={cx(
                                    styles[sizeToPadding(padding)],
                                    styles.mainContent,
                                    {
                                        'pb-0':
                                            footerContent && padding !== 'none',
                                        [styles.scrollable]: scrollable,
                                    },
                                )}
                            >
                                {children}
                            </div>
                        ) : (
                            children
                        )}
                        {footerContent && (
                            <BoxFooter
                                padding={footerPadding}
                                topPadding={footerTopPadding}
                                rightPadding={footerRightPadding}
                                bottomPadding={footerBottomPadding}
                                leftPadding={footerLeftPadding}
                                hasFooterShadow={hasFooterShadow}
                            >
                                {footerContent}
                            </BoxFooter>
                        )}
                    </div>
                )}
            </ThemeConsumer>
        );
    },
);

Box.displayName = 'Box';

export type BoxProps = React.ComponentProps<typeof Box>;
