import {useRef} from 'react';
import {useOverlayTriggerState} from '@react-stately/overlays';
import {useModalOverlay} from '@react-aria/overlays';

export const useModal = (isOpen: boolean) => {
    const ref = useRef<HTMLDivElement>(null);
    const state = useOverlayTriggerState({isOpen});
    const {modalProps} = useModalOverlay({}, state, ref);
    return {ref, modalProps};
};
