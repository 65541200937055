import React from 'react';
import cx from 'classnames';

import {TextHeading, Text, FontVariant} from '@pexip/components';

import styles from './BreakoutRoomsNameAndTimeLeft.module.scss';

export const BreakoutRoomsNameAndTimeLeftView: React.FC<{
    roomName: string;
    timeLeft: string;
    isSmallText?: boolean;
    isTruncated?: boolean;
}> = ({roomName, timeLeft, isSmallText = false, isTruncated = false}) => (
    <div className={styles.container}>
        <TextHeading
            htmlTag="h5"
            fontVariant={isSmallText ? FontVariant.Body : FontVariant.H5}
            className={cx(styles.roomName, {[styles.truncated]: isTruncated})}
            isTruncated
        >
            {roomName}
        </TextHeading>
        {timeLeft && (
            <Text
                className={cx('ml-2', styles.timeLeft, {
                    [styles.smallText]: isSmallText,
                })}
                fontVariant={isSmallText ? FontVariant.Body : FontVariant.H6}
            >
                {`/ ${timeLeft}`}
            </Text>
        )}
    </div>
);

export type BreakoutRoomsNameAndTimeLeftViewProps = React.ComponentProps<
    typeof BreakoutRoomsNameAndTimeLeftView
>;
