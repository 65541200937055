import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '@pexip/components';
import {
    FontVariant,
    TextHeading,
    Button,
    Icon,
    IconTypes,
    sizeToPadding,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './PanelHeader.module.scss';

export const PanelHeader: React.FC<{
    padding?: ExtendedSizeModifier;
    topPadding?: ExtendedSizeModifier;
    rightPadding?: ExtendedSizeModifier;
    bottomPadding?: ExtendedSizeModifier;
    leftPadding?: ExtendedSizeModifier;
    endContent?: React.ReactElement;
    backButtonAriaLabel?: string;
    closeButtonAriaLabel?: string;
    closeButtonTestId?: string;
    title?: string;
    onBackClick?: () => void;
    onCloseClick?: () => void;
}> = ({
    padding,
    topPadding = padding,
    rightPadding = padding,
    bottomPadding = padding,
    leftPadding = padding,
    endContent,
    backButtonAriaLabel = 'Go back',
    closeButtonAriaLabel = 'Close panel',
    closeButtonTestId = '',
    title,
    onCloseClick,
    onBackClick,
}) => (
    <div
        className={cx(
            styles.container,
            topPadding && styles[`${sizeToPadding(topPadding)}Top`],
            rightPadding && styles[`${sizeToPadding(rightPadding)}Right`],
            bottomPadding && styles[`${sizeToPadding(bottomPadding)}Bottom`],
            leftPadding && styles[`${sizeToPadding(leftPadding)}Left`],
        )}
    >
        <div className={styles.startContent}>
            {onBackClick && (
                <Button
                    aria-label={backButtonAriaLabel}
                    onClick={onBackClick}
                    className="mr-1"
                    size="none"
                    variant="translucent"
                    data-testid={TestId.BreakoutSetupPanelBackBtn}
                >
                    <Icon
                        size="small"
                        source={IconTypes.IconArrowLeftRegular}
                    ></Icon>
                </Button>
            )}
            {title && (
                <TextHeading htmlTag="h5" fontVariant={FontVariant.H5}>
                    {title}
                </TextHeading>
            )}
        </div>
        {(endContent ?? onCloseClick) && (
            <div className={styles.endContent}>
                {endContent}
                {onCloseClick && (
                    <Button
                        aria-label={closeButtonAriaLabel}
                        onClick={onCloseClick}
                        size="compact"
                        modifier="square"
                        variant="translucent"
                        data-testid={closeButtonTestId}
                    >
                        <Icon source={IconTypes.IconClose}></Icon>
                    </Button>
                )}
            </div>
        )}
    </div>
);

export type PanelHeaderProps = React.ComponentProps<typeof PanelHeader>;
