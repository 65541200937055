import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {Divider, ThemeProvider, Button} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './ParticipantsPanelFooter.module.scss';

export const ParticipantsPanelFooter: React.FC<{
    addParticipant: () => void;
}> = ({addParticipant}) => {
    const {t} = useTranslation();

    return (
        <ThemeProvider colorScheme="light">
            <div className={styles.wrapper}>
                <Divider />
                <Button
                    className={cx('p-3', 'text-center')}
                    size="large"
                    modifier="fullWidth"
                    title={t('add-participant.submit', 'Add participant')}
                    variant="text"
                    onClick={addParticipant}
                    data-testid={TestId.UserMenuAddParticipant}
                >
                    {t('add-participant.submit', 'Add participant')}
                </Button>
            </div>
        </ThemeProvider>
    );
};
