import React from 'react';
import cx from 'classnames';

import {
    Box,
    FontVariant,
    Text,
    URLToLink,
    withColorScheme,
} from '@pexip/components';

import type {ChatMessage} from '../../types';
import {TestId} from '../../../test/testIds';

import styles from './ChatMessage.module.scss';

const BoxLight = withColorScheme(Box, 'light');

export const ChatMessageView: React.FC<{message: ChatMessage}> = ({
    message,
}) => (
    <BoxLight
        isWithShadow={false}
        className={cx(
            styles.box,
            {[styles.messagePending]: message.pending},
            'p-3',
        )}
        key={message.id}
        data-testid={TestId.ChatMessage}
    >
        <div className={styles.messageTitle}>
            <Text
                fontVariant={FontVariant.BodyBold}
                className={styles.messageName}
                data-testid={TestId.ChatMessageName}
                translate="no"
            >
                {message.displayName}
            </Text>
            <Text
                fontVariant={FontVariant.Small}
                className={styles.messageDate}
            >
                {message.timestamp}
            </Text>
        </div>
        <Text
            htmlTag="p"
            className={styles.messageText}
            data-testid={TestId.ChatMessageText}
        >
            <URLToLink
                text={message.message}
                data-testid={TestId.ChatMessageLink}
            />
        </Text>
    </BoxLight>
);
