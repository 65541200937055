import type {MouseEvent} from 'react';
import React, {useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Box,
    Button,
    Color,
    FontVariant,
    Text,
    announce,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './LinkCopy.module.scss';

export const LinkCopy: React.FC<{
    className?: string;
    expiryHours?: number;
    link: string;
    isCopied?: boolean;
    onClick: (e: MouseEvent) => void;
}> = ({className, expiryHours, link, isCopied = false, onClick}) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (isCopied) {
            announce(t('invite.copied', 'Copied'), 'assertive');
        }
    }, [isCopied, t]);

    return (
        <>
            <Box
                isWithBorder
                isWithShadow={false}
                colorScheme="light"
                hasBackground
                bgColor={Color.DeepBlue10}
                style={isCopied ? {borderColor: Color.SignalGreen} : {}}
                className={cx(className, styles.linkBox)}
            >
                <Text
                    colorScheme="light"
                    className={styles.linkText}
                    data-testid={TestId.MeetingLinkText}
                >
                    {link}
                </Text>
                <Button
                    variant={isCopied ? 'success' : 'primary'}
                    colorScheme="light"
                    className={styles.linkCopyButton}
                    noEnhancerPadding
                    onClick={onClick}
                    aria-label={t(
                        'invite.copy-to-clipboard',
                        'Copy a link to clipboard',
                    )}
                    data-testid={TestId.ButtonCopyMeetingLink}
                >
                    {isCopied
                        ? t('invite.copied', 'Copied')
                        : t('invite.copy', 'Copy')}
                </Button>
            </Box>
            {expiryHours && (
                <div className="mt-2">
                    <Text fontVariant={FontVariant.Small}>
                        <Trans t={t} i18nKey="invite.expiry">
                            Your invite link expires in {{expiryHours}} hours
                        </Trans>
                    </Text>
                </div>
            )}
        </>
    );
};
