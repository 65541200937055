import React from 'react';
import cx from 'classnames';

import type {BaseSizeModifier} from '../../../types/sizes';
import type {ColorScheme, ShadowModifier} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import {Box} from '../../elements/Box/Box';
import {Text} from '../../elements/Text/Text';
import {Divider} from '../../elements/Divider/Divider';
import {FontVariant} from '../../../../design-tokens/constants';

import styles from './StatCard.module.scss';

export enum StatCardVariant {
    Danger = 'danger',
    Success = 'success',
    Warning = 'warning',
}

export interface StatCardRowData {
    caption?: string | React.ReactElement;
    captionSize?: BaseSizeModifier;
    captionTitle?: string;
    figure?: string | number | React.ReactElement;
    figureSize?: BaseSizeModifier;
    figureTitle?: string;
    icon?: React.ReactElement;
    iconColor?: string;
    isActive?: boolean;
}

export const StatCardBox: React.FC<
    React.PropsWithChildren<
        {
            className?: string;
            colorScheme?: ColorScheme;
            shadowModifier?: ShadowModifier;
            variant?: StatCardVariant;
        } & Partial<React.ComponentProps<typeof Box>>
    >
> = ({
    children,
    className,
    colorScheme,
    shadowModifier = 'light',
    variant,
    ...boxProps
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <Box
                className={cx(
                    className,
                    styles.box,
                    styles[colorScheme ?? defaultColorScheme],
                    variant && styles[variant],
                )}
                padding="none"
                shadowModifier={shadowModifier}
                {...boxProps}
            >
                {children}
            </Box>
        )}
    </ThemeConsumer>
);

export const StatCardRow: React.FC<{
    className?: string;
    position?: 'top' | 'bottom';
    rowData: StatCardRowData;
}> = ({className, position, rowData}) => {
    const {
        caption,
        captionSize = 'medium',
        captionTitle,
        figure,
        figureSize = 'medium',
        figureTitle,
        icon,
        iconColor,
        isActive = true,
    } = rowData;

    const iconProps = iconColor
        ? {style: {color: iconColor}}
        : {className: styles.icon};

    const captionString = typeof caption === 'string' ? caption : '';
    const figureString = typeof figure === 'string' ? figure : '';
    const captionTitleText = `${captionTitle ?? captionString} `;
    const figureTitleText = `${figureTitle ?? figureString} `;

    return (
        <div
            className={cx(
                styles.row,
                {
                    [styles.rowBottom]: position === 'bottom',
                    [styles.rowTop]: position === 'top',
                    [styles.subtle]: figureSize === 'small',
                },
                className,
            )}
        >
            {icon && <div {...iconProps}>{icon}</div>}

            <figure
                className={cx(styles.figure, {
                    [styles.inactive]: !isActive,
                })}
            >
                <Text
                    className={cx(styles.figureText, styles[figureSize])}
                    htmlTag="span"
                    variant="inherit"
                    fontVariant={
                        figureSize === 'small'
                            ? FontVariant.BodyBold
                            : FontVariant.H3
                    }
                    title={`${captionTitleText}${figureTitleText}`}
                >
                    {figure}
                </Text>

                <figcaption className={cx(styles.caption)}>
                    {typeof caption !== 'string' ? (
                        caption
                    ) : (
                        <Text
                            className={cx(
                                styles.figureCaption,
                                styles[captionSize],
                            )}
                            variant="inherit"
                        >
                            {caption}
                        </Text>
                    )}
                </figcaption>
            </figure>
        </div>
    );
};

export const StatCard: React.FC<{
    className?: string;
    colorScheme?: ColorScheme;
    hasDivider?: boolean;
    rowBottom?: StatCardRowData;
    rowTop: StatCardRowData;
    shadowModifier?: ShadowModifier;
    variant?: StatCardVariant;
}> = ({
    className,
    colorScheme,
    hasDivider,
    rowBottom,
    rowTop,
    shadowModifier,
    variant,
}) => (
    <StatCardBox
        className={cx(className, styles.wrap, {[styles.flexGrow]: !!rowBottom})}
        colorScheme={colorScheme}
        shadowModifier={shadowModifier}
        variant={variant}
    >
        <StatCardRow
            position={rowBottom ? 'top' : undefined}
            rowData={rowTop}
        />

        {hasDivider && rowBottom && (
            <div className={styles.dividerWrap}>
                <Divider className={styles.divider} data-testid="divider" />
            </div>
        )}

        {rowBottom && <StatCardRow position="bottom" rowData={rowBottom} />}
    </StatCardBox>
);
