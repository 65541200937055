import React from 'react';
import cx from 'classnames';

import {ThemeProvider} from '../../../themes/ThemeContext';
import type {ColorScheme} from '../../../types/variants';
import {SvgImage} from '../SvgImage/SvgImage';
import type {ExtendedTooltipPosition} from '../../../types/propTypes';
import {useAriaTooltip} from '../../../hooks/useAriaTooltip';

import type {ControlledTooltip} from './useTooltip';
import {useTooltip} from './useTooltip';
import ArrowSvg from './arrow.svg';

import styles from './Tooltip.module.scss';

export const CustomTooltip: React.FC<
    Omit<React.ComponentProps<'div'>, 'content'> &
        ControlledTooltip & {
            position?: ExtendedTooltipPosition;
            testIdInner?: string;
            colorScheme?: ColorScheme;
            content: React.ReactNode;
            isHoverEnabled?: boolean;
            isArrowShown?: boolean;
            mainWrapperClassName?: string;
            wrapperClassName?: string;
        }
> = ({
    colorScheme = 'light',
    position = 'topRight',
    controlledIsVisible,
    controlledOnChange,
    children,
    className,
    content,
    isHoverEnabled = true,
    isArrowShown = true,
    mainWrapperClassName,
    wrapperClassName,
    ...props
}) => {
    const {isVisible, setVisible} = useTooltip({
        controlledIsVisible,
        controlledOnChange,
    });
    const isControlled = Boolean(controlledOnChange);

    const tooltipContent = (
        <ThemeProvider colorScheme={colorScheme}>
            <div
                className={cx(styles.tooltip, styles[position], className)}
                {...props}
            >
                {content}
                {isArrowShown && (
                    <div className={styles.tooltipArrow}>
                        <SvgImage
                            source={ArrowSvg}
                            className={styles.tooltipArrowIcon}
                        />
                    </div>
                )}
            </div>
        </ThemeProvider>
    );

    const {targetRef, tooltip} = useAriaTooltip({
        isOpen: isVisible,
        position,
        tooltipContent,
    });

    return (
        <div
            ref={targetRef}
            className={cx(styles.tooltipContainer, mainWrapperClassName)}
        >
            <div
                className={wrapperClassName}
                onMouseEnter={() =>
                    isHoverEnabled &&
                    setVisible({isVisible: true, isControlled})
                }
                onMouseLeave={() =>
                    isHoverEnabled &&
                    setVisible({isVisible: false, isControlled})
                }
            >
                {children}
            </div>
            {tooltip}
        </div>
    );
};

export type CustomTooltipProps = React.ComponentProps<typeof CustomTooltip>;
