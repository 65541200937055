import React, {useState} from 'react';
import cx from 'classnames';

import {useFocusFromOpened, useModal} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {useShouldPanelBeModal} from '../../hooks/useShouldPanelBeModal';

import styles from './BreakoutRoomsPanelWrapper.module.scss';

export const BreakoutRoomsPanelWrapper: React.FC<{
    children: React.ReactNode;
    className?: string;
    isShown: boolean;
    animateIn?: boolean;
    animateOut?: boolean;
}> = ({children, className, isShown, animateIn, animateOut}) => {
    const [boxElement, setBoxElement] = useState<HTMLDivElement | null>(null);
    useFocusFromOpened(boxElement, isShown);

    const shouldEnableModal = useShouldPanelBeModal(isShown);
    const {ref} = useModal(shouldEnableModal);

    return (
        <div ref={ref}>
            <div
                ref={setBoxElement}
                data-testid={TestId.BreakoutRoomsPanelWrapper}
                className={cx(
                    styles.panel,
                    styles.breakoutRoomsPanelWrapper,
                    {
                        [styles.show]: isShown,
                        [styles.animateIn]: !!animateIn,
                        [styles.animateOut]: !!animateOut,
                    },
                    className,
                )}
            >
                {children}
            </div>
        </div>
    );
};

export type BreakoutRoomsPanelWrapperProps = React.ComponentProps<
    typeof BreakoutRoomsPanelWrapper
>;
