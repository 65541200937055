import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {Button, Text, Icon, IconTypes, Tooltip} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './ChatPanelInputButtons.module.scss';

export const ChatPanelInputSendButton: React.FC<{
    disabled: boolean;
}> = ({disabled}) => {
    const {t} = useTranslation();
    const buttonLabel = t('common.send', 'Send');
    const sendButton = (
        <Button
            aria-label={buttonLabel}
            className={cx(styles.sendButton)}
            disabled={disabled}
            data-testid={TestId.ButtonChatSend}
            variant="text"
            type="submit"
        >
            <Icon source={IconTypes.IconSend} size="compact" />
        </Button>
    );

    return disabled ? (
        sendButton
    ) : (
        <Tooltip
            text={<Text variant="standard">{buttonLabel}</Text>}
            position="top"
        >
            {sendButton}
        </Tooltip>
    );
};
