import React, {useRef, useMemo} from 'react';
import {useTooltipTriggerState} from '@react-stately/tooltip';
import {useTooltipTrigger} from '@react-aria/tooltip';
import {Overlay, useOverlayPosition} from '@react-aria/overlays';

import type {TooltipPosition, ExtendedTooltipPosition} from '..';
import {mapTooltipPosition} from '../components/elements/Tooltip/Tooltip.utils';

export const useAriaTooltip = ({
    isOpen,
    tooltipContent,
    closeDelay,
    defaultOpen,
    delay,
    crossOffset,
    offset,
    position,
}: {
    isOpen?: boolean;
    tooltipContent: React.ReactNode;
    closeDelay?: number;
    crossOffset?: number;
    defaultOpen?: boolean;
    delay?: number;
    offset?: number;
    position: TooltipPosition | ExtendedTooltipPosition;
}) => {
    const state = useTooltipTriggerState({
        closeDelay,
        defaultOpen,
        delay,
        isOpen,
    });
    const targetRef = useRef(null);
    const overlayRef = useRef(null);
    const {triggerProps, tooltipProps} = useTooltipTrigger(
        {},
        state,
        targetRef,
    );

    const {overlayProps} = useOverlayPosition({
        crossOffset,
        isOpen: state.isOpen,
        offset,
        overlayRef,
        placement: mapTooltipPosition(position),
        targetRef,
    });

    const tooltip = useMemo(() => {
        return state.isOpen ? (
            <Overlay>
                <div
                    {...tooltipProps}
                    ref={overlayRef}
                    {...overlayProps}
                    role="tooltip"
                >
                    {tooltipContent}
                </div>
            </Overlay>
        ) : null;
    }, [state, tooltipProps, overlayRef, overlayProps, tooltipContent]);

    return {targetRef, triggerProps, tooltip};
};
