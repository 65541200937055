import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '../../../types/sizes';
import {sizeToPadding} from '../../../utils/helpers';

import styles from './BoxFooter.module.scss';

export const BoxFooter: React.FC<
    React.ComponentProps<'div'> & {
        padding?: ExtendedSizeModifier;
        topPadding?: ExtendedSizeModifier;
        rightPadding?: ExtendedSizeModifier;
        bottomPadding?: ExtendedSizeModifier;
        leftPadding?: ExtendedSizeModifier;
        hasFooterShadow?: boolean;
    }
> = ({
    className,
    padding = 'small',
    topPadding = padding,
    rightPadding = padding,
    bottomPadding = padding,
    leftPadding = padding,
    hasFooterShadow = false,
    children,
}) => (
    <div
        className={cx(
            className,
            styles.boxFooter,
            topPadding && styles[`${sizeToPadding(topPadding)}Top`],
            rightPadding && styles[`${sizeToPadding(rightPadding)}Right`],
            bottomPadding && styles[`${sizeToPadding(bottomPadding)}Bottom`],
            leftPadding && styles[`${sizeToPadding(leftPadding)}Left`],
            {[styles.hasShadow]: hasFooterShadow},
        )}
    >
        {children}
    </div>
);

export type BoxFooterProps = React.ComponentProps<typeof BoxFooter>;
