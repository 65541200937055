import React from 'react';

import {Tooltip} from '@pexip/components';
import type {DeniedDevices} from '@pexip/media';

import {DeviceDeniedTooltip} from '../DeviceDeniedTooltip/DeviceDeniedTooltip.view';
import {TestId} from '../../../test/testIds';
import type {BlockedBrowserPermissionsInfoType} from '../../types';
import {MuteDisabledTooltip} from '../MuteDisabledTooltip/MuteDisabledTooltip.view';

import {InputControl} from './InputControl.view';

export const InMeetingInputControl: React.FC<
    React.ComponentProps<typeof InputControl> & {
        deniedDevice?: DeniedDevices;
        learnHowToGrantAccessURL?: string;
        permissionInfoType?: BlockedBrowserPermissionsInfoType;
        shouldShowTooltip?: boolean;
        showDeniedDeviceTooltip?: boolean;
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
        tooltipText?: React.ReactNode;
        tooltipClassName?: string;
        setShowHelpVideo?: React.Dispatch<React.SetStateAction<boolean>>;
        isMuteDisabled?: boolean;
    }
> = ({
    deniedDevice,
    learnHowToGrantAccessURL,
    permissionInfoType,
    shouldShowTooltip = true,
    showDeniedDeviceTooltip = false,
    tooltipPosition = 'top',
    tooltipText,
    tooltipClassName,
    variant = 'translucent',
    setShowHelpVideo,
    size = 'large',
    isMuteDisabled,
    ...props
}) => {
    const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);

    if (isMuteDisabled) {
        return (
            <MuteDisabledTooltip
                onClose={() => setIsTooltipVisible(!isTooltipVisible)}
                visible={isTooltipVisible}
            >
                <InputControl
                    variant={variant}
                    size={size}
                    {...props}
                    onClick={() => setIsTooltipVisible(!isTooltipVisible)}
                />
            </MuteDisabledTooltip>
        );
    }

    if (
        deniedDevice &&
        shouldShowTooltip &&
        learnHowToGrantAccessURL &&
        setShowHelpVideo
    ) {
        return (
            <DeviceDeniedTooltip
                data-testid={TestId.TooltipDeniedDevice}
                deniedDevice={deniedDevice}
                learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                permissionInfoType={permissionInfoType}
                visible={showDeniedDeviceTooltip}
                setShowHelpVideo={setShowHelpVideo}
            >
                <InputControl variant={variant} size={size} {...props} />
            </DeviceDeniedTooltip>
        );
    }

    if (!shouldShowTooltip) {
        return <InputControl size={size} variant={variant} {...props} />;
    }

    if (!tooltipText) {
        return <InputControl size={size} variant={variant} {...props} />;
    }

    return (
        <Tooltip
            text={tooltipText}
            position={tooltipPosition}
            className={tooltipClassName}
            role="tooltip"
            aria-label={props['aria-label']}
        >
            <InputControl size={size} variant={variant} {...props} />
        </Tooltip>
    );
};

export type InMeetingInputControlProps = React.ComponentProps<
    typeof InMeetingInputControl
>;
