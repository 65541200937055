/* FILE GENERATED BY packages/components/design-tokens/build-scripts/colors.js DO NOT CHANGE */
/* eslint-disable @typescript-eslint/no-duplicate-enum-values -- same colors with different names in figma */

export enum Color {
    White = '#ffffff',
    Blue40 = '#b1aaff',
    Blue20 = '#d0ccff',
    Red100 = '#ff7273',
    Green100 = '#0ebec7',
    Blue100 = '#4b37ff',
    TextLinkBlueDarkBg = '#00c2ff',
    TextLinkBlueHoverDarkBg = '#52d7ff',
    Blue50 = '#a198ff',
    Green90 = '#30c8cf',
    Green80 = '#51d2d8',
    Green60 = '#8de5e9',
    Green40 = '#baf4f6',
    Red90 = '#ff9697',
    Red80 = '#ffb6b7',
    Red70 = '#f24949',
    Red60 = '#ffe3e4',
    Red40 = '#fff7f7',
    Blue90 = '#5d4cff',
    SignalRedHover = '#bc1515',
    DeepBlue100 = '#0a2136',
    Neutral100 = '#212121',
    DeepBlue90 = '#1e3a54',
    Neutral90 = '#323232',
    SignalRed = '#e60000',
    SignalGreen = '#2c8500',
    JoinHover = '#488716',
    JoinDisabled = '#e3eede',
    SignalGreen130 = '#205e01',
    Join = '#5eaf1d',
    DeepBlue80 = '#3c5974',
    Neutral80 = '#494949',
    DeepBlue50 = '#a5b8ca',
    Neutral50 = '#c3c4c5',
    DeepBlue40 = '#c1d1df',
    Neutral40 = '#d2d2d2',
    DeepBlue30 = '#d8e3ee',
    Neutral30 = '#dcdcdc',
    DeepBlue20 = '#e8f0f8',
    Neutral20 = '#e6e6e6',
    Grey = '#f7f7f7',
    Black = '#111111',
    DeepBlue60 = '#728fac',
    Neutral60 = '#989898',
    DeepBlue70 = '#577490',
    Neutral70 = '#777777',
    DeepBlue10 = '#f4f7fb',
    Neutral10 = '#f2f2f2',
    DeepBlue5 = '#ffffff',
    Neutral5 = '#ffffff',
    TextLinkBlueWhiteBg = '#032fff',
    TextLinkBlueHoverWhiteBg = '#0023d1',
    SignalOrange130 = '#bc5a00',
    SignalOrange80 = '#fef1e6',
    Green130 = '#00777c',
    SignalOrange120 = '#ff9a3e',
    SignalOrange110 = '#fcc592',
    SignalOrange100 = '#ffd1a7',
    SignalOrange90 = '#fdd8b7',
    SignalOrange60 = '#fff5ed',
    SignalOrange40 = '#fff8f2',
}
export enum RgbColor {
    White = '255,255,255',
    Blue40 = '177,170,255',
    Blue20 = '208,204,255',
    Red100 = '255,114,115',
    Green100 = '14,190,199',
    Blue100 = '75,55,255',
    TextLinkBlueDarkBg = '0,194,255',
    TextLinkBlueHoverDarkBg = '82,215,255',
    Blue50 = '161,152,255',
    Green90 = '48,200,207',
    Green80 = '81,210,216',
    Green60 = '141,229,233',
    Green40 = '186,244,246',
    Red90 = '255,150,151',
    Red80 = '255,182,183',
    Red60 = '255,227,228',
    Red40 = '255,247,247',
    Blue90 = '93,76,255',
    SignalRedHover = '188,21,21',
    DeepBlue100 = '10,33,54',
    Neutral100 = '33,33,33',
    DeepBlue90 = '30,58,84',
    Neutral90 = '50,50,50',
    SignalRed = '230,0,0',
    SignalGreen = '44,133,0',
    JoinHover = '72,135,22',
    JoinDisabled = '227,238,222',
    SignalGreen130 = '32,94,1',
    Join = '94,175,29',
    DeepBlue80 = '60,89,116',
    Neutral80 = '73,73,73',
    DeepBlue50 = '165,184,202',
    Neutral50 = '195,196,197',
    DeepBlue40 = '193,209,223',
    Neutral40 = '210,210,210',
    DeepBlue30 = '216,227,238',
    Neutral30 = '220,220,220',
    DeepBlue20 = '232,240,248',
    Neutral20 = '230,230,230',
    Grey = '247,247,247',
    Black = '17,17,17',
    DeepBlue60 = '114,143,172',
    Neutral60 = '152,152,152',
    DeepBlue70 = '87,116,144',
    Neutral70 = '119,119,119',
    DeepBlue10 = '244,247,251',
    Neutral10 = '242,242,242',
    DeepBlue5 = '255,255,255',
    Neutral5 = '255,255,255',
    TextLinkBlueWhiteBg = '3,47,255',
    TextLinkBlueHoverWhiteBg = '0,35,209',
    SignalOrange130 = '188,90,0',
    SignalOrange80 = '254,241,230',
    Green130 = '0,119,124',
    SignalOrange120 = '255,154,62',
    SignalOrange110 = '252,197,146',
    SignalOrange100 = '255,209,167',
    SignalOrange90 = '253,216,183',
    SignalOrange60 = '255,245,237',
    SignalOrange40 = '255,248,242',
}
/* eslint-enable @typescript-eslint/no-duplicate-enum-values -- same colors with different names in figma */
