import React from 'react';
import cx from 'classnames';

import type {ButtonProps, IconSource} from '@pexip/components';
import {Button, Icon, IconTypes} from '@pexip/components';

import styles from './SelfviewButton.module.scss';

export const SelfviewButton: React.FC<
    React.PropsWithChildren<
        {
            ariaLabel?: string;
            className?: string;
            hasError?: boolean;
            iconClassName?: string;
            isDisabled?: boolean;
            isActive?: boolean;
            isMobileDevice?: boolean;
            onClick?: () => void;
            onPointerDownCapture?: (e: React.MouseEvent) => void;
            source?: IconSource;
        } & ButtonProps
    >
> = ({
    ariaLabel,
    className,
    children,
    iconClassName,
    isDisabled,
    isActive,
    isMobileDevice,
    onClick,
    onPointerDownCapture,
    source,
    ...props
}) => {
    return (
        <Button
            modifier="square"
            size="compact"
            variant="neutral"
            className={cx(
                {
                    [styles.miniButton]: isMobileDevice,
                    [styles.disabled]: isDisabled,
                },
                className,
            )}
            aria-label={ariaLabel}
            disabled={isDisabled || false}
            isActive={isActive}
            onClick={onClick}
            onPointerDownCapture={onPointerDownCapture}
            noHover={isMobileDevice || isDisabled || true}
            {...props}
        >
            {children || (
                <Icon
                    source={source || IconTypes.IconAdd}
                    size={isMobileDevice ? 'mini' : 'compact'}
                    className={iconClassName}
                />
            )}
        </Button>
    );
};

export type SelfviewButtonProps = React.ComponentProps<typeof SelfviewButton>;
