import React from 'react';

import type {AriaMenuProps} from '@pexip/components';
import {AriaMenu} from '@pexip/components';

import styles from '../CallControls.module.scss';
import {
    ARIA_MENU_DESKTOP_POPOVER_OFFSET,
    ARIA_MENU_TOUCH_POPOVER_OFFSET,
} from '../constants';

export const ToolbarAriaMenu: React.FC<
    AriaMenuProps & {
        isTouch?: boolean;
    }
> = ({items, isTouch = false, ...props}) => {
    return (
        <AriaMenu
            items={items}
            popoverPlacement={isTouch ? 'top' : 'right'}
            popoverOffset={
                isTouch
                    ? ARIA_MENU_TOUCH_POPOVER_OFFSET
                    : ARIA_MENU_DESKTOP_POPOVER_OFFSET
            }
            menuClassName={styles.moreMenu}
            buttonTooltipPosition={isTouch ? 'top' : 'right'}
            {...props}
        />
    );
};
