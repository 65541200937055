import {useEffect, useState} from 'react';

import {ariaMenuOpenStateSignal} from '@pexip/components';

export const useIsAriaMenuOpen = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => ariaMenuOpenStateSignal.add(setIsOpen), []);

    return isOpen;
};
