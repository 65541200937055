import {createSignal} from '@pexip/signal';

export type AnnounceSignal = {
    message: React.ReactNode | string;
    politeness: 'polite' | 'assertive';
    timeout?: number;
};

export const announceSignal = createSignal<AnnounceSignal>({
    name: 'announcer:announce',
});
