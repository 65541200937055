import cx from 'classnames';
import React from 'react';

import {useMobileDevice, useBelow360, BadgeCounter} from '@pexip/components';
import type {
    AriaMenuItem,
    ButtonProps,
    LogoSizeModifier,
} from '@pexip/components';

import {PanelHeader} from '../PanelHeader/PanelHeader.view';

import type {AudioButtonProps} from './Controls/AudioButton.view';
import {AudioButton} from './Controls/AudioButton.view';
import type {LeaveButtonDesktopProps} from './Controls/LeaveButton.view';
import {LeaveButtonTouch} from './Controls/LeaveButton.view';
import type {RaiseHandButtonProps} from './Controls/RaiseHandButton.view';
import {RaiseHandButton} from './Controls/RaiseHandButton.view';
import type {VideoButtonProps} from './Controls/VideoButton.view';
import {VideoButton} from './Controls/VideoButton.view';
import type {PresentationButtonProps} from './Controls/PresentationButton.view';
import {PresentationButton} from './Controls/PresentationButton.view';
import {MoreMenu} from './Controls/MoreMenu.view';

import styles from './CallControls.module.scss';

export const CallControlsTouchView: React.FC<
    React.PropsWithChildren<
        Omit<RaiseHandButtonProps, 'iconSize'> &
            Omit<AudioButtonProps, 'iconSize'> &
            Omit<VideoButtonProps, 'iconSize'> &
            Omit<LeaveButtonDesktopProps, 'iconSize'> &
            Omit<PresentationButtonProps, 'iconSize'> & {
                canPresent: boolean;
                moreMenuItems: AriaMenuItem[];
                pluginMenuParent?: string;
                isMoreMenuBadgeCounterVisible?: boolean;
                clearPluginMenu: () => void;
                onMoreMenuOpenChange: (isOpen: boolean) => void;
            }
    >
> = ({
    isAudioBtnDisabled,
    isHandRaised,
    isMicrophoneMuted,
    isVideoBtnDisabled,
    isVideoMuted,
    isRaiseHandHidden,
    localIsPresenting,
    canPresent,
    moreMenuItems,
    pluginMenuParent,
    isMoreMenuBadgeCounterVisible: isBadgeCounterVisible = false,
    onAudioBtnClick,
    onLeave,
    onRaiseHand,
    onVideoBtnClick,
    startPresentation,
    stopPresentation,
    clearPluginMenu,
    onMoreMenuOpenChange,
}) => {
    const isMobileDevice = useMobileDevice();
    const isCoreOnlyTouchCallControls = useBelow360();
    const iconSize: LogoSizeModifier = isMobileDevice ? 'medium' : 'small';
    const buttonVariant: ButtonProps['variant'] = 'neutral';

    return (
        <div className={cx(styles.callControlsWrapper, styles.touchWrapper)}>
            <div className={styles.touchCallControlBtnWrapper}>
                <MoreMenu
                    isTouch
                    isMobile={isMobileDevice}
                    items={moreMenuItems}
                    onOpenChange={onMoreMenuOpenChange}
                    headerContent={
                        pluginMenuParent ? (
                            <PanelHeader
                                title={pluginMenuParent}
                                onBackClick={clearPluginMenu}
                            />
                        ) : undefined
                    }
                >
                    <BadgeCounter
                        variant="info"
                        isTruncated
                        isVisible={isBadgeCounterVisible}
                        badgeClassName={styles.moreMenuBadge}
                    />
                </MoreMenu>
                {!isCoreOnlyTouchCallControls && (
                    <RaiseHandButton
                        variant={buttonVariant}
                        isHandRaised={isHandRaised}
                        onRaiseHand={onRaiseHand}
                        iconSize={iconSize}
                        isRaiseHandHidden={isRaiseHandHidden}
                    />
                )}
                {canPresent && (
                    <PresentationButton
                        variant={buttonVariant}
                        localIsPresenting={localIsPresenting}
                        iconSize={iconSize}
                        stopPresentation={stopPresentation}
                        startPresentation={startPresentation}
                    />
                )}
                <AudioButton
                    variant={buttonVariant}
                    isMicrophoneMuted={isMicrophoneMuted}
                    isAudioBtnDisabled={isAudioBtnDisabled}
                    onAudioBtnClick={onAudioBtnClick}
                    iconSize={iconSize}
                />
                <VideoButton
                    variant={buttonVariant}
                    isVideoMuted={isVideoMuted}
                    isVideoBtnDisabled={isVideoBtnDisabled}
                    onVideoBtnClick={onVideoBtnClick}
                    iconSize={iconSize}
                />
                <LeaveButtonTouch onLeave={onLeave} iconSize={iconSize} />
            </div>
        </div>
    );
};

export type CallControlsTouchProps = React.ComponentProps<
    typeof CallControlsTouchView
>;
